/**
 * @name ModificacionesDispositivos
 * @version 0.0.1
 * @author @Jorel254
 * @description Archivo principal del Módulo de Modificaciones a Insumos de Saludo de tipo Dispositivos.
 * Este archivo debe exportar las interfaces, tipos y constantes que se definan en el módulo.
 */

export enum EModificacionDispositivosTipo {
	ADMINISTRATIVAS = 'ADMINISTRATIVAS',
	TECNICAS = 'TECNICAS'
}

export enum EModificacionDispositivosSeccionOficio {
	TITULAR_REGISTRO = 'TITULAR_REGISTRO',
	DISTRIBUIDOR = 'DISTRIBUIDOR',
	FABRICANTE = 'FABRICANTE',
	IMPORTADOR = 'IMPORTADOR',
	DENOMINACION_DISTINTIVA = 'DENOMINACION_DISTINTIVA',
	ENVASE_SECUNDARIO = 'ENVASE_SECUNDARIO',
	PRESENTACIONES = 'PRESENTACIONES'
}
export enum EModificacionDispositivosHomoclaveModalidad {
	'COFEPRIS_2022_022_003_A_A' = 'COFEPRIS_2022_022_003_A_A',
	'COFEPRIS_2022_022_003_A_B' = 'COFEPRIS_2022_022_003_A_B',
	'COFEPRIS_2022_022_003_A_O' = 'COFEPRIS_2022_022_003_A_O',
	'COFEPRIS_2022_022_004_A' = 'COFEPRIS_2022_022_004_A'
}
export interface IModificacionDispositivo {
	numeral: string
	titulo: string
	descripcion: string
	seccionesOficio: EModificacionDispositivosSeccionOficio[]
	homoclaveModalidad: EModificacionDispositivosHomoclaveModalidad[]
	tipo: EModificacionDispositivosTipo
}

export interface IDatosDispositivo {
	dice: string
	debeDecir: string
}
export interface IModificacionSeccionOficioDispositivo {
	titulo: EModificacionDispositivosSeccionOficio
	datos: IDatosDispositivo[]
}

export interface IFormulariosModificacionesDispositivos {
	titularRegistro: IModificacionSeccionOficioDispositivo
	distribuidor: IModificacionSeccionOficioDispositivo
	fabricante: IModificacionSeccionOficioDispositivo
	importador: IModificacionSeccionOficioDispositivo
	denominacionDistintiva: IModificacionSeccionOficioDispositivo
	envaseSecundario: IModificacionSeccionOficioDispositivo
	presentaciones: IModificacionSeccionOficioDispositivo
}

export interface IEstructuraModificacionesDispositivos {
	formatos: IFormulariosModificacionesDispositivos
}

export interface IModificacionDatosDispositivos {
	valor: IModificacionDispositivo
	isSelect: boolean
}
