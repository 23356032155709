import { EGraphqlResponseStatus } from 'cofepris-typesafe/Types/Graphql'
export interface ISelectionSet {
	[key: string]: ISelectionSet | number
}
export default class SpyProxy {
	public obj: any
	public used: Set<string>
	private readonly exclude: string[] = ['length', 'map', 'constructor', 'forEach', '0', 'toJSON']

	constructor(ops: { addMetaData: boolean }) {
		this.used = new Set<string>()
		this.obj = this.defineSpyProp(ops)
	}
	private defineSpyProp(ops?: { target?: any; prop?: string; path?: string; value?: any; addMetaData?: boolean }): any {
		const spy = new Proxy(ops?.value ?? [], {
			get: (target, prop) => this.getSpy(target, prop as string, ops?.path ?? '')
		})
		if (typeof ops?.prop == 'string') {
			ops.target[ops?.prop] = spy
		}
		if ((ops?.addMetaData ?? false) === false) {
			return spy
		}
		spy.metaData = {
			status: EGraphqlResponseStatus.SUCCESS
		}

		this.used.add('.metaData.status')
		this.used.add('.metaData.message')
		this.used.add('.versionCodeDetails.hash')
		this.used.add('.versionCodeDetails.shortHash')
		this.used.add('.versionCodeDetails.branch')
		this.used.add('.versionCodeDetails.version')
		this.used.add('.versionCodeDetails.lastCommitDate')
		this.used.add('.versionCodeDetails.lastDeployDate')
		this.used.add('.versionCodeDetails.changedFiles')
		return spy
	}

	private getSpy(target: any, prop: string | symbol, path: string): any {
		let propName: string | undefined = prop.toString()
		if (typeof prop != 'string' && typeof prop != 'symbol') {
			throw new Error('Invalid prop type')
		}
		if (propName.startsWith('__v')) {
			return () => false
		}
		if (propName == 'toJSON') {
			return () => '{}'
		}
		if (typeof prop == 'symbol') {
			propName = undefined
			const v = target[prop] ?? 'object'
			//console.log('getSpy', v)
			return v
		}
		let fullPath = path
		if (propName != undefined && this.exclude.includes(propName) == false) {
			fullPath = fullPath + '.' + propName
			//console.log('getSpy', fullPath)
		}

		this.used.add(fullPath)

		const value = Reflect.get(target, prop)
		if (value != undefined) {
			if (value === 0 && prop == 'length') {
				target[0] = this.defineSpyProp({ target: target, prop: undefined, path: fullPath })
				return 1
			}
			return value
		}

		return this.defineSpyProp({ target: target, prop: prop, path: fullPath })
	}

	public getUsed(ops?: { ignorePrefix?: string }): ISelectionSet {
		const root: ISelectionSet = {}

		for (let used of this.used.values()) {
			if (ops?.ignorePrefix != undefined) {
				used = used.replace('.' + ops.ignorePrefix, '')
			}
			root[used.replace('.data.', '')] = 1
		}
		// console.log(root)
		return root
	}

	public getSelectionQuery() {
		const addSubProps = (query: string, root: any) => {
			for (const key of Object.keys(root)) {
				const isObj = typeof root[key] == 'object'
				const isEmpty = isObj && Object.keys(root[key]).length == 0
				query += ' ' + key
				if (isObj && !isEmpty) {
					query += ' { '
					query = addSubProps(query, root[key])
					query += ' } '
				}
			}
			return query
		}

		const root: { [key: string]: any } = {}
		for (const used of this.used.values()) {
			const parts = used.split('.')
			let cpy = root
			for (const element of parts) {
				const part = element
				if (part == '') {
					continue
				}
				if (cpy[part] == undefined) {
					cpy[part] = {}
				}
				cpy = cpy[part]
			}
		}
		const query = addSubProps('', root)
		//console.log(query)
		return query
	}
}
