import { AnyEventObject } from 'xstate'
import { getCredentialRegistrationData, CredentialRegistrationData, getCredentialAssertionData, CredentialAssertionData, stringSHA256, byteArrayToBase64 } from 'webauthn'
//local imports
import { WebAuthnContext } from './types'

const setDebug = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setDebug', event)
	const newContext = { ...context }
	newContext.debug = event.debug
	return newContext
}

const setMode = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setMode', event)
	const newContext = { ...context }
	newContext.mode = event.mode
	return newContext
}

const setChallenge = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setChallenge', event)
	const newContext = { ...context }
	newContext.challenge = event.challenge || (event.data ? event.data.challenge : null) || null
	if (newContext.challenge) {
		newContext.challengeSHA256 = stringSHA256(newContext.challenge)
	} else {
		newContext.challengeSHA256 = null
	}
	return newContext
}

const setCredentialId = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setCredentialId', event)
	const newContext = { ...context }
	newContext.credentialId = event.credentialId || (event.data ? event.data.credentialId : null) || null
	return newContext
}

const setPublicKey = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setPublicKey', event)
	const newContext = { ...context }
	newContext.publicKey = event.publicKey || (event.data ? event.data.publicKey : null) || null
	return newContext
}

const setFirmante = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setFirmante', event)
	const newContext = { ...context }
	newContext.firmante = event.firmante || (event.data ? event.data.firmante : null) || null
	return newContext
}

const setRol = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setRol', event)
	const newContext = { ...context }
	newContext.rol = event.rol || (event.data ? event.data.rol : null) || null
	return newContext
}

const setAssertionErrorMessage = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setAssertionErrorMessage', event)
	const newContext = { ...context }
	newContext.assertionErrorMessage = event.assertionErrorMessage || (event.data ? event.data.assertionErrorMessage : null) || null
	return newContext
}

const setChallengeToCurrentTime = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setChallengeToCurrentTime', event)
	const newContext = { ...context }
	newContext.challenge = Date.now().toString()
	return newContext
}

const setCredential = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setCredential', event)
	const newContext = { ...context }
	if (event.data) {
		const pubKeyCredential: PublicKeyCredential = event.data
		if (context.mode == 'REGISTER') {
			const credentialRegistrationData: CredentialRegistrationData = getCredentialRegistrationData(pubKeyCredential)
			newContext.credential = credentialRegistrationData
		} else {
			const credentialAssertionData: CredentialAssertionData = getCredentialAssertionData(pubKeyCredential)
			newContext.credential = credentialAssertionData
			newContext.signature = credentialAssertionData.response.signature
			newContext.signatureB64 = byteArrayToBase64(credentialAssertionData.response.signature)
		}
	} else {
		newContext.credential = null
		newContext.signature = null
		newContext.signatureB64 = null
	}
	return newContext
}

const setRegistrationRSAMessage = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setRegistrationRSAMessage', event)
	const newContext = { ...context }
	newContext.registrationRSAMessage = event.data || null
	return newContext
}

const setRegistrationRSASignature = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setRegistrationRSASignature', event)
	const newContext = { ...context }
	newContext.registrationRSASignature = event.registrationRSASignature || null
	return newContext
}

const setRegistrationRSAErrroMessage = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setRegistrationRSAErrroMessage', event)
	const newContext = { ...context }
	newContext.registrationRSAErrorMessage = event.data || null
	return newContext
}

const setActivationErrorMessage = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setActivationErrorMessage', event)
	const newContext = { ...context }
	newContext.activationErrorMessage = event.data || null
	return newContext
}

const setLoginErrorMessage = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setLoginErrorMessage', event)
	const newContext = { ...context }
	newContext.loginErrorMessage = event.data || null
	return newContext
}

const setJwt = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setJwt', event)
	const newContext = { ...context }
	newContext.jwt = event.jwt || (event.data ? event.data.jwt : null) || null
	return newContext
}

const setCertificadoB64 = function (context: WebAuthnContext, event: AnyEventObject): WebAuthnContext {
	context.debug && console.log('Assignation: setCertificadoB64', event)
	const newContext = { ...context }
	newContext.certificadoB64 = event.certificadoB64 || (event.data ? event.data.certificadoB64 : null) || null
	return newContext
}

export default {
	setDebug,
	setMode,
	setChallenge,
	setAssertionErrorMessage,
	setFirmante,
	setRol,
	setChallengeToCurrentTime,
	setCredential,
	setCredentialId,
	setPublicKey,
	setRegistrationRSAMessage,
	setRegistrationRSASignature,
	setRegistrationRSAErrroMessage,
	setActivationErrorMessage,
	setLoginErrorMessage,
	setJwt,
	setCertificadoB64
}
