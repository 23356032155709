/**
 * @name CCTDispositivos
 * @author @tirsomartinezreyes
 * @version 0.0.6
 *
 * Documento de análisis de la estructura del CTD para COFEPRIS
 * @references https://docs.google.com/spreadsheets/d/1iRlvEqT78WXnAxntQ2RwKvlRBIWM6VcVAJc4Po50--0/edit?usp=sharing
 *
 * @changelog 0.0.6 - 29/oct/24 - Se actualizan módulos 2 y 5
 * @changelog 0.0.5 - 9/sep/24 - Se actualiza nodo raiz y módulo 1
 * @changelog 0.0.4 - 28/ago/24 - Se modificaron módulos 2 y 3
 * @changelog 0.0.2 Se agregaron carpetas de resúmenes en el módulo 3
 */

import {
	ECTDTipoCTD, //Enumeración que define los tipos de CTD: MEDICAMENTOS y DISPOSITIVOS
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTD, //Definición de la estructura del CTD apegado a la interface definida
	CICTDVersion //Versión de la interface ICTD
} from '../ICTD'

import { CTDDispositivosModulo1 } from './CTDDispositivosModulo1' //0.0.3
import { CTDDispositivosModulo2 } from './CTDDispositivosModulo2' //0.0.5
import { CTDDispositivosModulo3 } from './CTDDispositivosModulo3' //0.0.5
import { CTDDispositivosModulo4 } from './CTDDispositivosModulo4' //0.0.1
import { CTDDispositivosModulo5 } from './CTDDispositivosModulo5' //0.0.2

export const CCTDDispositivosVersion = '0.0.6'
/**
 * @name CCTDDispositivos
 * @description Define la estructura del CTD (Common Technical Document) para Medicamentos.
 */
const CCTDDispositivos: ICTD = {
	versionICTD: CICTDVersion,
	versionCTD: CCTDDispositivosVersion,
	tipoCTD: ECTDTipoCTD.DISPOSITIVOS_MEDICOS,
	raiz: {
		tipo: ECTDTipoNodo.RAIZ,
		id: 'ddm',
		nombre: 'DDM',
		descripcion: 'Dossier para Registro de Dispositivos Médicos',
		hijos: [CTDDispositivosModulo1, CTDDispositivosModulo2, CTDDispositivosModulo3, CTDDispositivosModulo4, CTDDispositivosModulo5]
	}
}

/**
 *
 * @name obtenerCopiaCTDDispositivos
 * @description Obtiene una copia del CTD Medicamentos como un objeto nuevo, sin referencias al CCTDMedicamentos original.
 */
export const obtenerCopiaCTDDispositivos = (): ICTD => JSON.parse(JSON.stringify(CCTDDispositivos)) as ICTD
