/**
 * @name strings.ts
 * @author @tirsomartinezreyes
 * @version 1.0.2
 * @description Este archivo proporciona funciones para el manejo de cadenas de texto, utilizables por Vue o TypeScript
 *
 * Notas de la versión:
 * @changelog 1.0.2 - 21/sep/24 - Se agregó la función de formatoMoneda y convertirNumeroATexto
 *  1.0.1 Se agregó funciona de normalización de cadenas para búsquedas
 */

/**
 * @name addSpacesToCSV
 * @description Agrega espacios a una cadena de texto separada por comas para evitar que queden pegadas
 */
export function addSpacesToCSV(csv: string): string {
	let response = ''
	const tokens = csv.split(',')
	response = tokens.join(', ')
	return response
}

/**
 * @name addCarReturnToCSV
 * @description Agrega saltos de línea a una cadena de texto separada por comas para que queden en líneas separadas
 */
export function addCarReturnToCSV(csv: string): string {
	let response = ''
	const tokens = csv.split(',')
	response = tokens.join(',\r\n')
	return response
}

/**
 * @name objectsArrayToString
 * @description Convierte un arreglo de objetos a una cadena de texto con sus valores en formato JSON, separados por comas
 */
export function objectsArrayToString(objects: any[]): string {
	let response = ''
	const tokens = objects.map(object => JSON.stringify(object))
	response = tokens.join(', ')
	return response
}

/**
 * @name arrayElementsCount
 * @description Cuenta los elementos de un arreglo y devuelve el número de elementos, si no e sun arreglo, devuelve 0
 */
export function arrayElementsCount(array: any[]): number {
	let response = 0
	if (array instanceof Array) {
		response = array.length
	}

	return response
}

/**
 * @name arrayIndexToOrdinal
 * @description Convierte un índice de un arreglo en un ordinal en españolen femenino o masculino
 */
export function arrayIndexToOrdinal(index: number, modo: 'masculino' | 'femenino' = 'masculino'): string {
	let response = ''
	const ordinalNumbersMasculino = ['primer', 'segundo', 'tercer', 'cuarto', 'quinto', 'sexto', 'séptimo', 'octavo', 'noveno', 'décimo']
	const ordinalNumbersFemenino = ['primera', 'segunda', 'tercera', 'cuarta', 'quinta', 'sexta', 'séptima', 'octava', 'novena', 'décima']
	const ordinalNumbers = modo === 'femenino' ? ordinalNumbersFemenino : ordinalNumbersMasculino
	if (index >= 0 && index < ordinalNumbers.length) {
		response = ordinalNumbers[index]
	}
	return response
}

/**
 * @name upperCaseFirst
 * @description Convierte la primera letra de una cadena de texto a mayúsculas
 */
export function upperCaseFirst(string: string): string {
	let response = ''
	if (string.length > 0) {
		response = string.charAt(0).toUpperCase() + string.slice(1)
	}
	return response
}

/**
 * @name normalizarBusqueda
 * @description Normaliza una cadena de texto para realizar búsquedas sin importar mayúsculas, minúsculas o acentos
 */
export function normalizarBusqueda(cadena: string): string {
	const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ'
	const accentsOut = 'AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn'
	cadena = cadena
		.split('')
		.map(letter => {
			const accentIndex = accents.indexOf(letter)
			return accentIndex !== -1 ? accentsOut[accentIndex] : letter
		})
		.join('')

	return cadena.normalize('NFD').toLowerCase()
}

export const formatoMoneda = (valor: number, decimales: number = 2) =>
	valor != undefined ? '$ ' + Number(valor).toLocaleString('es-MX', { minimumFractionDigits: decimales, maximumFractionDigits: decimales }) : 'SIN VALOR'

export const convertirNumeroATexto = (numero: number): string => {
	const convertirParteEntera = (numero: number): string => {
		if (numero === 0) return 'cero'
		if (numero < 1000) return convertirCentenas(numero)

		const millones = Math.floor(numero / 1000000)
		const miles = Math.floor((numero % 1000000) / 1000)
		const resto = numero % 1000

		const textoMillones = millones > 0 ? (millones === 1 ? 'un millón' : convertirCentenas(millones) + ' millones') : ''
		const textoMiles = miles > 0 ? (miles === 1 ? 'mil' : convertirCentenas(miles).replace('uno', 'un') + ' mil') : ''
		const textoResto = resto > 0 ? convertirCentenas(resto) : ''

		return [textoMillones, textoMiles, textoResto].filter(Boolean).join(' ')
	}

	const convertirCentenas = (numero: number): string => {
		const unidades = ['cero', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve']
		const decenas = ['', '', 'veinte', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa']
		const especiales = ['diez', 'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve']
		const centenas = ['cien', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos']
		if (numero < 10) return unidades[numero]
		if (numero >= 10 && numero < 20) return especiales[numero - 10]
		if (numero >= 20 && numero < 100) {
			const unidad = numero % 10
			const decena = Math.floor(numero / 10)
			return decena === 2 && unidad !== 0 ? 'veinti' + unidades[unidad] : decenas[decena] + (unidad !== 0 ? ' y ' + unidades[unidad] : '')
		}
		if (numero >= 100 && numero < 1000) {
			const centena = Math.floor(numero / 100)
			const resto = numero % 100
			if (centena === 1 && resto === 0) return 'cien'
			if (centena === 1 && resto > 0) return 'ciento ' + convertirCentenas(resto)
			return centenas[centena - 1] + (resto > 0 ? ' ' + convertirCentenas(resto) : '')
		}
		return ''
	}

	const entero = Math.floor(numero)
	const decimal = numero % 1 !== 0 ? Math.round((numero % 1) * 100) : null

	const textoEntero = convertirParteEntera(entero)
	const textoDecimal = decimal !== null ? ' punto ' + convertirParteEntera(decimal) : ''

	return textoEntero + textoDecimal
}

export const convertirPorcentajeDecimalATexto = (numero: number, decimales?: number): string => {
	let texto = ''
	const _decimales = decimales ?? 2
	if (typeof numero == 'number' && typeof _decimales == 'number') {
		texto = (numero * 100).toFixed(_decimales).toString() + '%'
	}
	return texto
}
