import router from 'src/router'
import { Roles, RolType } from 'cofepris-typesafe/Types/Roles'

function redirectToPathByRoleAndGroup(role: RolType, grupo: string) {
	if (role == Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type) {
		router.push(`/portal/GRUPO/${grupo}/permisos`)
		return
	}

	if ([Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type].indexOf(role) >= 0) {
		router.push(`/portal/GRUPO/${grupo}`)
		return
	}

	if (role == Roles.ROL_ADMINISTRADOR.type) {
		router.push('/administrador')
		return
	}

	if (role == Roles.ROL_SUPERADMINISTRADOR.type) {
		router.push('/superadministrador')
		return
	}

	if (role == Roles.ROL_COORDINADOR.type) {
		router.push('/sistema/reportes')
		return
	}

	if ([Roles.ROL_DICTAMINADOR.type, Roles.ROL_AUTORIDAD.type].indexOf(role) >= 0) {
		router.push('/sistema')
		return
	}

	if (role == Roles.ROL_ANONIMO.type) {
		router.push('/')
		return
	}
	if (role == Roles.ROL_EDITOR_DE_ACTIVOS.type) {
		router.push('/editor_activos/insumos_salud')
		return
	}
	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
	console.error(`Rol no identificado: ${role}`)
	router.push('/')
}
export { redirectToPathByRoleAndGroup }
