/**
 * @name AIInsumoSalud
 * @author @tirsomartinezreyes
 * @version: 2.0.4
 *
 * @description Definición de la estructura dexl Activo de Información de Insumo para la Salud
 * Debe ser versionado de manera semántica para emparejar la compatibilidad de las plantilas de los productos documentales producidos
 * referencia en https://whimsical.com/estructura-de-activo-de-informacion-insumos-de-salud-33tfg9425VYBZyFnbJd1bj
 * Desgloce por elemento en https://docs.google.com/spreadsheets/d/1Mu7x9odTi7tBlzfSzvJbp_FgIz3QYnEdVUj7bTjU8H0/edit#gid=0
 * @changelog - 2.0.4 - 28/oct/24/ Se agrega historico de cambios para las firmas y ajustes en medicamentos  @Jorel254
 * @changelog 2.0.3 - 15/oct/24  @BrenAzua , @tirsomartinezreyes - Se exportan constantes de vigencia de autorización y prorroga de insumos para la salud
 * @changelog 2.0.2 - 9/oct/24 - @MrCalamitus - Se agrega el historico de cambios y se modifica el ACTUALIZACION_VERSION_MANUAL por ACTUALIZACION_MANUAL
 * @changelog 2.0.1 - 3/oct/24 - @tirsomartinezreyes - Se agregan las banderas de prorrogaEnEvaluación y prorrogado
 * @changelog 0.0.15 - 06/sep/24 S agregan validaciones para longitud de RFC
 * @changelog 0.0.1 - 12/sep/24 Se ajustan documentoLegado a arreglo de documentos
 */
import {
	INodoRecurso, //Requerido para extender la interfaz de un Nodo del Árbol de recursos genérico a un Activo de Información de Insumo para la Salud
	EHomoclaveRegistryAntecedenteActivoInformacion, //Requerido para definir el tipo de activo de información de insumo para la salud
	EHomoclaveContextTipoResolucion //Requerido para definir el tipo de resolución de un trámite en el contexto de la máquina de estados Homoclave
} from './bom' //[v0.0.1]

import {
	IInsumoSalud //Definición del contenido de cada versión particular de un insumo para la salud
} from './InsumoSalud' // [v0.0.10] Proporciona interfaces y enumeraciones sobre los insumos para la salud

import {
	EMedicamentoClaseLGS226,
	EMedicamentoTipo, //familias de tipo de medicamento
	EMedicamentoTipoAlopatico
} from './Medicamento' // [v0.0.4] Proporciona interfaces y enumeraciones sobre los insumos para la salud de tipo Medicamento

import {
	EDispositivoClaseRIS83, //Definición de las posible clases de un dispositivo médico según el Reglamento de Insumos Médicos, Artículo 83
	EDispositivoCategoriaLGS262 //Definición de las posibles categorías de un dispositivo médico según la Ley General de Salud, Artículo 262
} from './Dispositivo' // [v0.0.5] Proporciona interfaces y enumeraciones sobre los insumos para la salud
import { IFileType } from 'cofepris-typesafe/Types/FilesType'
import { EDocumentoClase, EDocumentoEstado, IGeneratedDoc } from 'cofepris-typesafe/Types/IGeneratedDoc'
import { IFirmaES256 } from '../Firma'

/**
 * @name CIAIInsumoSaludVersion
 * @description Versión de la interfaz de activo de información de Insumo para la Salud
 */
export const CIAIInsumoSaludVersion = '2.0.3'
export const CIAIInsumoSaludAniosVigenciaAutorizacionRegistroSanitario: number = 5 //Vigencia de la autorización de un insumo para la salud en años
export const CIAIInsumoSaludAniosVigenciaReconocimientoMedicamentoHuerfano: number = 2 //Vigencia del reconocimiento de un medicamento huérfano en años
export const CIAIInsumoSaludDiasNaturalesMinimosRequeridosParaSolicitudProrrogaRegistroSanitario: number = 150 // Días naturales mínimos requeridos para solicitar una prórroga de un insumo para la salud
export const CIAIInsumoSaludDiasNaturalesMinimosRequeridosParaSolicitudProrrogaReconocimientoMedicamentoHuerfano: number = 0 // Días naturales mínimos requeridos para solicitar una prórroga de un medicamento huérfano

/**
 * @name EAIInsumoSaludError
 * @description Errores comunes en la estructura del Activo de Información de Insumo para la Salud
 */
export enum EAIInsumoSaludError {
	//INodoRecurso
	ERROR_AL_PROCESAR_VALIDACIONES = 'ERROR_AL_PROCESAR_VALIDACIONES', //Error desconocido
	ID_VACIO = 'ID_VACIO', //El identificador del activo de información no puede estar vacío
	PARENT_NAME_VACIO = 'PARENT_NAME_VACIO', //El parent name del activo de información no puede estar vacío
	PARENT_PATH_VACIO = 'PARENT_PATH_VACIO', //El parent path del activo de información no puede estar vacío
	PATH_VACIO = 'PATH_VACIO', //El path del activo de información no puede estar vacío
	TIPO_NODO_VACIO = 'TIPO_NODO_VACIO', //El tipo de nodo del activo de información no puede estar vacío
	TIPO_NODO_DESCONOCIDO = 'TIPO_NODO_DESCONOCIDO', //El tipo de nodo del activo de información no es reconocido
	CREATION_DATE_VACIO = 'CREATION_DATE_VACIO', //La fecha de creación del activo de información no puede estar vacía
	MODIFICATION_DATE_VACIO = 'MODIFICATION_DATE_VACIO', //La fecha de modificación del activo de información no puede estar vacía
	MODIFICACION_DATE_MENOR_CREACION_DATE = 'MODIFICACION_DATE_MENOR_CREACION_DATE', //La fecha de modificación del activo de información no puede ser menor a la fecha de creación
	GRUPO_VACIO = 'GRUPO_VACIO', //El grupo del activo de información no puede estar vacío
	//IAIInsumoSalud: Primitivos
	VERSION_INTERFACE_AI_VACIA = 'VERSION_INTERFACE_AI_VACIA', //La versión de la interfaz del activo de información no puede estar vacía
	NUMERO_REGISTRO_VACIO = 'NUMERO_REGISTRO_VACIO', //El número de registro del activo de información no puede estar vacío
	VERSION_ACTUAL_VACIA = 'VERSION_ACTUAL_VACIA', //La versión actual del activo de información no puede estar vacía
	RFC_SOLICITANTE_VACIO = 'RFC_SOLICITANTE_VACIO', //El RFC del solicitante del activo de información no puede estar vacío
	RFC_SOLICITANTE_LONGITUD_INCORRECTA = 'RFC_SOLICITANTE_LONGITUD_INCORRECTA', //El RFC del solicitante del activo de información debe tener 12 o 13 caracteres
	TIPO_AI_VACIO = 'TIPO_AI_VACIO', //El tipo de activo de información del activo de información no puede estar vacío
	TIPO_ORIGEN_VACIO = 'TIPO_ORIGEN_VACIO', //El tipo de origen del activo de información no puede estar vacío
	TIPO_INSUMO_SALUD_VACIO = 'TIPO_INSUMO_SALUD_VACIO', //El tipo de insumo para la salud del activo de información no puede estar vacío
	TIPO_AUTORIZACION_VACIO = 'TIPO_AUTORIZACION_VACIO', //El tipo de autorización del activo de información no puede estar vacío
	ESTADO_AUTORIZACION_VACIO = 'ESTADO_AUTORIZACION_VACIO', //El estado de autorización del activo de información no puede estar vacío
	VIGENCIA_FIN_AUTORIZACION_NO_VALIDA = 'VIGENCIA_FIN_AUTORIZACION_NO_VALIDA', //La vigencia de fin de la autorización del activo de información no puede estar vacía
	VIGENCIA_FIN_AUTORIZACION_MENOR_VIGENCIA_INICIO_AUTORIZACION = 'VIGENCIA_FIN_AUTORIZACION_MENOR_VIGENCIA_INICIO_AUTORIZACION', //La vigencia de fin de la autorización del activo de información no puede ser menor a la vigencia de inicio
	PRORROGADO_NO_DEFINIDO = 'PRORROGADO_NO_DEFINIDO', //La bandera de prorrogado del activo de información no está definida
	PRORROGA_RETROACTIVA_NO_DEFINIDA = 'PRORROGA_RETROACTIVA_NO_DEFINIDA', //La bandera de prórroga retroactiva del activo de información no está definida
	PRORROGA_EN_EVALUACION_NO_DEFINIDA = 'PRORROGA_EN_EVALUACION_NO_DEFINIDA', //La bandera de prórroga en evaluación del activo de información no está definida
	//IAIInsumoSalud: Objetos
	AUTORIZADOR_NO_OBJETO = 'AUTORIZADOR_NO_OBJETO', //El autorizador del activo de información no es un objeto
	AUTORIZADOR_SIN_NOMBRE = 'AUTORIZADOR_SIN_NOMBRE', //El autorizador del activo de información no puede estar vacío
	AUTORIZADOR_SIN_CERTIFICADO = 'AUTORIZADOR_SIN_CERTIFICADO', //El autorizador del activo de información no puede estar vacío
	AUTORIZADOR_SIN_CADENA_ORIGINAL = 'AUTORIZADOR_SIN_CADENA_ORIGINAL', //El autorizador del activo de información no puede estar vacío
	AUTORIZADOR_SIN_FIRMA = 'AUTORIZADOR_SIN_FIRMA', //El autorizador del activo de información no puede estar vacío
	AUTORIZADOR_SIN_VERSION_AI = 'AUTORIZADOR_SIN_VERSION_AI', //El autorizador del activo de información no puede estar vacío
	AUTORIZADOR_VERSION_AI_NO_EXISTE_EN_VERSIONES = 'AUTORIZADOR_VERSION_AI_NO_EXISTE_EN_VERSIONES', //La versión del autorizador del activo de información no existe en las versiones
	VERSIONES_VACIAS = 'VERSIONES_VACIAS', //Las versiones del activo de información no pueden estar vacías
	VERSION_ACTUAL_NO_EXISTE_EN_VERSIONES = 'VERSION_ACTUAL_NO_EXISTE_EN_VERSIONES', //La versión actual del activo de información no existe en las versiones
	VERSION_ACTUAL_NO_COINCIDE_CON_VERSIONES = 'VERSION_ACTUAL_NO_COINCIDE_CON_VERSIONES', //La versión actual del activo de información no coincide con la versión actual en las versiones
	VERSION_ACTUAL_NO_INCLUYE_INSUMO_SALUD = 'VERSION_ACTUAL_NO_INCLUYE_INSUMO_SALUD', //La versión actual del activo de información no incluye un insumo para la salud
	VERSION_ACTUAL_NO_INCLUYE_FECHA_VERSION = 'VERSION_ACTUAL_NO_INCLUYE_FECHA_VERSION', //La versión actual del activo de información no incluye la fecha de la versión
	VERSION_NO_COINCIDE_CON_INDICE = 'VERSION_NO_COINCIDE_CON_INDICE', //La versión del activo de información no coincide con el índice
	VERSION_NO_INCLUYE_INSUMO_SALUD = 'VERSION_NO_INCLUYE_INSUMO_SALUD', //La versión del activo de información no incluye un insumo para la salud
	VERSION_NO_INCLUYE_FECHA_VERSION = 'VERSION_NO_INCLUYE_FECHA_VERSION', //La versión del activo de información no incluye la fecha de la versión
	INSUMO_SALUD_SIN_DENOMINACION_DISTINTIVA = 'INSUMO_SALUD_SIN_DENOMINACION_DISTINTIVA', //La denominación distintiva del activo de información no puede estar vacía
	INSUMO_SALUD_SIN_DENOMINACION_GENERICA = 'INSUMO_SALUD_SIN_DENOMINACION_GENERICA', //La denominación genérica del activo de información no puede estar vacía
	INSUMO_SALUD_SIN_TITULAR = 'INSUMO_SALUD_SIN_TITULAR', //El titular del activo de información no puede estar vacío
	INSUMO_SALUD_SIN_MEDICAMENTO_NI_DISPOSITIVO = 'INSUMO_SALUD_SIN_MEDICAMENTO_NI_DISPOSITIVO', //El activo de información no puede estar sin medicamento ni dispositivo
	INSUMO_SALUD_MEDICAMENTO_Y_DISPOSITIVO_AL_MISMO_TIEMPO = 'INSUMO_SALUD_MEDICAMENTO_Y_DISPOSITIVO_AL_MISMO_TIEMPO', //El activo de información no puede tener medicamento y dispositivo al mismo tiempo
	INSUMO_SALUD_TITULAR_SIN_NOMBRE = 'INSUMO_SALUD_TITULAR_SIN_NOMBRE', //El nombre de titular del insumo de salud no puede estar vacío
	INSUMO_SALUD_TITULAR_SIN_PAIS_ORIGEN = 'INSUMO_SALUD_TITULAR_SIN_PAIS_ORIGEN', //El país de origen del titular del insumo de salud no puede estar vacío
	INSUMO_SALUD_TITULAR_NACIONAL_CON_REPRESENTANTE = 'INSUMO_SALUD_TITULAR_NACIONAL_CON_REPRESENTANTE', //El titular del insumo de salud no puede ser nacional y tener representante
	INSUMO_SALUD_TITULAR_NACIONAL_SIN_RFC = 'INSUMO_SALUD_TITULAR_NACIONAL_SIN_RFC', //El RFC del titular del insumo de salud no puede estar vacío
	INSUMO_SALUD_TITULAR_NACIONAL_RFC_LONGITUD_INCORRECTA = 'INSUMO_SALUD_TITULAR_NACIONAL_RFC_LONGITUD_INCORRECTA', //El RFC del titular del insumo de salud debe tener 12 o 13 caracteres
	INSUMO_SALUD_TITULAR_EXTRANJERO_SIN_REPRESENTANTE = 'INSUMO_SALUD_TITULAR_EXTRANJERO_SIN_REPRESENTANTE', //El titular del insumo de salud extranjero debe tener representante
	INSUMO_SALUD_REPRESENTANTE_SIN_NOMBRE = 'INSUMO_SALUD_REPRESENTANTE_SIN_NOMBRE', //El nombre del representante del insumo de salud no puede estar vacío
	INSUMO_SALUD_REPRESENTANTE_NO_MEXICANO = 'INSUMO_SALUD_REPRESENTANTE_NO_MEXICANO', //El representante del insumo de salud debe ser mexicano
	INSUMO_SALUD_ACUERDO_EQUIVALENCIA_NO_VALIDO = 'INSUMO_SALUD_ACUERDO_EQUIVALENCIA_NO_VALIDO', //El acuerdo de equivalencia del insumo de salud no es válido
	MEDICAMENTO_SIN_TIPO = 'MEDICAMENTO_SIN_TIPO', //El tipo de medicamento del activo de información no puede estar vacío
	MEDICAMENTO_SIN_GRUPO_COORDINACION = 'MEDICAMENTO_SIN_GRUPO_COORDINACION', //El grupo de coordinación del medicamento del activo de información no puede estar vacío
	MEDICAMENTO_SIN_GRUPO_PAGO = 'MEDICAMENTO_SIN_GRUPO_PAGO', //El grupo de pago del medicamento del activo de información no puede estar vacío
	MEDICAMENTO_SIN_ESTUPEFACIENTE_PSICOTROPICO_PRECURSOR = 'MEDICAMENTO_SIN_ESTUPEFACIENTE_PSICOTROPICO_PRECURSOR', //El medicamento del activo de información no puede estar vacío
	MEDICAMENTO_SIN_DE_REFERENCIA = 'MEDICAMENTO_SIN_DE_REFERENCIA', //El medicamento del activo de información no puede estar vacío
	MEDICAMENTO_SIN_CLASE_MEDICAMENTO_LGS226 = 'MEDICAMENTO_SIN_CLASE_MEDICAMENTO_LGS226', //La clase de medicamento LGS226 del activo de información no puede estar vacía
	DISPOSITIVO_SIN_CATEGORIA_LGS262 = 'DISPOSITIVO_SIN_CATEGORIA_LGS262', //La categoría del dispositivo del activo de información no puede estar vacía
	DISPOSITIVO_SIN_CLASE_RIS83 = 'DISPOSITIVO_SIN_CLASE_RIS83', //La clase del dispositivo del activo de información no puede estar vacía
	DISPOSITIVO_SIN_GRUPO_PAGO = 'DISPOSITIVO_SIN_GRUPO_PAGO', //El grupo de pago del dispositivo del activo de información no puede estar vacío
	ALERTA_SANITARIA_SIN_ESTADO = 'ALERTA_SANITARIA_SIN_ESTADO', //La alerta sanitaria del activo de información no puede estar vacía
	ALERTA_SANITARIA_SIN_NIVEL = 'ALERTA_SANITARIA_SIN_NIVEL', //La alerta sanitaria del activo de información no puede estar vacía
	RESUMEN_PUBLICO_NO_DEFINIDO = 'RESUMEN_PUBLICO_NO_DEFINIDO', //El resumen público del
	RESUMEN_PUBLICO_PRESENTACIONES_NO_ES_ARRAY = 'RESUMEN_PUBLICO_PRESENTACIONES_NO_ES_ARRAY', //Las presentaciones del resumen público del activo de información no es un arreglo
	RESUMEN_PUBLICO_SIN_NUMERO_REGISTRO = 'RESUMEN_PUBLICO_SIN_NUMERO_REGISTRO', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_NOMBRE_TITULAR = 'RESUMEN_PUBLICO_SIN_NOMBRE_TITULAR', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_TIPO_INSUMO_SALUD = 'RESUMEN_PUBLICO_SIN_TIPO_INSUMO_SALUD', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_TIPO_AUTORIZACION = 'RESUMEN_PUBLICO_SIN_TIPO_AUTORIZACION', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_ESTADO_AUTORIZACION = 'RESUMEN_PUBLICO_SIN_ESTADO_AUTORIZACION', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_FECHA_INICIO_VIGENCIA_AUTORIZACION = 'RESUMEN_PUBLICO_SIN_FECHA_INICIO_VIGENCIA_AUTORIZACION', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_FECHA_FIN_VIGENCIA_AUTORIZACION = 'RESUMEN_PUBLICO_SIN_FECHA_FIN_VIGENCIA_AUTORIZACION', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_DENOMINACION_GENERICA = 'RESUMEN_PUBLICO_SIN_DENOMINACION_GENERICA', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_DENOMINACION_DISTINTIVA = 'RESUMEN_PUBLICO_SIN_DENOMINACION_DISTINTIVA', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_SIN_NUMERO_ALERTAS_SANITARIAS = 'RESUMEN_PUBLICO_SIN_NUMERO_ALERTAS_SANITARIAS', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_DISPOSITIVO_SIN_CATEGORIA = 'RESUMEN_PUBLICO_DISPOSITIVO_SIN_CATEGORIA', //El resumen público del activo de información no puede estar vacío
	RESUMEN_PUBLICO_DISPOSITIVO_SIN_CLASE = 'RESUMEN_PUBLICO_DISPOSITIVO_SIN_CLASE', //El resumen público del activo de información no puede estar vacío
	METADATOS_NO_DEFINIDO = 'METADATOS_NO_DEFINIDO', //Los metadatos del activo de información no están definidos
	METADATOS_NUMERO_CONSTANCIAS_CANCELADAS_ERRONEO = 'METADATOS_NUMERO_CONSTANCIAS_CANCELADAS_ERRONEO', //El número de constancias canceladas del activo de información no puede ser menor a 0
	METADATOS_NUMERO_CONSTANCIAS_CANCELADAS_PREVIO_VERIFICACION_HUMANA_ERRONEO = 'METADATOS_NUMERO_CONSTANCIAS_CANCELADAS_PREVIO_VERIFICACION_HUMANA_ERRONEO', //El número de constancias canceladas previo a verificación humana del activo de información no puede ser menor a 0
	METADATOS_FECHA_LIMITE_SOLICITUD_PRORROGA_ERRONEA = 'METADATOS_FECHA_LIMITE_SOLICITUD_PRORROGA_ERRONEA', //La fecha límite de solicitud de prórroga del activo de información no puede estar vacía
	METADATOS_NUMERO_PRORROGAS_ERRONEO = 'METADATOS_NUMERO_PRORROGAS_ERRONEO', //El número de prórrogas del activo de información no puede ser menor a 0
	METADATOS_FECHA_FIN_VIGENCIA_AUTORIZACION_ERRONEA = 'METADATOS_FECHA_FIN_VIGENCIA_AUTORIZACION_ERRONEA', //La fecha de fin de vigencia de autorización del activo de información no puede estar vacía
	DOCUMENTO_SIN_CLASE = 'DOCUMENTO_SIN_CLASE', //El documento del activo de información no puede estar vacío
	DOCUMENTO_SIN_TIPO = 'DOCUMENTO_SIN_TIPO', //El documento del activo de información no puede estar vacío
	DOCUMENTO_SIN_VIGENCIA = 'DOCUMENTO_SIN_VIGENCIA', //El documento del activo de información no puede estar vacío
	DOCUMENTO_SIN_FECHA_EMISION = 'DOCUMENTO_SIN_FECHA_EMISION', //El documento del activo de información no puede estar vacío
	DOCUMENTO_SIN_UUID = 'DOCUMENTO_SIN_UUID', //El documento del activo de información no puede estar vacío
	DOCUMENTO_SIN_USUARIO_CARGA = 'DOCUMENTO_SIN_USUARIO_CARGA', //El documento del activo de información no puede estar vacío
	DOCUMENTO_SIN_NOTA = 'DOCUMENTO_SIN_NOTA', //El documento del activo de información no puede estar vacío
	ARCHIVO_LEGADO_SIN_CLASE = 'ARCHIVO_LEGADO_SIN_CLASE', //El archivo legado del activo de información no puede estar vacío
	ARCHIVO_LEGADO_SIN_UUID = 'ARCHIVO_LEGADO_SIN_UUID', //El archivo legado del activo de información no puede estar vacío
	ARCHIVO_LEGADO_SIN_SHA256 = 'ARCHIVO_LEGADO_SIN_SHA256', //El archivo legado del activo de información no puede estar vacío
	ARCHIVO_LEGADO_SIN_USUARIO_CARGA = 'ARCHIVO_LEGADO_SIN_USUARIO_CARGA', //El archivo legado del activo de información no puede estar vacío
	ARCHIVO_LEGADO_SIN_NOTA = 'ARCHIVO_LEGADO_SIN_NOTA', //El archivo legado del activo de información no puede estar vacío
	TRAMITE_SIN_HOMOCLAVE = 'TRAMITE_SIN_HOMOCLAVE', //El trámite del activo de información no puede estar vacío
	TRAMITE_SIN_MODALIDAD = 'TRAMITE_SIN_MODALIDAD', //El trámite del activo de información no puede estar vacío
	TRAMITE_SIN_FECHA_SOLICITUD = 'TRAMITE_SIN_FECHA_SOLICITUD', //El trámite del activo de información no puede estar vacío
	TRAMITE_SIN_NUMERO = 'TRAMITE_SIN_NUMERO', //El trámite del activo de información no puede estar vacío
	LOG_NO_DEFINIDO = 'LOG_NO_DEFINIDO', //El log del activo de información no está definido
	LOG_SIN_FECHA = 'LOG_SIN_FECHA', //El log del activo de información no puede estar sin fecha
	LOG_SIN_VERSION = 'LOG_SIN_VERSION', //El log del activo de información no puede estar sin versión del activo de informacion
	LOG_SIN_ACCION = 'LOG_SIN_ACCION', //El log del activo de información no puede estar sin acción
	LOG_VACIO = 'LOG_VACIO' //El log del activo de información no puede estar vacío
}

/**
 * @name IAIInsumoSalud
 * @description Activo de Información de Insumo para la Salud
 */
export interface IAIInsumoSalud extends INodoRecurso {
	versionInterfaceAI: string //Versión de la interfaz de activo de información. Se incrementa al cambiar la estructura de la interfaz utilizando versionado semántico
	numeroRegistro: string //Identificador único del registro sanitario, medicamento huérfano o autorización de uso de emergencia
	versionActual: number //número de versión mas vigente del Insumo para la Salud
	rfcSolicitante: string //RFC de la persona física o moral relacionado  con el activo de información (grupo solicitante),  quién realiza las solicitudes de trámites a trávez del sistema. Este RFC debe coincidir exactamente con el RFC del grupo solicitante ya que es el valor que se utiliza para evaluar la titularidad del solicitante
	tipoAI: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD //Tipo de activo de información en el árbol de recursos
	tipoOrigen: EAIInsumoSaludOrigen //Identifica el tipo de origen desde el cual se generó el activo de información
	tipoInsumoSalud: EAIInsumoSaludTipo //Tipo de insumo para la salud que se encuentra registrado (MEDICAMENTO O DISPOSITIVO)
	tipoAutorizacion: EAIInsumoSaludTipoAutorizacion //Tipo de autorización otorgada al Insumo para la salud (Registro sanitario, medicamento huérfano, autorización de uso de emergencia)
	estadoAutorizacion: EAIInsumoSaludEstadoAutorizacion //Estado de la autorización del insumo para la salud (no confundir con vigencia, puede estar autorizado, pero vencido en vigencia)
	vigenciaInicioAutorizacion?: number //Fecha de inicio de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	vigenciaFinAutorizacion: number //Fecha de vencimiento de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	autorizador?: IAIInsumoSaludAutorizador //Autoridad que otorgó la autorización del insumo para la salud
	prorrogaRetroactiva: boolean //Indica si la prórroga es retroactiva
	prorrogaEnEvaluacion: boolean //Bandera que indica si existe actualmente un proceso de prórroga en evaluación , por lo que sería factibla solicitar modificaciones a las condiciones del registro sanitario teniendo menos de 150 días de vigencia
	prorrogado: boolean //Bandera que indica que el insumo de salud tiene al menos una prórroga emitida
	versiones: IAIInsumoSaludVersion[] //Información del insumo para la salud en una versión específica
	alertasSanitarias?: IAIInsumoSaludAlertaSanitaria[] //Alertas sanitarias asociadas al activo de información
	resumenPublico: IAIInsumoSaludResumenPublico //Resumen extraído de los datos del activo de información,  necesarios para búsquedas en RNIS. Debe ser actualizado en cada versión del activo de información
	metadatos: IAIInsumoSaludMetadatos //Metadatos computados del activo de información, necesarios para flujos de negocio. Debe ser actualizado en cada versión del activo de información
	documentos?: IAIInsumoSaludDocumento[] //Documentos generados (oficios y constancias) con los eventos que han generado o modificado el activo de información
	archivosLegados?: IAIInsumoSaludArchivoLegado[] //Archivos legados asociados al activo de información
	tramites?: IAIInsumoSaludTramite[] //Trámites relacionados con el activo de información
	log: IAIInsumoSaludLog[] //Registro de eventos que han generado o modificado el activo de información
	draft?: string //Propiedad para guardar un borrador del activo de información en el editor de activos de información
	historicoCambios?: IAIInsumoSaludHistoricoCambios[] //Historico de cambios del activo de información
}

/**
 * @name IAIInsumoSaludHistoricoCambios
 * @description Cambios en el activo de información
 */
export interface IAIInsumoSaludHistoricoCambios {
	fecha: number //Fecha de la versión en UNIX timestamp en milisegundos UTC del cambio en el activo de información
	firma: IFirmaES256 //Firma electrónica de la cadena original que el editor de activos firmó en el cambio del activo de información
}

/**
 * @name IAI_InsumoSaludVersion
 * @description Versión del activo de información de Insumo para la Salud
 */
export interface IAIInsumoSaludVersion {
	version: number //Número de versión del activo de información
	fechaVersion: number //Fecha de la versión del activo de información en milisegundos UTC
	insumoSalud: IInsumoSalud //Información del insumo para la salud en una versión específica
}

/**
 * @name IAIInsumoSaludAutorizador
 * @description Autoridad que otorgó la autorización del insumo para la salud
 */
export interface IAIInsumoSaludAutorizador {
	nombre: string //Nombre de la autoridad que otorgó la autorización del insumo para la salud
	certificado: string //Certificado de E.firma de la autoridad que otorgó la autorización del insumo para la salud
	cadenaOriginal: string //Cadena original del certificado de E.firma de la autoridad que otorgó la autorización del insumo para la salud
	firma: string //Firma electrónica de la cadena original que la autoridad firmó en la autorización del insumo para la salud
	versionAI: number //Versión del activo de información de la autoridad que otorgó la autorización del insumo para la salud
}

/**
 * @name EAIInsumoSaludEstadoAutorizacion
 * @description Estado de la autorización del insumo para la salud
 */
export enum EAIInsumoSaludEstadoAutorizacion {
	AUTORIZADO = 'AUTORIZADO', //El activo de información se autorizó inicialmente y no se ha revocado o cancelado
	CANCELADO = 'CANCELADO', //El activo de información se canceló a solicitud del administrador
	REVOCADO = 'REVOCADO' // El activo de información se revocó por parte de la autoridad
}

/**
 * @name EAIInsumoSaludOrigen
 * @description Origen del activo de información de insumo para la salud
 */
export enum EAIInsumoSaludOrigen {
	LEGADO = 'LEGADO', //El activo de información proviene de una fuente de datos legada, de una importación o migración manual por parte de la organización
	DIGITALIZADO = 'DIGITALIZADO', //El activo de información proviene de una persona de confianza, como resultado de la autorización de una Homoclave con evaluación humana
	DIGITAL = 'DIGITAL' //El activo de información nativo, creado y mantenido en el sistema de información de la organización
}

/**
 * @name IAIInsumoSaludLog
 * @description Registro de eventos que han generado o modificado el activo de información
 */
export interface IAIInsumoSaludLog {
	fecha: number //Fecha del evento en UNIX timestamp en milisegundos UTC
	version: number //Versión del activo de información que se generó con el evento, comienza en 1 y se incrementa unariamente
	accion: EAIInsumoSaludAccion //Acción que se realizó y desencadenó la generación de una nueva versión del activo de información
	nota?: string //Nota asociada al evento, si aplica (P,e,. en caso de cambio de rfc administrador, registrar el rfc anterior y el nuevo). Utilizar en eventos que modifican información de las secciones no versionables del activo de información y que no se almacenan en metadatos
}

/**
 * @name EAIInsumoSaludAccion
 * @description Acciones que pueden afectar al Activo de Información, Actualizando metadatos o generando una versión nueva
 */
export enum EAIInsumoSaludAccion {
	CREACION_IMPORTACION_LEGADO = 'CREACION_IMPORTACION_LEGADO', //Se importa un activo de información de una fuente de datos legada
	CREACION_AUTOMATIZADA = 'CREACION_AUTOMATIZADA', //Se crea la primera versión del activo de informacion (Por procesamiento automatizado en la atención a un trámite digital)
	CREACION_MANUAL = 'CREACION_MANUAL', //Se crea la primera versión del activo de informacion de manera manual, al integrar un trámite en papel que esta en cola previa a la entrada en operación del sistema o que no se importó de manera legada
	ACTUALIZACION_VERSION_AUTOMATIZADA = 'ACTUALIZACION_VERSION_AUTOMATIZADA', //Se actualiza la versión del activo de información de manera automatizada (Por procesamiento automatizado en la atención a un trámite digital)
	ACTUALIZACION_MANUAL = 'ACTUALIZACION_MANUAL', //Se actualiza la versión del insumo de salud de manera manual, al integrar un trámite en papel que esta en cola previa a la entrada en operación del sistema o corrigiendo datos erróneos en una versión previa
	PROCESO_ADMINISTRATIVO_REVOCACION_AUTORIZACION = 'PROCESO_ADMINISTRATIVO_REVOCACION_AUTORIZACION', //Se inicia un proceso administrativo para revocar la autorización del activo de información
	PROCESO_ADMINISTRATIVO_CORRECCION_INTERNA = 'PROCESO_ADMINISTRATIVO_CORRECCION_INTERNA', //Se inicia un proceso administrativo para corregir la información de un documento previamente emitido de manera interna y emitir una versión actualizada
	PROCESO_ADMINISTRATIVO_ALERTA_SANITARIA = 'PROCESO_ADMINISTRATIVO_ALERTA_SANITARIA', //Se inicia un proceso administrativo para crear una alerta sanitaria asociada al activo de información
	TRAMITE_SOLICITUD_NUEVO_INSUMO = 'TRAMITE_SOLICITUD_NUEVO_INSUMO', //Se inicia un trámite para registrar un nuevo insumo para la salud
	TRAMITE_SOLICITUD_NUEVO_INSUMO_AUTORIZACION = 'TRAMITE_SOLICITUD_NUEVO_INSUMO_AUTORIZACION', //Se inicia un trámite para autorizar un nuevo insumo para la salud
	TRAMITE_SOLICITUD_NUEVO_INSUMO_PREVENCION = 'TRAMITE_SOLICITUD_NUEVO_INSUMO_PREVENCION', //Se inicia un trámite para solicitar una prevención de insumo para la salud
	TRAMITE_SOLICITUD_NUEVO_INSUMO_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_NUEVO_INSUMO_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de insumo para la salud
	TRAMITE_SOLICITUD_NUEVO_INSUMO_DESECHO = 'TRAMITE_SOLICITUD_NUEVO_INSUMO_DESECHO', //Se inicia un trámite para solicitar el desecho de insumo para la salud
	TRAMITE_SOLICITUD_NUEVO_INSUMO_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_NUEVO_INSUMO_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de insumo para la salud
	TRAMITE_SOLICITUD_NUEVO_INSUMO_DESISTIMIENTO = 'TRAMITE_SOLICITUD_NUEVO_INSUMO_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de insumo para la salud
	TRAMITE_SOLICITUD_CANCELACION = 'TRAMITE_SOLICITUD_CANCELACION', //Se inicia un trámite para cancelar la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION = 'TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION', //Se inicia un trámite para cancelar la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_PREVENCION = 'TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_PREVENCION', //Se inicia un trámite para solicitar una prevención de la cancelación de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CANCELACION_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_CANCELACION_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de la cancelación de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_DESECHO = 'TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_DESECHO', //Se inicia un trámite para solicitar el desecho de la cancelación de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de la cancelación de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_DESISTIMIENTO = 'TRAMITE_SOLICITUD_CANCELACION_AUTORIZACION_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de la cancelación de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_PRIMERA_PRORROGA = 'TRAMITE_SOLICITUD_PRIMERA_PRORROGA', //Se inicia un trámite para solicitar la primera prórroga de autorización
	TRAMITE_SOLICITUD_PRIMERA_PRORROGA_AUTORIZACION = 'TRAMITE_SOLICITUD_PRIMERA_PRORROGA_AUTORIZACION', //Se inicia un trámite para autorizar la primera prórroga de autorización
	TRAMITE_SOLICITUD_PRIMERA_PRORROGA_PREVENCION = 'TRAMITE_SOLICITUD_PRIMERA_PRORROGA_PREVENCION', //Se inicia un trámite para solicitar una prevención de la primera prórroga de autorización
	TRAMITE_SOLICITUD_PRIMERA_PRORROGA_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_PRIMERA_PRORROGA_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de la primera prórroga de autorización
	TRAMITE_SOLICITUD_PRIMERA_PRORROGA_DESECHO = 'TRAMITE_SOLICITUD_PRIMERA_PRORROGA_DESECHO', //Se inicia un trámite para solicitar el desecho de la primera prórroga de autorización
	TRAMITE_SOLICITUD_PRIMERA_PRORROGA_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_PRIMERA_PRORROGA_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de la primera prórroga de autorización
	TRAMITE_SOLICITUD_PRIMERA_PRORROGA_DESISTIMIENTO = 'TRAMITE_SOLICITUD_PRIMERA_PRORROGA_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de la primera prórroga de autorización
	TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE = 'TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE', //Se inicia un trámite para solicitar una prórroga subsecuente de autorización
	TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_PREVENCION = 'TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_PREVENCION', //Se inicia un trámite para solicitar una prevención de la prórroga subsecuente de autorización
	TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de la prórroga subsecuente de autorización
	TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_DESECHO = 'TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_DESECHO', //Se inicia un trámite para solicitar el desecho de la prórroga subsecuente de autorización
	TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de la prórroga subsecuente de autorización
	TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_DESISTIMIENTO = 'TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de la prórroga subsecuente de autorización
	TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_AFIRMACION_FICTA = 'TRAMITE_SOLICITUD_PRORROGA_SUBSECUENTE_AFIRMACION_FICTA', //Se inicia un trámite para afirmar la prórroga subsecuente de autorización
	TRAMITE_SOLICITUD_CESION_DERECHOS = 'TRAMITE_SOLICITUD_CESION_DERECHOS', //Se inicia un trámite para solicitar la cesión de derechos de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CESION_DERECHOS_AUTORIZACION = 'TRAMITE_SOLICITUD_CESION_DERECHOS_AUTORIZACION', //Se inicia un trámite para autorizar la cesión de derechos de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CESION_DERECHOS_PREVENCION = 'TRAMITE_SOLICITUD_CESION_DERECHOS_PREVENCION', //Se inicia un trámite para solicitar una prevención de la cesión de derechos de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CESION_DERECHOS_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_CESION_DERECHOS_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de la cesión de derechos de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CESION_DERECHOS_DESECHO = 'TRAMITE_SOLICITUD_CESION_DERECHOS_DESECHO', //Se inicia un trámite para solicitar el desecho de la cesión de derechos de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CESION_DERECHOS_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_CESION_DERECHOS_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de la cesión de derechos de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_CESION_DERECHOS_DESISTIMIENTO = 'TRAMITE_SOLICITUD_CESION_DERECHOS_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de la cesión de derechos de la autorización del insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MENORES = 'TRAMITE_SOLICITUD_MODIFICACIONES_MENORES', //Se inicia un trámite para solicitar modificaciones menores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_PREVENCION', //Se inicia un trámite para solicitar una prevención de modificaciones menores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de modificaciones menores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_DESECHO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_DESECHO', //Se inicia un trámite para solicitar el desecho de modificaciones menores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de modificaciones menores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_DESISTIMIENTO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de modificaciones menores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_AFIRMACION_FICTA = 'TRAMITE_SOLICITUD_MODIFICACIONES_MENORES_AFIRMACION_FICTA', //Se inicia un trámite para afirmar modificaciones menores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS = 'TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS', //Se inicia un trámite para solicitar modificaciones moderadas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_PREVENCION', //Se inicia un trámite para solicitar una prevención de modificaciones moderadas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de modificaciones moderadas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_DESECHO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_DESECHO', //Se inicia un trámite para solicitar el desecho de modificaciones moderadas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de modificaciones moderadas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_DESISTIMIENTO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de modificaciones moderadas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_AFIRMACION_FICTA = 'TRAMITE_SOLICITUD_MODIFICACIONES_MODERADAS_AFIRMACION_FICTA', //Se inicia un trámite para afirmar modificaciones moderadas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES = 'TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES', //Se inicia un trámite para solicitar modificaciones mayores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_AUTORIZACION = 'TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_AUTORIZACION', //Se inicia un trámite para autorizar modificaciones mayores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_PREVENCION', //Se inicia un trámite para solicitar una prevención de modificaciones mayores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de modificaciones mayores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_DESECHO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_DESECHO', //Se inicia un trámite para solicitar el desecho de modificaciones mayores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de modificaciones mayores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_DESISTIMIENTO = 'TRAMITE_SOLICITUD_MODIFICACIONES_MAYORES_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de modificaciones mayores al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS', //Se inicia un trámite para solicitar modificaciones administrativas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_ATENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_ATENCION', //Se inicia un trámite para autorizar modificaciones administrativas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_AUTORIZACION = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_AUTORIZACION', //Se inicia un trámite para autorizar modificaciones administrativas al insumo para la salud para productos que generan oficio
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_PREVENCION', //Se inicia un trámite para solicitar una prevención de modificaciones administrativas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de modificaciones administrativas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_DESECHO = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_DESECHO', //Se inicia un trámite para solicitar el desecho de modificaciones administrativas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de modificaciones administrativas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_DESISTIMIENTO = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_DESISTIMIENTO', //Se inicia un trámite para solicitar el desistimiento de modificaciones administrativas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_AFIRMACION_FICTA = 'TRAMITE_SOLICITUD_MODIFICACIONES_ADMINISTRATIVAS_AFIRMACION_FICTA', //Se autriza un trámite por tiempo para licitudes que generan constancias y no fueron atendidas
	TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS = 'TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS', //Se inicia un trámite para solicitar modificaciones técnicas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_AUTORIZACION = 'TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_AUTORIZACION', //Se inicia un trámite para autorizar modificaciones técnicas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_PREVENCION', //Se inicia un trámite para solicitar una prevención de modificaciones técnicas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_RESPUESTA_PREVENCION = 'TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_RESPUESTA_PREVENCION', //Se inicia un trámite para responder una prevención de modificaciones técnicas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_DESECHO = 'TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_DESECHO', //Se inicia un trámite para solicitar el desecho de modificaciones técnicas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_DESECHO_EXPIRADO = 'TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_DESECHO_EXPIRADO', //Se inicia un trámite para solicitar el desecho de modificaciones técnicas al insumo para la salud
	TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_DESISTIMIENTO = 'TRAMITE_SOLICITUD_MODIFICACIONES_TECNICAS_DESISTIMIENTO' //Se inicia un trámite para solicitar el desistimiento de modificaciones técnicas al insumo para la salud
}

/**
 * @name EAIInsumoSaludTipoAutorizacion
 * @description Tipo de autorización otorgada al Insumo para la salud
 */
export enum EAIInsumoSaludTipoAutorizacion {
	REGISTRO_SANITARIO = 'REGISTRO_SANITARIO', //El activo de información se generó a partir de la autorización de un insumo para la salud como registro sanitario
	MEDICAMENTO_HUERFANO = 'MEDICAMENTO_HUERFANO', //El activo de información se generó a partir de la autorización por el reconocimiento de un medicamento como huérfano
	USO_EMERGENCIA = 'USO_EMERGENCIA' //El activo de información se generó a partir de la autorización de un insumo para la salud como autorización de uso de emergencia
}

/**
 * @name EAIInsumoSaludTipo
 * @description Tipo de insumo para la salud que se encuentra registrado
 */
export enum EAIInsumoSaludTipo {
	MEDICAMENTO = 'MEDICAMENTO', //Engloba a medicamentos alopáticos, homeopáticos, biológicos, herbolarios, vitamínicos, vacunas, etc.
	DISPOSITIVO = 'DISPOSITIVO' //Engloba a dispositivo médico, prótesis, equipo médico, material de curación, materiales quirúrgicos, etc.
}

/**
 * @name IAIInsumoSaludProcesarAccionParams
 */

export interface IAIInsumoSaludProcesarAccionParams {
	accion: EAIInsumoSaludAccion //Acción que se realizará sobre el activo de información
	_id?: string //Identificador único del activo de información
	numeroRegistro?: string //Número de registro del activo de información
	tipoInsumoSalud: EAIInsumoSaludTipo //Tipo de insumo para la salud que se encuentra registrado (MEDICAMENTO O DISPOSITIVO)
	tipoAutorizacion: EAIInsumoSaludTipoAutorizacion //Tipo de autorización otorgada al Insumo para la salud (Registro sanitario, medicamento huérfano, autorización de uso de emergencia)
	estadoAutorizacion: EAIInsumoSaludEstadoAutorizacion //Estado de la autorización del insumo para la salud (no confundir con vigencia, puede estar autorizado, pero vencido en vigencia)
	vigenciaInicioAutorizacion?: number //Fecha de vencimiento de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	vigenciaFinAutorizacion: number //Fecha de vencimiento de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	prorrogado: boolean //Indica si la autorización del insumo para la salud ha sido prorrogada
	prorrogaEnEvaluacion: boolean //Indica si la autorización del insumo para la salud tiene una prórroga en evaluación
	prorrogaRetroactiva: boolean //Indica si la autorización del insumo para la salud tiene una prórroga retroactiva
	grupo: string //Grupo al que pertenece un activo de información
	rfcSolicitante: string //RFC del solicitante que puede realizar solicitudes sobre el activo de información
	versionActual?: number //Versión actual del activo de información
	versiones?: IAIInsumoSaludVersion[] //Versiones del activo de información
	insumoSalud: IInsumoSalud
	alertasSanitarias?: IAIInsumoSaludAlertaSanitaria[] //Alertas sanitarias asociadas al activo de información
	documentoGenerado?: IGeneratedDoc & IAIInsumoSaludDocumento
	documentoACancelar?: EAIInsumoSaludDocumentoTipo
	archivosLegados?: IAIInsumoSaludArchivoLegado[] //Archivos legados asociados al activo de información
	idTramite?: string //Identificador único del trámite que se está procesando
	tramite?: IAIInsumoSaludTramite
}

export interface IAIInsumoSaludProcesarAccionResponse {
	versionActual: number //Versión actual del activo de información
	nota: string //Nota asociada al evento, si aplica
}

/**
 * @name IAIInsumoSaludAlertaSanitaria
 * @description Alerta sanitaria asociada al activo de información. Las alertas sanitarias son avisos que hace la autoridad con respecto a medicamentos, dispositivos, tendencias, lugares, entre otros, que pueden representar un riesgo para la salud
 */
export interface IAIInsumoSaludAlertaSanitaria {
	idAlertaSanitaria?: string //Identificador único de la alerta sanitaria emitida por cofepris (fuera del sistema, simplemente se referencía), pe, 2021-1234
	estadoAlertaSanitaria: EAIInsumoSaludAlertaSanitariaEstado //Estado de la alerta sanitaria asociada al activo de información, puede estar activa o inactiva
	urlAlertaSanitaria?: string //URL de la alerta sanitaria emitida por cofepris (fuera del sistema, simplemente se referencía), p,e, https://www.gob.mx/cofepris/alertas/2021-1234
	nivelAlertaSanitaria: EAIInsumoSaludAlertaSanitariaNivel //Nivel de la alerta sanitaria en función del riesgo que representa, puede ser informativa, de advertencia o de peligro
	documentoAlertaSanitaria?: IFileType[] //Documento de la alerta sanitaria emitida por cofepris (fuera del sistema, simplemente se referencía), p,e, https://www.gob.mx/cofepris/alertas/2021-1234.pdf
}

/**
 * @name EAIInsumoSaludAlertaSanitariaEstado
 * @description Estado de la alerta sanitaria asociada al activo de información
 */
export enum EAIInsumoSaludAlertaSanitariaEstado {
	ACTIVA = 'ACTIVA', //La alerta sanitaria se encuentra activa, aún representa un riesgo para la salud
	INACTIVA = 'INACTIVA' //La alerta sanitaria ya no se considera un riesgo, pero se guarda el registro por razones históricas
}

/**
 * @name EAIInsumoSaludAlertaSanitariaNivel
 * @description Nivel de la alerta sanitaria en función del riesgo que representa
 */
export enum EAIInsumoSaludAlertaSanitariaNivel {
	INFORMACION = 'INFORMACION', //Alertas informativas, Ejem. robo de medicamentos
	ADVERTENCIA = 'ADVERTENCIA', //Alertas de advertencia, como falsificación o comercialización ilegal
	PELIGRO = 'PELIGRO' //Alertas de peligro, como reacciones adversas graves, defectos de calidad o seguridad
}

/**
 * @name IAIInsumoSaludResumenPublico
 * @description Resumen de la información del activo de información,  necesarios para búsquedas en RNIS, extraídos de la última versión del Insumo para la Salud
 * Los elementos de este resumen deben ser actualizados en cada versión del activo de información
 * El objeto debe ser plano, para ser capaz de realizar búsquedas por texto y número en sus nodos
 * Se debe incluir la información necesaria que será pública para identificar el activo de información en búsquedas en RNIS, ya que el RNIS solo debería mostrar la información de este nodo
 */
export interface IAIInsumoSaludResumenPublico {
	numeroRegistro: string //Identificador único del registro sanitario, medicamento huérfano o autorización de uso de emergencia
	nombreTitular: string //Nombre del titular de la autorización del insumo para la salud
	tipoInsumoSalud: EAIInsumoSaludTipo //Tipo de insumo para la salud que se encuentra registrado (MEDICAMENTO O DISPOSITIVO)
	tipoAutorizacion: EAIInsumoSaludTipoAutorizacion //Tipo de autorización otorgada al Insumo para la salud (Registro sanitario, medicamento huérfano, autorización de uso de emergencia)
	estadoAutorizacion: EAIInsumoSaludEstadoAutorizacion //Estado de la autorización del insumo para la salud (no confundir con vigencia, puede estar autorizado, pero vencido en vigencia)
	fechaInicioVigenciaAutorizacion: number //Fecha de inicio de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	fechaFinVigenciaAutorizacion: number //Fecha de vencimiento de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	denominacionDistintiva: string //Nombre comercial del producto
	denominacionGenerica: string //Nombre genérico del producto
	presentaciones?: string[] //resumen de presentaciones asociadas al activo de información (Caja con 10 tabletas de 500 mg, blister con 2 tabletas de 100 mg etc.)
	numeroAlertasSanitariasActivas: number //Número de alertas sanitarias activas
	consultaPatente?: string //Número de consulta de patente
	medicamentoReferencia?: string //Nombre del medicamento de referencia
	informeTecnico?: string //Número de informe técnico
	pruebaIntercambiabilidad?: string //Texto descriptivo de la prueba de intercambiabilidad
	categoriaDispositivoLGS262?: EDispositivoCategoriaLGS262 //Categoría de dispositivo médico según la LGS262 asociada al activo de información
	claseDispositivoRIS83?: EDispositivoClaseRIS83 //Clase de dispositivo médico según el RIS83 asociada al activo de información
	claseMedicamentoLGS226?: EMedicamentoClaseLGS226 //Clase de medicamento según la LGS226 asociada al activo de información
	tipoAlopatico?: EMedicamentoTipoAlopatico //Tipo de medicamento alopático asociado al activo de información
}

/**
 * @name IAIInsumoSaludMetadatos
 * @description Metadatos computados del activo de información, necesarios para flujos de negocio, extraídos de la última versión del Insumo para la Salud y de los datos del activo de información
 * Los elementos de este resumen deben ser actualizados en cada versión del activo de información
 * El objeto debe ser plano, para ser capaz de realizar búsquedas por texto y número en sus nodos
 * Se debe incluir la información necesaria que será interna para identificar el activo de información en búsquedas internas de Insumos de Salud
 */
export interface IAIInsumoSaludMetadatos {
	fechaAutorizacionInicial?: number //Fecha de autorización inicial del insumo para la salud en UNIX timestamp en milisegundos UTC
	fechaInicioVigenciaAutorizacion?: number //Fecha de inicio de vigencia de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	fechaFinVigenciaAutorizacion: number //Fecha de fin de vigencia de la autorización del insumo para la salud en UNIX timestamp en milisegundos UTC
	numeroConstanciasCanceladas: number //Número de constancias de modificación menor o moderadas canceladas
	numeroConstanciasCanceladasPrevioVerificacionHumana: number //Número de constancias de modificación menor o moderadas canceladas desde la última verificacion humana (autorización o primera prorroga)
	fechaLimiteSolicitudProrroga: number //Fecha límite para solicitar la primera prórroga de autorización en UNIX timestamp en milisegundos UTC
	numeroProrrogas: number //Número de prórrogas autorizadas (que se han realizado de manera digital en el sistema DRL, no se contemplan las prórrogas legadas)
	idTramiteAutorizacionInicial?: string //ID del Trámite en el cual se generó la primera autorización del insumo para la salud
	idUltimoTramiteAutorizadoEvaluacionHumana?: string //ID del Trámite de evaluación humana último que generó un oficio  de autorización (modificación mayor|técnica|administrativa|cesión|primera prórroga)
	idPenultimoTramiteAutorizadoEvaluacionHumana?: string //ID del Trámite de evaluación humana previo al último que generó un oficio  de autorización (modificación mayor|técnica|administrativa|cesión|primera prórroga)
	idTramiteEvaluacionHumanaEnProcesoSinResolucion?: string //ID del Trámite de evaluación humana pendiente de resolución que podría bloquear la solicitud de nuevos trámites
	fechaAutorizacionUltimoTramiteEvaluacionHumana?: number //Fecha de autorización del último trámite de evaluación humana en UNIX timestamp en milisegundos UTC
	fechaAutorizacionPenultimoTramiteEvaluacionHumana?: number //Fecha de autorización del penúltimo trámite de evaluación humana en UNIX timestamp en milisegundos UTC
	fechaSolicitudTramiteEvaluacionEnProcesoSinResolucion?: number //Fecha de solicitud del trámite de evaluación humana pendiente de resolución en UNIX timestamp en milisegundos UTC
	numeroAnexos?: number //Número de anexos asociados al activo de información
	clavesCuadroBasico?: string[] //Claves del cuadro básico de medicamentos asociadas al activo de información (01.10.11.23, 01.21.45.12, etc.)
	registrosSanitariosRelacionados?: string[] //Registros sanitarios relacionados al activo de información (252524 SSA, 252525 SSA, etc.)
	farmacos?: string[] //resumen de fármacos relacionados al medicamento (Ácido acetilsalicílico, cafeína, paracetamol, etc)
	aditivos?: string[] //resumen de aditivos relacionados al (Amarillo #5, Jarabe de Maíz, etc.)
}

/**
 * @name IAIInsumoSaludTramite
 * @description Trámite relacionado con el activo de información de insumo para la salud
 */
export interface IAIInsumoSaludTramite {
	homoclave: string //Homoclave del trámite de evaluación humana
	modalidad: string //Modalidad del trámite de evaluación humana
	fechaSolicitud: number //Fecha de solicitud del trámite en UNIX timestamp en milisegundos UTC,
	fechaResolucion?: number //Fecha de resolución del trámite en UNIX timestamp en milisegundos UTC
	numeroTramite: string //Número de trámite de evaluación humana pendiente de resolución
	resolucion?: EHomoclaveContextTipoResolucion //Resolución del trámite
	numeroResolucion?: string //Número de resolución del trámite
}

/**
 * @name IAIInsumoSaludDocumento
 * @description Documento generado (oficios y constancias) con los eventos que han generado o modificado el activo de información
 */
export interface IAIInsumoSaludDocumento {
	claseDocumento: EDocumentoClase //Clase de documento que se integra al activo de información
	tipoDocumento: EAIInsumoSaludDocumentoTipo //Tipo de documento que se integra al activo de información
	vigenciaDocumento: EDocumentoEstado //Vigencia del documento
	fechaEmision: number //Fecha de emisión del documento en UNIX timestamp en milisegundos UTC
	uuidDocumento: string //UUID del documento generado
	numeroTramite?: string //Número de trámite asociado al documento
	uuidUsuarioCarga?: string //UUID del usuario que cargó el documento
	nota: string //Notas adicionales sobre el documento
}

/**
 * @name IAIInsumoSaludArchivoLegado
 * @description Documentos legados almacenados en el activo de información
 */
export interface IAIInsumoSaludArchivoLegado {
	claseDocumento: EDocumentoClase //Clase de documento que se integra al activo de información
	uuidDocumento: string //UUID del documento generado
	sha256: string //SHA256 del archivo
	uuidUsuarioCarga: string //UUID del usuario que cargó el documento
	nota: string //Notas adicionales sobre el documento
	documentoLegado: IFileType[] //Documento legado
}

/**
 * @name EAIInsumoSaludDocumentoTipo
 * @description Tipo de documento que se integra al activo de información
 */
export enum EAIInsumoSaludDocumentoTipo {
	OFICIO_AUTORIZACION_REGISTRO_SANITARIO = 'OFICIO_AUTORIZACION_REGISTRO_SANITARIO', //Oficio de autorización de registro sanitario
	OFICIO_AUTORIZACION_RECONOCIMIENTO_MEDICAMENTO_HUERFANO = 'OFICIO_AUTORIZACION_RECONOCIMIENTO_MEDICAMENTO_HUERFANO', //Oficio de autorización de medicamento huérfano
	OFICIO_AUTORIZACION_USO_EMERGENCIA = 'OFICIO_AUTORIZACION_USO_EMERGENCIA', //Oficio de autorización de uso de emergencia
	CONSTANCIA_SUBSECUENTE_PRORROGA = 'CONSTANCIA_SUBSECUENTE_PRORROGA', //Constancia de prórroga subsecuente
	CONSTANCIA_MODIFICACION_MENOR = 'CONSTANCIA_MODIFICACION_MENOR', //Constancia de modificación menor
	CONSTANCIA_MODIFICACION_MODERADA = 'CONSTANCIA_MODIFICACION_MODERADA', //Constancia de modificación moderada
	CONSTANCIA_MODIFICACION_ADMINISTRATIVA = 'CONSTANCIA_MODIFICACION_ADMINISTRATIVA', //Constancia de modificación administrativa a Medicamentos
	OFICIO_MODIFICACION_MAYOR = 'OFICIO_MODIFICACION_MAYOR', //Oficio de modificación mayor
	OFICIO_CESION_DERECHOS = 'OFICIO_CESION_DERECHOS', //Oficio de cesión de derechos
	OFICIO_CANCELACION_AUTORIZACION = 'OFICIO_CANCELACION_AUTORIZACION', //Oficio de cancelación de autorización
	OFICIO_REVOCACION_AUTORIZACION = 'OFICIO_REVOCACION_AUTORIZACION', //Oficio de revocación de autorización
	OFICIO_ACTUALIZADO_PRIMERA_PRORROGA = 'OFICIO_ACTUALIZADO_PRIMERA_PRORROGA', //Oficio de actualización por primera prórroga
	OFICIO_ACTUALIZADO_CORRECCION = 'OFICIO_ACTUALIZADO_CORRECCION', //Oficio de actualización por corrección
	OFICIO_DESISTIMIENTO = 'OFICIO_DESISTIMIENTO', //Oficio de actualización por corrección
	OFICIO_PREVENCION = 'OFICIO_PREVENCION', //Oficio de prevención de insumo para la salud
	OFICIO_DESECHO = 'OFICIO_DESECHO', //Oficio de desecho de insumo para la salud
	SOLICITUD = 'SOLICITUD', //Solicitud de trámite
	ACUSE = 'ACUSE', //Acuse de solicitud de trámite o respuesta a prevención
	RESPUESTA_PREVENCION = 'RESPUESTA_PREVENCION', //Respuesta a oficio de prevención
	OTRO = 'OTRO' //Otros tipos de documentos
}

/**
 * @name IAIInsumoSaludQueryInput
 * @description Entrada de la consulta de activos de información de insumos para la salud
 */
export interface IAIInsumoSaludQueryInput {
	id?: string //Identificador único del activo de información
	numeroRegistro?: string //Número de registro del activo de información
	tipoInsumoSalud?: EAIInsumoSaludTipo //Tipo de insumo para la salud que se encuentra registrado (MEDICAMENTO O DISPOSITIVO)
	tiposMedicamento?: EMedicamentoTipo[] //Tipos de medicamento que se encuentran registrados
	categoriasDispositivoLGS262?: EDispositivoCategoriaLGS262[] //Tipos de dispositivo que se encuentran registrados
	clasesDispositivoRIS83?: EDispositivoClaseRIS83[] //Clases de dispositivo que se encuentran registrados
	prorrogaRetroactiva?: boolean //Indica si se requiere que la prórroga sea retroactiva
	denominacionDistintiva?: string //Nombre comercial del producto
	denominacionGenerica?: string //Nombre genérico del producto
	nombreTitular?: string //Nombre del titular de la autorización del insumo para la salud
	prorrogaEnEvaluacion?: boolean //Indica si la autorización del insumo para la salud tiene una prórroga en evaluación
}

/**
 *
 * @name obtenerDiasNaturalesMinimosSolicitudProrroga
 * @description Obtiene los días naturales mínimos requeridos para solicitar una prórroga de autorización de un insumo para la salud
 */
export const obtenerDiasNaturalesMinimosSolicitudProrroga = (tipoInsumoSalud: EAIInsumoSaludTipo, tipoMedicamento?: EMedicamentoTipo): number => {
	if (tipoInsumoSalud === EAIInsumoSaludTipo.MEDICAMENTO) {
		if (tipoMedicamento == EMedicamentoTipo.HUERFANO) {
			return CIAIInsumoSaludDiasNaturalesMinimosRequeridosParaSolicitudProrrogaReconocimientoMedicamentoHuerfano
		} else {
			return CIAIInsumoSaludDiasNaturalesMinimosRequeridosParaSolicitudProrrogaRegistroSanitario
		}
	} else if (tipoInsumoSalud === EAIInsumoSaludTipo.DISPOSITIVO) {
		return CIAIInsumoSaludDiasNaturalesMinimosRequeridosParaSolicitudProrrogaRegistroSanitario
	} else {
		throw new Error('Tipo de insumo para la salud no soportado')
	}
}
