/**
 * @module Dates
 * @author @tirsomartinezreyes
 * @version 1.0.0
 * @description Módulo para Manejo de fechas
 * */

/**
 * @name getDateByMillis
 * @description Obtiene la fecha en formato dd/MM/yyyy con o sin HH:mm:ss a partir de un timestamp
 */
export function getDateByMillis(millis: number, short: boolean = false, dia: boolean = false): string {
	let response = ''
	if (typeof millis == 'string') {
		millis = parseInt(millis)
	}
	if (millis) {
		const tmp = new Date(millis)
		const year: number = tmp.getFullYear()
		const day = (tmp.getDate() < 10 ? '0' + tmp.getDate() : tmp.getDate()).toString()
		const hours = (tmp.getHours() < 10 ? '0' + tmp.getHours() : tmp.getHours()).toString()
		const minutes = (tmp.getMinutes() < 10 ? '0' + tmp.getMinutes() : tmp.getMinutes()).toString()
		const seconds = (tmp.getSeconds() < 10 ? '0' + tmp.getSeconds() : tmp.getSeconds()).toString()

		const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
		const monthName = monthNames[tmp.getMonth()]
		const dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
		const dayName = dayNames[tmp.getDay()]
		if (dia) {
			response = `${dayName} `
		}

		if (short) {
			response += `${day}/${monthName}/${year}`
		} else {
			response += `${day}/${monthName}/${year} ${hours}:${minutes}:${seconds}`
		}
	}
	return response
}

export const getTodayStartMillis = (): number => {
	const today = new Date()
	today.setHours(0, 0, 0, 0)
	return today.getTime()
}

export const getTodayEndMillis = (): number => {
	const today = new Date()
	today.setHours(23, 59, 59, 999)
	return today.getTime()
}

export const CMonthNames = Object.freeze(['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'])
export const CWeekDays = Object.freeze(['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'])
export const CWeekDaysShort = Object.freeze(['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'])
