//Packages
import * as jose from 'jose'
import { defineStore } from 'pinia'
import { computed, onMounted, reactive, ref } from 'vue'

//Project
import { RSASignContext } from 'src/components/RSASignature/bom'
import { callAuthenticationAPI } from 'src/controllers/auth'
import { redirectToPathByRoleAndGroup } from 'src/router/redirect'
import { useLogRocketStore } from 'src/store/logrocket'
import { useConfigStore } from 'src/store/config'
import * as Sentry from '@sentry/vue'
import { useSessionStore } from 'src/store/session'
import { AuthUserData, AuthenticationAPIRequest, AuthenticationAPIRequestBody, AuthenticationAPIResponse, IdentityToken } from 'cofepris-typesafe/Types/Auth'
import { Funciones, Grupo, Rol, RolType, Roles } from 'cofepris-typesafe/Types/Roles'
import router from 'src/router'
import { AccessToken } from 'cofepris-typesafe/Types/Auth/AccessToken'

const defaultUserData: AuthUserData = {
	activeRole: Roles.ROL_ANONIMO,
	activeGroup: {
		grupo: '',
		roles: [] as Rol[],
		solicitante: ''
	} as Grupo,
	identityToken: {
		iat: 0,
		exp: 0,
		iss: '',
		sub: '',
		jti: '',
		data: {
			uid: '',
			uuid: '',
			nombre: '',
			grupos: [] as Grupo[],
			requiereAutenticador: false,
			autenticadorRegistrado: false
		}
	} as IdentityToken,
	accessToken: {
		iat: 0,
		exp: 0,
		jti: '',
		iss: '',
		sub: '',
		data: {
			uid: '',
			uuid: '',
			nombre: '',
			grupo: '',
			rol: 'ANONIMO',
			solicitante: '',
			identityToken: ''
		}
	} as AccessToken,
	certificadoB64: '',
	IdentityJWT: '',
	AccessJWT: ''
}

export const useUserStore = defineStore('user', () => {
	const debug = ref(false)
	const User = reactive(JSON.parse(JSON.stringify(defaultUserData)) as AuthUserData)

	onMounted(() => {
		getUserFromSessionStorage()
		debug.value && console.log(getIdentityTokenLiveRemainSeconds() + ' segundos de vigencia del token de identidad')
		debug.value && console.log(getAccessTokenLiveRemainSeconds() + ' segundos de vigencia del token de acceso')
	})

	async function RSALogin(context: RSASignContext) {
		debug.value && console.log('on RSALogin')
		await login(context.jwt as string, context.certificadoB64 as string)
	}

	async function webAuthnLogin(jwt: string, certificadoB64: string) {
		debug.value && console.log('on webAuthnLogin')
		await login(jwt, certificadoB64)
	}

	async function login(jwt: string, certificadoB64: string) {
		debug.value && console.log('on login')
		const alg = 'RS256'
		try {
			const publicKey = await jose.importSPKI(useConfigStore().getEnvironmentConfig().AUTHENTICATION.JWK_PUBLIC, alg)
			const JWTDecoded = await jose.jwtVerify(jwt, publicKey, {
				issuer: useConfigStore().getEnvironmentConfig().AUTHENTICATION.JWT_ISSUER
			})
			User.identityToken.iat = JWTDecoded.payload.iat as IdentityToken['iat']
			User.identityToken.jti = JWTDecoded.payload.jti as IdentityToken['jti']
			User.identityToken.exp = JWTDecoded.payload.exp as IdentityToken['exp']
			User.identityToken.iss = JWTDecoded.payload.iss as IdentityToken['iss']
			User.identityToken.sub = JWTDecoded.payload.sub as IdentityToken['sub']
			User.identityToken.data = { ...(JWTDecoded.payload.data as IdentityToken['data']) }
			User.IdentityJWT = jwt
			User.certificadoB64 = certificadoB64

			//Instrumentación de UI
			if (import.meta.env.PROD) {
				useLogRocketStore().setUser(User.identityToken.data.uid)
				Sentry.setUser({
					id: User.identityToken.data.uuid,
					username: User.identityToken.data.uid
				})
			}

			setUserToSessionStorage()
			useSessionStore().machine.send('LOGIN')
			if (User.identityToken.data.grupos.length === 1) {
				if (User.identityToken.data.grupos[0].roles.length === 1) {
					await setCurrentRole(User.identityToken.data.grupos[0], User.identityToken.data.grupos[0].roles[0], User.IdentityJWT)
					useSessionStore().machine.send('UNIQUE_ROLE')
				}
			} else {
				useSessionStore().machine.send('LOGIN')
			}
		} catch (e) {
			console.error(e as Error)
			if (e instanceof Error) {
				useSessionStore().machine.send('REQUEST_LOGIN_FAIL', { requestLoginError: e.message })
			} else {
				useSessionStore().machine.send('REQUEST_LOGIN_FAIL', { requestLoginError: e })
			}
		}
	}

	async function setCurrentRole(group: Grupo, rol: Rol, IdentityJWT: string) {
		debug.value && console.log('on setCurrentRole', [group, rol])
		const AccessJWT = await getRoleToken(IdentityJWT, group.grupo, rol.type)
		const alg = 'RS256'
		try {
			const publicKey = await jose.importSPKI(useConfigStore().getEnvironmentConfig().AUTHENTICATION.JWK_PUBLIC, alg)
			const JWTDecoded = await jose.jwtVerify(AccessJWT, publicKey, {
				issuer: useConfigStore().getEnvironmentConfig().AUTHENTICATION.JWT_ISSUER
			})
			User.activeRole = rol
			User.activeGroup = group
			User.accessToken.data = { ...(JWTDecoded.payload.data as AccessToken['data']) }
			User.accessToken.iat = JWTDecoded.payload.iat as AccessToken['iat']
			User.accessToken.sub = JWTDecoded.payload.sub as AccessToken['sub']
			User.accessToken.iss = JWTDecoded.payload.iss as AccessToken['iss']
			User.accessToken.exp = JWTDecoded.payload.exp as AccessToken['exp']
			User.accessToken.jti = JWTDecoded.payload.jti as AccessToken['jti']
			User.AccessJWT = AccessJWT
			setUserToSessionStorage()
			redirectToPathByRoleAndGroup(rol.type, group.grupo)
			useSessionStore().machine.send('SELECT_ROLE')
		} catch (e) {
			console.error(e as Error)
			if (e instanceof Error) {
				useSessionStore().machine.send('REQUEST_ROLE_FAIL', { requestRoleError: e.message })
			} else {
				useSessionStore().machine.send('REQUEST_ROLE_FAIL', { requestRoleError: e })
			}
		}
	}

	async function getRoleToken(IdentityJWT: string, grupo: string, rol: RolType): Promise<string> {
		debug.value && console.log('on getRoleToken', [grupo, rol])
		useSessionStore().machine.send('REQUEST_ROLE')
		try {
			const access_Token = await getRoleAccessToken(IdentityJWT, grupo, rol)
			return access_Token
		} catch (e) {
			console.error(e)
			if (e instanceof Error) {
				useSessionStore().machine.send('REQUEST_ROLE_FAIL', { requestRoleError: e.message })
			} else {
				useSessionStore().machine.send('REQUEST_ROLE_FAIL', { requestRoleError: e })
			}
			return ''
		}
	}

	async function getRoleAccessToken(IdentityJWT: string, grupo: string, rol: RolType): Promise<string> {
		debug.value && console.log('on getRoleAccessToken', [grupo, rol])
		return new Promise((resolve, reject) => {
			//requerido para evitar decorar el Promise con async
			// eslint-disable-next-line @typescript-eslint/no-extra-semi
			;(async () => {
				const body: AuthenticationAPIRequestBody['RSA_GET_ACCESS_TOKEN'] = {
					grupo,
					rol
				}

				const request: AuthenticationAPIRequest = {
					accion: 'RSA_GET_ACCESS_TOKEN',
					body: JSON.stringify(body)
				}

				const response: AuthenticationAPIResponse = await callAuthenticationAPI(request, IdentityJWT)

				if (response.status == 'OK') {
					resolve(response.data.accessToken as string)

					//Instrumentación de UI
					if (import.meta.env.PROD) {
						Sentry.setContext('AccessToken', {
							rol,
							grupo,
							accessToken: response.data.accessToken
						})
					}
				} else {
					reject(response.message)
				}
			})()
		})
	}

	async function renewRoleAccessToken() {
		debug.value && console.log('on renewRoleAccessToken')
		const newAccessToken = await getRoleAccessToken(User.IdentityJWT, User.activeGroup.grupo, User.activeRole.type)
		const alg = 'RS256'
		try {
			const publicKey = await jose.importSPKI(useConfigStore().getEnvironmentConfig().AUTHENTICATION.JWK_PUBLIC, alg)
			const JWTDecoded = await jose.jwtVerify(newAccessToken, publicKey, {
				issuer: useConfigStore().getEnvironmentConfig().AUTHENTICATION.JWT_ISSUER
			})
			User.accessToken.data = { ...(JWTDecoded.payload.data as AccessToken['data']) }
			User.accessToken.exp = JWTDecoded.payload.exp as AccessToken['exp']
			User.accessToken.jti = JWTDecoded.payload.jti as AccessToken['jti']
			User.AccessJWT = newAccessToken
			debug.value && console.log('Token de acceso renovado')
			setUserToSessionStorage()
		} catch (e) {
			console.error(e as Error)
			if (e instanceof Error) {
				console.error(e.message)
			} else {
				console.error(e)
			}
		}
	}

	async function logOut(): Promise<void> {
		debug.value && console.log('on logOut')
		router.replace('/sitio/logout')
		return new Promise(resolve => {
			const request: AuthenticationAPIRequest = {
				accion: 'LOGOUT',
				body: '{}'
			}
			try {
				callAuthenticationAPI(request, useUserStore().User.IdentityJWT)
					.then(response => {
						debug.value && console.log(response)
					})
					.catch(e => {
						console.error(e)
					})
					.finally(() => {
						localLogOut()
						resolve()
					})
			} catch (e) {
				console.error(e)
				localLogOut()
				resolve()
			}
		})
	}

	function localLogOut(): void {
		debug.value && console.log('on localLogOut')
		setTimeout(() => {
			const defaultData = JSON.parse(JSON.stringify(defaultUserData)) as AuthUserData
			Object.assign(User, defaultData)
			clearUserFromSessionStorage()
		}, 500)
	}

	function setUserToSessionStorage() {
		debug.value && console.log('on setUserToSessionStorage')
		sessionStorage.setItem('User', JSON.stringify(User))
	}

	function getUserFromSessionStorage() {
		debug.value && console.log('on getUserFromSessionStorage')
		const user = JSON.parse(sessionStorage.getItem('User') ?? '{}')
		Object.assign(User, user)
	}

	function clearUserFromSessionStorage() {
		debug.value && console.log('on clearUserFromSessionStorage')
		sessionStorage.removeItem('User')
	}

	function getAccessTokenLiveRemainSeconds() {
		let response = 0
		if (User.accessToken.exp) {
			const timeToLive = User.accessToken.exp - Math.floor(Date.now() / 1000)
			if (timeToLive > 0) {
				response = timeToLive
			}
		}
		return response
	}

	function getIdentityTokenLiveRemainSeconds() {
		debug.value && console.log('on getIdentityTokenLiveRemainSeconds')
		let response = 0
		if (User.identityToken.exp) {
			const timeToLive = User.identityToken.exp - Math.floor(Date.now() / 1000)
			if (timeToLive > 0) {
				response = timeToLive
			}
		}
		return response
	}

	function getIdentityJWT() {
		return User.IdentityJWT
	}

	function getAccesJWT() {
		return User.AccessJWT
	}

	function getGroupFromAccessToken() {
		return User.accessToken.data.grupo
	}

	function setDebug(val: boolean) {
		debug.value = val
		debug.value && console.log('Debug activado')
	}

	function hasFuncion(funcion: keyof typeof Funciones): boolean {
		debug.value && console.log('on hasFuncion')
		return Funciones[funcion].roles.includes(User.activeRole)
	}

	//Roles de usuario
	const isSuperadministrador = computed<boolean>(() => Roles.ROL_SUPERADMINISTRADOR.type == User.activeRole.type)

	const isAdministrador = computed<boolean>(() => Roles.ROL_ADMINISTRADOR.type == User.activeRole.type)

	const isEditorDeActivos = computed<boolean>(() => Roles.ROL_EDITOR_DE_ACTIVOS.type == User.activeRole.type)

	const isAutoridad = computed<boolean>(() => Roles.ROL_AUTORIDAD.type == User.activeRole.type)

	const isCoordinador = computed<boolean>(() => Roles.ROL_COORDINADOR.type == User.activeRole.type)

	const isDictaminador = computed<boolean>(() => Roles.ROL_DICTAMINADOR.type == User.activeRole.type)

	const isMiembroOrganizacion = computed<boolean>(
		() => [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type, Roles.ROL_AUTORIDAD.type, Roles.ROL_COORDINADOR.type, Roles.ROL_DICTAMINADOR.type].indexOf(User.activeRole.type) >= 0
	)

	const isTitular = computed<boolean>(() => [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type].indexOf(User.activeRole.type) >= 0)

	const isTitularPersonaFisica = computed<boolean>(() => Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type == User.activeRole.type)

	const isTitularPersonaMoral = computed<boolean>(() => Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type == User.activeRole.type)

	const isAutorizador = computed<boolean>(() => Roles.ROL_SOLICITANTE_AUTORIZADOR.type == User.activeRole.type)

	const isEditor = computed<boolean>(() => Roles.ROL_SOLICITANTE_EDITOR.type == User.activeRole.type)

	const isVisor = computed<boolean>(() => Roles.ROL_SOLICITANTE_VISOR.type == User.activeRole.type)

	const isMiembroSolicitante = computed<boolean>(
		() =>
			[
				Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
				Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type,
				Roles.ROL_SOLICITANTE_AUTORIZADOR,
				Roles.ROL_SOLICITANTE_EDITOR,
				Roles.ROL_SOLICITANTE_VISOR
			].indexOf(User.activeRole.type) >= 0
	)

	const isAnonimo = computed(() => Roles.ROL_ANONIMO.type == User.activeRole.type)

	return {
		setDebug,
		User,
		RSALogin,
		webAuthnLogin,
		setCurrentRole,
		getRoleAccessToken,
		logOut,
		localLogOut,
		getAccessTokenLiveRemainSeconds,
		getIdentityTokenLiveRemainSeconds,
		getIdentityJWT,
		getAccesJWT,
		getGroupFromAccessToken,
		renewRoleAccessToken,
		hasFuncion,
		isSuperadministrador,
		isAdministrador,
		isEditorDeActivos,
		isAutoridad,
		isCoordinador,
		isDictaminador,
		isMiembroOrganizacion,
		isTitular,
		isTitularPersonaFisica,
		isTitularPersonaMoral,
		isAutorizador,
		isEditor,
		isVisor,
		isMiembroSolicitante,
		isAnonimo
	}
})
