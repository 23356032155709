//packages
import { ref } from 'vue'

//Project
import { useConfigStore } from 'src/store/config'
import { Usuario, OrganizacionUsuarioAPIRequest, OrganizacionUsuarioAPIResponse, Permiso } from 'cofepris-typesafe/Types/Organizacion'
import { useUserStore } from 'src/store/auth'
import { ErrorHandler } from 'cofepris-typesafe/Types/Errors'
import ERROR_API from 'src/errors/ERROR_API'
import { HomoclaveContextPermissionItem } from 'cofepris-typesafe/Types/Homoclave'

let debug = false
export const usuario = ref<Usuario | null>(null)
export const cargando = ref<boolean>(false)
export const lastAPIRequest = ref<OrganizacionUsuarioAPIRequest | null>(null)
export const lastAPIResponse = ref<OrganizacionUsuarioAPIResponse | null>(null)

export const setDebug = function (value: boolean) {
	if (debug != value) {
		console.log('on setDebug:', value)
		debug = value
	}
}

export const listarUsuarios = async function (lastEvaluatedKey?: Record<string, unknown> | undefined | null): Promise<OrganizacionUsuarioAPIResponse> {
	debug && console.log('on listarUsuarios')

	const request: OrganizacionUsuarioAPIRequest = {
		accion: 'LISTAR_USUARIOS',
		lastEvaluatedKey: lastEvaluatedKey || undefined
	}

	const response = await callAPI('ORGANIZATION', request, useUserStore().getAccesJWT())

	if (!response.data.lastEvaluatedKey) {
		lastEvaluatedKey = null
	}

	return response
}

export const callAPI = async function (
	service: 'ORGANIZATION' | 'USERS_BY_ORGANIZATIVE_UNIT' | 'GET_USERS_BY_UUIDS',
	request: OrganizacionUsuarioAPIRequest,
	jwt: string,
	forceResponseStatus?: OrganizacionUsuarioAPIResponse['status'] //Para testing de respuesta de API
): Promise<OrganizacionUsuarioAPIResponse> {
	debug && console.log('on callAPI', service, request)
	return new Promise(resolve => {
		cargando.value = true
		fetch(useConfigStore().getEnvironmentConfig().API_SERVICES[service], {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: jwt
			},
			body: JSON.stringify(request)
		})
			.then(async (response: Response) => {
				const json: OrganizacionUsuarioAPIResponse = (await response.json()) || {}
				lastAPIRequest.value = request
				lastAPIResponse.value = json
				switch (response.status) {
					case 200:
						resolve({
							status: forceResponseStatus || 'OK',
							message: json.message,
							data: json.data
						} as OrganizacionUsuarioAPIResponse)
						break
					case 400:
						resolve({
							status: 'ERROR',
							message: json.message,
							data: json.data
						} as OrganizacionUsuarioAPIResponse)
						break
					default:
						resolve({
							status: forceResponseStatus || 'NETWORK_ERROR',
							message: json.message || 'Error ' + response.status + ': Ocurrió un error de red en la solicitud',
							data: {}
						} as OrganizacionUsuarioAPIResponse)

						throw new ERROR_API('Error en solicitud a API', {
							timestamp: Date.now().toString(),
							environment: useConfigStore().getEnvironment(),
							url: useConfigStore().getEnvironmentConfig().API_SERVICES[service],
							method: 'POST',
							request,
							response: json,
							status: response.status.toString()
						})
				}
			})
			.catch(error => {
				debug && console.log('on catch', error)
				lastAPIRequest.value = request
				resolve({
					status: forceResponseStatus || 'NETWORK_ERROR',
					message: 'Ocurrió un error de red en la solicitud: (' + error.message + ') @ ' + useConfigStore().getEnvironmentConfig().API_SERVICES.ORGANIZATION,
					data: {}
				} as OrganizacionUsuarioAPIResponse)

				ErrorHandler(error)
			})
			.finally(() => {
				debug && console.log('on finally')
				cargando.value = false
			})
	})
}

export const getSubjectFieldFromCertificate = (subject: any, type: string, valueToFind: any): string => {
	let value: string = ''
	try {
		if (Array.isArray(subject?.attributes)) {
			value = subject.attributes.find((field: any) => field[type] === valueToFind).value
		}
	} catch (e) {
		return value
	}

	return value
}

export function isPermisoActivo(permiso: Permiso | HomoclaveContextPermissionItem): boolean {
	let response = false
	const now = Date.now()
	if (!permiso.revocado && permiso.fin > now) {
		response = true
	}
	return response
}

export function isPermisoVencido(permiso: Permiso | HomoclaveContextPermissionItem): boolean {
	let response = false
	const now = Date.now()
	if (permiso.fin > now) {
		response = true
	}
	return response
}

export function isPermisoRevocado(permiso: Permiso | HomoclaveContextPermissionItem): boolean {
	let response = false
	if (permiso.revocado) {
		response = true
	}
	return response
}

export const isResponseError = (response: OrganizacionUsuarioAPIResponse): boolean => response.status == 'ERROR'

export const isResponseNetworkError = (response: OrganizacionUsuarioAPIResponse): boolean => response.status == 'NETWORK_ERROR'

export const isResponseOk = (response: OrganizacionUsuarioAPIResponse): boolean => response.status == 'OK'
