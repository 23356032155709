/**
 * @name CTD.class
 * @author @tirsomartinezreyes
 * @version 2.0.1
 *
 * @description Clase principal del Modelo CTD para gestionar programáticamente.
 *
 * Responsabilidad:
 * - Implementar la interfaz ICTD
 * - Crear la raíz del modelo CTD
 * - Cargar el modelo CTD de Medicamentos
 * - Exportar el CTD como ICTD (JSON estático)
 * - Imprimir el árbol en consola (Emojis y vista de jerarquía tipo explorador de archivos)
 * - Proporcionar un mecanismo para normalizar nombres de nodos
 *
 * @changelog 2.0.1 - 30/sep/24 - @tirsomartinezreyes - Se corrige detección de archivos no válidos para archivos de 0 bytes
 * @changelog 0.0.10 - 12/sep/24 - Actualización del constructor para recibir un string con el JSON del CTD y hacerlo objeto
 * @changelog 0.0.9 - 09/sep/24 - Actualización de ICTDValidacionDossier con nombreEsperadoFolderRaiz
 * @changelog 0.0.8 - 03/sep/24 - Actualización de CTDNodo.class a 0.0.9
 * @changelog 0.0.7 Se agrega homoclave y modalidad a exportarArbolCTDConsola
 */

import {
	ICTD, // Interfaz del Modelo CTD
	ECTDTipoCTD, // Enumerador de los tipos de CTD
	CICTDVersion, // Constante de la versión del Modelo CTD
	ICTDRutaCTDValidacionDossier, // Interfaz de la ruta de validación de un dossier
	ICTDValidacionDossier, // Interfaz de la validación de un dossier
	ECTDTipoNodo
} from './ICTD'

import {
	CTDNodo, // Clase Nodo del Modelo CTD
	ICTDNodoParams, // Interfaz de los parámetros de creación de un Nodo
	ECTDNodoVistaArbolConsola
} from './CTDNodo.class' //0.0.9

import { IFileTypeFrontEnd } from 'cofepris-typesafe/Types/FilesType'

interface ICTDParams {
	versionICTD?: string
	versionCTD: string
	tipoCTD: ECTDTipoCTD
	raiz: CTDNodo | ICTDNodoParams
}

/**
 * @name CTD
 * @description Clase principal del Modelo CTD para gestionar programáticamente.
 */

export class CTD implements ICTD {
	private _versionICTD: string
	private _versionCTD: string
	private _tipoCTD: ECTDTipoCTD
	private _raiz: CTDNodo

	constructor(args: ICTDParams | ICTD | string) {
		if (typeof args === 'string') {
			args = JSON.parse(args)
		}
		const ctd = args as ICTD
		this._versionICTD = ctd?.versionICTD ?? CICTDVersion
		this._versionCTD = ctd?.versionCTD ?? ''
		this._tipoCTD = ctd?.tipoCTD
		this._raiz = ctd?.raiz instanceof CTDNodo ? ctd.raiz : new CTDNodo(ctd.raiz)
	}

	// Getters
	get versionICTD(): string {
		return this._versionICTD
	}

	get versionCTD(): string {
		return this._versionCTD
	}

	get tipoCTD(): ECTDTipoCTD {
		return this._tipoCTD
	}

	get raiz(): CTDNodo {
		return this._raiz
	}

	// Setters
	set versionICTD(version: string) {
		this._versionICTD = version
	}

	set versionCTD(version: string) {
		this._versionCTD = version
	}

	set tipoCTD(tipo: ECTDTipoCTD) {
		this._tipoCTD = tipo
	}

	set raiz(raiz: CTDNodo) {
		this._raiz = raiz
	}

	/**
	 * name validarDossire
	 * @description Valida la estructura del CTD contra una lista de archivos
	 */
	validarDossier(archivos: IFileTypeFrontEnd[]): ICTDValidacionDossier {
		const respuesta: ICTDValidacionDossier = {
			nombreCorrectoFolderRaiz: false,
			nombreFolderRaiz: archivos[0]?.webkitRelativePath!.split('/')[0],
			nombreEsperadoFolderRaiz: this.raiz.nombre,
			numeroCarpetas: 0,
			numeroArchivos: archivos.length,
			numeroArchivosValidos: 0,
			numeroArchivosErroneos: 0,
			numeroArchivosIgnorados: 0,
			rutasValidas: [],
			rutasErroneas: [],
			archivosIgnorados: [],
			rutasValidadas: []
		}
		try {
			//utilizar en lowercase siempre
			const archivosIgnorables = ['instrucciones.txt', '.ds_store', 'thumbs.db', 'desktop.ini', 'ehthumbs.db', 'ehthumbs_vista.db', 'ehthumbs_xp.db', 'thumbs.db', 'thumbs_vista.db', 'thumbs_xp.db']
			const carpetasEnDossier = foldersEnFileArray(archivos)
			respuesta.nombreCorrectoFolderRaiz = archivos[0]?.webkitRelativePath!.startsWith(this.raiz.nombre + '/')
			respuesta.numeroCarpetas = carpetasEnDossier.length

			if (!respuesta.nombreCorrectoFolderRaiz) {
				respuesta.rutasErroneas = carpetasEnDossier
				respuesta.numeroArchivosErroneos = archivos.length
			} else {
				this.raiz.obtenerNodosComoArray([ECTDTipoNodo.RAIZ, ECTDTipoNodo.FOLDER, ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE]).forEach(nodo => {
					const ruta = nodo.obtenerRutaAbsoluta()

					const rutaValidada: ICTDRutaCTDValidacionDossier = {
						ruta: ruta,
						id: nodo.id,
						nombre: nodo.nombre,
						nodo,
						archivosValidos: [],
						archivosErroneos: [],
						archivosIgnorados: []
					}

					if (nodo.tipo != ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE && carpetasEnDossier.includes(ruta)) {
						respuesta.rutasValidas.push(ruta)
						carpetasEnDossier.splice(carpetasEnDossier.indexOf(ruta), 1)
						archivos.forEach(archivo => {
							if (folderDeArchivo(archivo) === ruta) {
								if (archivo.size == 0) {
									rutaValidada.archivosErroneos.push(archivo)
									respuesta.numeroArchivosErroneos++
								} else if (archivosIgnorables.includes(archivo.name.toLowerCase())) {
									rutaValidada.archivosIgnorados.push(archivo)
									respuesta.archivosIgnorados.push(archivo.webkitRelativePath!)
									respuesta.numeroArchivosIgnorados++
								} else if (nodo.validarArchivoEnHijos(archivo)) {
									rutaValidada.archivosValidos.push(archivo)
									respuesta.numeroArchivosValidos++
								} else {
									rutaValidada.archivosErroneos.push(archivo)
									respuesta.numeroArchivosErroneos++
								}
							}
						})
					}

					if (nodo.tipo == ECTDTipoNodo.FOLDER_MULTIPLE_REPORTE) {
						const rutasReporte = carpetasEnDossier.filter(rutaDossier => rutaDossier.startsWith(ruta))
						if (rutasReporte.length > 0) {
							rutasReporte.forEach(rutaReporte => {
								respuesta.rutasValidas.push(rutaReporte)
								carpetasEnDossier.splice(carpetasEnDossier.indexOf(rutaReporte), 1)
								archivos.forEach(archivo => {
									if (folderDeArchivo(archivo) === rutaReporte) {
										if (archivosIgnorables.includes(archivo.name.toLocaleLowerCase())) {
											rutaValidada.archivosIgnorados.push(archivo)
											respuesta.archivosIgnorados.push(archivo.webkitRelativePath!)
											respuesta.numeroArchivosIgnorados++
										} else if (nodo.validarArchivo(archivo)) {
											rutaValidada.archivosValidos.push(archivo)
											respuesta.numeroArchivosValidos++
										} else {
											rutaValidada.archivosErroneos.push(archivo)
											respuesta.numeroArchivosErroneos++
										}
									}
								})
							})
						}
					}
					respuesta.rutasValidadas.push(rutaValidada)
				})
				respuesta.rutasErroneas = carpetasEnDossier
				//Carpetas restantes que no pertenecen a una ruta válida del CTD
				carpetasEnDossier.forEach(ruta => {
					archivos.forEach(archivo => {
						if (folderDeArchivo(archivo) === ruta) {
							if (archivosIgnorables.includes(archivo.name.toLowerCase())) {
								respuesta.numeroArchivosIgnorados++
								respuesta.archivosIgnorados.push(archivo.webkitRelativePath!)
							} else {
								respuesta.numeroArchivosErroneos++
							}
						}
					})
				})
			}
		} catch (error) {
			console.error('Error al validar dossier', error)
		}
		return respuesta
	}

	/**
	 * @name exportarComoICTD
	 * @description Exporta el árbol CTD en formato JSON.
	 */
	exportarComoICTD(reemplazarIdsPorInternos: boolean = false): ICTD {
		return {
			versionICTD: this._versionICTD,
			versionCTD: this._versionCTD,
			tipoCTD: this._tipoCTD,
			raiz: this._raiz.exportarComoICTDNodo(reemplazarIdsPorInternos)
		}
	}
	/**
	 * @name exportarArbolCTDConsola
	 * @description Exporta el árbol CTD en formato de líneas de texto para impresión en consola.
	 */
	exportarArbolCTDConsola(vista: ECTDNodoVistaArbolConsola, mostrarDescripcion: boolean = false, homoclave: string = '', modalidad: string = ''): string[] {
		const lineas: string[] = []
		lineas.push('======= CTD =======')
		if (homoclave) {
			lineas.push(`Homoclave: ${homoclave}`)
		}
		if (modalidad) {
			lineas.push(`Modalidad: ${modalidad}`)
		}
		lineas.push(`Tipo de visualización del árbol: ${vista}`)
		lineas.push(`Tipo de CTD: ${this._tipoCTD}`)
		lineas.push(`Versión de interface ICTD: ${this._versionICTD}`)
		lineas.push(`Versión de estructura del Dossier: ${this._versionCTD}\n`)
		lineas.push(...this.raiz.exportarArbolCTDConsola(vista, mostrarDescripcion))
		return lineas
	}

	/**
	 * @name imprimirArbolCTDConsola
	 * @description Imprime el árbol CTD en consola.
	 */
	imprimirArbolCTDConsola(vista: ECTDNodoVistaArbolConsola = ECTDNodoVistaArbolConsola.ID_NOMBRE, mostrarDescripcion: boolean = false): void {
		const lineas = this.exportarArbolCTDConsola(vista, mostrarDescripcion)
		console.log(lineas.join('\n'))
	}

	/**
	 * @name normalizarNombre
	 * @description Normaliza un nombre a minúsculas solo de la a a la z, numeros, permite guiones y reemplaza algunos caracteres comunes esperados por su valor normalizado
	 *
	 */
	public static normalizarNombre(prefijo: string, nombre: string): string {
		let response = ''

		const normaliza = (nombre: string): string => {
			const accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ'
			const accentsOut = 'AAAAAAaaaaaaOOOOOOooooooEEEEeeeeCcIIIIiiiiUUUUuuuuyNn'
			nombre = nombre
				.split('')
				.map(letter => {
					const accentIndex = accents.indexOf(letter)
					return accentIndex !== -1 ? accentsOut[accentIndex] : letter
				})
				.join('')

			return nombre
				.normalize('NFD')
				.toLowerCase()
				.replace(/ /g, '-')
				.replace(/%/g, '-por-ciento')
				.replace(/[^a-z0-9-]/g, '-')
		}

		if (prefijo) {
			response += normaliza(prefijo)
			response += '-'
		}

		if (nombre) {
			response += normaliza(nombre)
		}

		return response
	}
}

function foldersEnFileArray(files: IFileTypeFrontEnd[]): string[] {
	const folders: string[] = []
	for (const file of files) {
		const folder = file.webkitRelativePath!.split('/').slice(0, -1).join('/')
		if (!folders.includes(folder)) {
			folders.push(folder)
		}
	}
	return folders
}

function folderDeArchivo(file: IFileTypeFrontEnd): string {
	return file.webkitRelativePath!.split('/').slice(0, -1).join('/')
}
