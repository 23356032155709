/**
 * @name ModoOperacion
 * @description Módulo que contiene la definición de los modos de operación del sistema
 * @version 1.0.1
 * @author @tirsomartinezreyes
 *
 * @changelog 1.0.1 - 21/sep/24 - Se agrega el array de días no hábiles en IModoOperacion
 */
import { CatalogGenericData } from 'cofepris-typesafe/Types/Catalogs'

export enum EModoOperacion {
	INDEFINIDO = 'INDEFINIDO',
	ERROR = 'ERROR',
	NORMAL = 'NORMAL',
	AVISO = 'AVISO',
	MANTENIMIENTO = 'MANTENIMIENTO'
}

export interface IModoOperacion {
	modoOperacion: EModoOperacion
	mensaje?: string
	texto?: string
	diasNoHabiles?: string
}

export const catalogoModosOperacion: CatalogGenericData[] = [
	{
		key: 'NORMAL',
		value: {
			etiqueta: 'NORMAL: Operación normal del sistema',
			valor: 'NORMAL'
		} as any
	},
	{
		key: 'AVISO',
		value: {
			etiqueta: 'AVISO: El sistema muestra un aviso de manera permanente',
			valor: 'AVISO'
		} as any
	},
	{
		key: 'MANTENIMIENTO',
		value: {
			etiqueta: 'MANTENIMIENTO: El sistema muestra un aviso de manera permanente. Acceso por E.firma deshabilitado',
			valor: 'MANTENIMIENTO'
		} as any
	}
]

export const getCatalogoModosOperacionItem = (key: string): CatalogGenericData | undefined => {
	let response: CatalogGenericData | undefined = undefined
	catalogoModosOperacion.forEach((item: CatalogGenericData) => {
		if (item.key === key) {
			response = item
		}
	})
	return response
}
