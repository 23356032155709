export enum TIPO_NODO {
	DESCONOCIDO = 'DESCONOCIDO',
	SOLICITUD = 'SOLICITUD',
	GRUPO = 'GRUPO',
	MODALIDAD = 'MODALIDAD',
	COMENTARIO = 'COMENTARIO',
	HOMOCLAVE = 'HOMOCLAVE',
	ARCHIVO = 'ARCHIVO',
	PDF_SOLICITUD = 'PDF_SOLICITUD',
	TEMPLATE_HEADER = 'TEMPLATE_HEADER',
	TEMPLATE_FOOTER = 'TEMPLATE_FOOTER',
	TEMPLATE = 'TEMPLATE',
	ROOT = 'ROOT',
	PRODUCTOS = 'PRODUCTOS',
	AI_ENSAYO_CLINICO = 'AI_ENSAYO_CLINICO',
	AI_INSUMO_SALUD = 'AI_INSUMO_SALUD'
}

export default interface INodoRecurso {
	_id: string
	path: string
	parentName: string
	parentPath: string
	tipo: TIPO_NODO
	creation_date: number
	modification_date: number
	grupo: string
}

export interface IElementsOfPath {
	grupo?: string
	homoclave?: string
	modalidad?: string
	solicitud?: string
	comentario?: string
	producto?: string
	ensayoClinico?: string
}

export const makeResourcePath = (elementsOfPath: Partial<IElementsOfPath>) => {
	let path = ''
	if (elementsOfPath.grupo) {
		path += `/${TIPO_NODO.GRUPO}/${elementsOfPath.grupo}`
	}
	if (elementsOfPath.homoclave) {
		path += `/${TIPO_NODO.HOMOCLAVE}/${elementsOfPath.homoclave}`
	}
	if (elementsOfPath.modalidad) {
		path += `/${TIPO_NODO.MODALIDAD}/${elementsOfPath.modalidad}`
	}
	if (elementsOfPath.solicitud) {
		path += `/${TIPO_NODO.SOLICITUD}/${elementsOfPath.solicitud}`
	}
	if (elementsOfPath.comentario) {
		path += `/${TIPO_NODO.COMENTARIO}/${elementsOfPath.comentario}`
	}
	if (elementsOfPath.producto) {
		path += `/${TIPO_NODO.PRODUCTOS}/${elementsOfPath.producto}`
	}
	if (elementsOfPath.ensayoClinico) {
		path += `/${TIPO_NODO.AI_ENSAYO_CLINICO}/${elementsOfPath.ensayoClinico}`
	}
	return path
}
