/**
 * @name CTDDispositivosModulo2
 * @author @tirsomartinezreyes
 * @version 0.0.5
 *
 * @description Define la estructura del Módulo 2 (Contexto de la solicitud)
 * del CTD (Common Technical Document) para Registro de Dispositivos.
 * @changelog 0.0.5 - 29/oct/24 - @tirsomartinezreyes - Se cambian tipo de documentos en 2.3.1 y 2.3.5
 * @changelog 0.0.4 - cambios en 2.5.2.3, 2.5.3.3, 2.5.3.4 y 2.5.3.5
 * @changelog 0.0.3 - 28/agosto/24 - Se corrige typo en 2.3.4
 * @changelog 0.0.2 - Se corrige typo en 2.2, 2.5.2, 2.5.3 y cambio de nombre de carpeta or utilizr ':' en 2.5.3.3
 */

import {
	ECTDFormatoDocumento, //Enumeración que define los formatos de documentos válidos para los nodos de tipo Documento del CTD
	ECTDTipoNodo, //Enumeración del tipo de  cada nodo del CTD
	ICTDNodo //Definición de la estructura de un nodo del CTD
} from '../ICTD'

/**
 * @name CTDDispositivosModulo2
 * @description Define la estructura del Módulo 2 del CTD (Common Technical Document) para Registro de Dispositivos.
 */
export const CTDDispositivosModulo2: ICTDNodo = {
	tipo: ECTDTipoNodo.FOLDER,
	id: 'm2',
	nombre: 'm2',
	descripcion: 'Módulo 2: CONTEXTO DE LA SOLICITUD',
	hijos: [
		{
			id: '2.1',
			nombre: '21-descr-dispositivo',
			descripcion: 'Descripción del Dispositivo',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '2.1.1',
					descripcion: 'Información general',
					nombre: '211-info-general',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '2.2',
			nombre: '22-tecnovigilancia',
			descripcion: 'Tecnovigilancia',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '2.3',
			nombre: '23-otra-reg-admin',
			descripcion: 'Otra información del Contexto de la Solicitud',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '2.3.1',
					descripcion: 'Proyecto de registro',
					nombre: '231-proy-registro',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.WORD] }]
				},
				{
					id: '2.3.2',
					descripcion: 'Registro Sanitario vigente',
					nombre: '232-registro-vigente',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '2.3.3',
					descripcion: 'Constancia de prórroga de Registro Sanitario de dispositivos médicos',
					nombre: '233-cons-2a-prorr',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '2.3.4',
					descripcion: 'Constancias de modificaciones a las condiciones del Registro Sanitario de dispositivos médicos de tipo administrativo',
					nombre: '234-cons-modi-admn',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '2.3.5',
					descripcion: 'Carta de solicitud de modificación (Dice*/Debe Decir) y justificación del cambio *Dice: Conforme a las últimas condiciones vigente',
					nombre: '235-carta-modi',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.WORD] }]
				},
				{
					id: '2.3.6',
					descripcion: 'Contrato de cesión de Derechos',
					nombre: '236-cesion-derechos',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '2.4',
			nombre: '24-kit',
			descripcion: 'Dispositivos médicos que forman parte del Kit',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '2.4.1',
					descripcion: 'Registro(s) Sanitario(s) vigentes pertenecientes al Kit',
					nombre: '241-registros-kit',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				},
				{
					id: '2.4.2',
					descripcion: 'Carta(s) de autorización emitida(s) para uso de otro(s) Registro(s) Sanitario(s) vigente(s)',
					nombre: '242-cartas-auto-kit',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '2.5',
			nombre: '25-acuerdos-equiv',
			descripcion: 'Acuerdos de equivalencia',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					id: '2.5.1',
					nombre: '251-japon-mhlw',
					descripcion: 'Japón (MHLW)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [
						{
							id: '2.5.1.1',
							nombre: '2511-cert-organismo-reg',
							descripcion: 'Certificación emitida por el Organismo de Certificación Registrado',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.1.2',
							nombre: '2512-noti-exportacion',
							descripcion: 'Notificación de Exportación ',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.1.3',
							nombre: '2513-carta-aprob-mhlw',
							descripcion: 'Carta de Aprobación emitida por el MHLW',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						}
					]
				},
				{
					id: '2.5.2',
					nombre: '252-eua-fda',
					descripcion: 'Estados Unidos de América (FDA)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [
						{
							id: '2.5.2.1',
							nombre: '2521-monografia-fda',
							descripcion: 'Monografía FDA',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.2.2',
							nombre: '2522-cfg-fda',
							descripcion: 'Certificado a gobierno extranjero (Certificate to Foreign Government) emitido por FDA',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.2.3',
							nombre: '2523-erl-510k-pma-reg-list',
							descripcion: 'ER&L, 510(k), PMA o Registration and Listing',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						}
					]
				},
				{
					id: '2.5.3',
					nombre: '253-canada-hc',
					descripcion: 'Canadá (HC)',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [
						{
							id: '2.5.3.1',
							nombre: '2531-monografia-hc',
							descripcion: 'Monografía HC',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.3.2',
							nombre: '2532-lic-dispositivo',
							descripcion: 'Licencia de dispositivo médico',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.3.3',
							nombre: '2533-cancsaiso13485-03',
							descripcion: 'Cumplimiento del estándar CAN/CSAISO 13485:03 o certificado ISO 13485 con alcance a MDSAP',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.3.4',
							nombre: '2534-cumpl-iso17021',
							descripcion: 'Cumplimiento del estándar ISO 17021 para el tercer autorizado que emitió el certificado CAN/CSAISO13485:03 o certificado ISO 13485 con alcance a MDSAP',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						},
						{
							id: '2.5.3.5',
							nombre: '2535-tercer-autorizado',
							descripcion: 'Autorización vigente para el tercer autorizado que emitió el certificado CAN/CSAISO 13485:03 o certificado ISO 13485 con alcance a MDSAP',
							tipo: ECTDTipoNodo.FOLDER,
							hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
						}
					]
				},
				{
					id: '2.5.4',
					nombre: '254-otros-acuerdos',
					descripcion: 'Otros acuerdos',
					tipo: ECTDTipoNodo.FOLDER,
					hijos: [{ tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE, documentoFormato: [ECTDFormatoDocumento.PDF] }]
				}
			]
		},
		{
			id: '2.6',
			nombre: '26-convenio-maquila',
			descripcion: 'Convenio de maquila',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		},
		{
			id: '2.7',
			nombre: '27-info-adicional',
			descripcion: 'Información adicional',
			tipo: ECTDTipoNodo.FOLDER,
			hijos: [
				{
					tipo: ECTDTipoNodo.DOCUMENTO_MULTIPLE,
					documentoFormato: [ECTDFormatoDocumento.PDF]
				}
			]
		}
	]
}
