import { IGitSummary } from 'cofepris-typesafe/Modules/GitUtils'

export enum EGraphqlResponseStatus {
	SUCCESS = 'SUCCESS',
	INTERNAL_ERROR = 'INTERNAL_ERROR',
	NOT_EXECUTED = 'NOT_EXECUTED',
	JWT_ACCESO_NO_VALIDO = 'JWT_ACCESO_NO_VALIDO',
	FORBIDDEN = 'FORBIDDEN',
	NOT_FOUND = 'NOT_FOUND',
	NOT_UPDATED = 'NOT_UPDATED',
	UNAUTHORIZED = 'UNAUTHORIZED',
	BAD_REQUEST = 'BAD_REQUEST',
	NET_WORK_ERROR = 'NET_WORK_ERROR'
}
export type GraphqlResponseStatus = EGraphqlResponseStatus

export const getStatusMessage = (status?: GraphqlResponseStatus): IGraphqlResponseStatus => {
	switch (status) {
		case EGraphqlResponseStatus.SUCCESS:
			return { statusText: 'Operación Exitosa', statusCode: 200 }
		case EGraphqlResponseStatus.INTERNAL_ERROR:
			return { statusText: 'Internal Server Error', statusCode: 500, statusNormalizedName: 'ERROR INTERNO' }
		case EGraphqlResponseStatus.NOT_EXECUTED:
			return { statusText: 'No ejecutado', statusCode: 12017, statusNormalizedName: 'NO EJECUTADO' }
		case EGraphqlResponseStatus.JWT_ACCESO_NO_VALIDO:
			return { statusText: 'El token de acceso no es válido', statusCode: 409 }
		case EGraphqlResponseStatus.FORBIDDEN:
			return { statusText: 'Forbidden', statusCode: 403, statusNormalizedName: 'PROHIBIDO' }
		case EGraphqlResponseStatus.NOT_FOUND:
			return { statusText: 'Not Found', statusCode: 404, statusNormalizedName: 'NO ENCONTRADO' }
		case EGraphqlResponseStatus.NOT_UPDATED:
			return { statusText: 'Not Modified', statusCode: 304, statusNormalizedName: 'NO MODIFICADO' }
		case EGraphqlResponseStatus.BAD_REQUEST:
			return { statusText: 'ERR_BAD_REQUEST', statusCode: 400, statusNormalizedName: 'SOLICITUD INCORRECTA' }
		case EGraphqlResponseStatus.UNAUTHORIZED:
			return { statusText: 'Unauthorized', statusCode: 401, statusNormalizedName: 'NO AUTORIZADO' }
		case EGraphqlResponseStatus.NET_WORK_ERROR:
			return { statusText: 'Network Error', statusCode: 599, statusNormalizedName: 'ERROR DE RED' }
		default:
			return { statusText: 'Error desconocido', statusCode: 0, statusNormalizedName: 'ERROR DESCONOCIDO' }
	}
}

export interface IJWTRequest {
	jwt?: string | undefined
}

export interface IGraphqlResponseMetaData {
	status: GraphqlResponseStatus
	message?: string
}

export interface IGraphqlResponse {
	metaData: IGraphqlResponseMetaData
	versionCodeDetails?: IGitSummary
	//Added by extenders
}
export interface IGraphqlRequest extends IJWTRequest {
	//Added by extenders
	querySelection?: string
}
export type SortType = 'asc' | 'desc'
export interface IPaginatedRequest extends IGraphqlRequest {
	start?: number
	limit?: number
	sortField?: string[]
	sortDirection?: SortType[]
}

export interface IGraphqlResponsePaginated<T> extends IGraphqlResponse {
	data?: IGraphqlResponsePaginatedData<T>
}

export interface IGraphqlResponsePaginatedData<T> {
	items?: T[]
	total?: number
}
export interface IGraphqlResponseStatus {
	statusText: string
	statusNormalizedName?: string
	statusCode: number
}
