<script lang="ts" setup>
/**
 * @name WebAuthn
 * @description Componente que permite el inicio de sesión y rubricado de informaciñon con autenticador
 *
 * @autor @tirsomartinezreyes
 * @version 2.0.1
 * @changelog - 2.0.1 - 14/nov/24 - Ajuste a máquina de estdos para evitar doble evento de firma por integraci´pon de transición de RESET
 */

//packages
import { onMounted, computed } from 'vue'
import { useMachine } from '@xstate/vue'

//Team Packages

//project imports
import { objectValidate } from 'cofepris-typesafe/Modules/Utilities'
import { useUserStore } from 'src/store/auth'
import { useSessionStore } from 'src/store/session'
import RSASignature from 'src/components/RSASignature/RSASignature.vue'
import { RSASignContext } from 'src/components/RSASignature/fsm/types'
//Local Imports
import { webauthn } from './fsm/machine'
import keyImgUrl from './images/icons8-key-64.png'
import usbImgUrl from './images/usbKey.png'
import fidoImgUrl from './images/icons8-the-fido-alliance-an-open-industry-association-48.png'
import shieldIconSource from './images/shield.png'

const props = defineProps({
	debug: {
		type: Boolean,
		//required: true,
		default: false
	},
	mode: {
		type: String,
		//required: true,
		default: 'LOGIN'
	},
	credentialId: {
		type: String,
		required: false,
		default: ''
	},
	publicKey: {
		type: Object,
		required: false,
		default: null
	},
	challenge: {
		type: String,
		//required: true,
		default: Date.now().toString()
	},
	firmante: {
		type: String,
		required: false,
		default: ''
	},
	rol: {
		type: String,
		required: false,
		default: ''
	}
})

props.debug && console.log('props:', props)

const emit = defineEmits(['login', 'cancel', 'signature'])

const webAuthnMachine = useMachine(webauthn)

defineExpose({
	webAuthnMachine
})

onMounted(() => {
	props.debug && console.log('onMounted')
	webAuthnMachine.send('START', {
		debug: props.debug,
		mode: props.mode,
		challenge: props.challenge,
		firmante: props.firmante,
		rol: props.rol,
		credentialId: props.credentialId,
		publicKey: props.publicKey
	})
})

// Máquina de estados de Registro
webAuthnMachine.service.onTransition(state => {
	if (state.context.debug || props.debug) {
		let previousState = (state.history ? state.history.value : '') as string
		let currentState = (state.value ?? '') as string
		let id = state.machine ? state.machine.id : ''
		props.debug && console.log(`${id}: %c${previousState} %c-->(${state.event.type}) --> %c${currentState}`, 'color:red', 'color:blue', 'color:green')
	}

	if (state.changed) {
		if (state.value == 'login_success') {
			emit('login', state.context)
		}

		if (state.history?.value == 'waiting_assertion' && state.value == 'assertion_success') {
			emit('signature', state.context)
		}
	}
})

webAuthnMachine.service.onEvent(event => {
	if (webAuthnMachine.state.value.context.debug === false) return
	props.debug && console.log(`%c${event.type}`, 'color:cyan')

	props.debug && console.log(event)
})

const nextEvents = computed(() => webAuthnMachine.state.value.nextEvents)

const isContextValue = function (path: string, value: any): any {
	let tmp = objectValidate(webAuthnMachine.state.value.context, path, null)
	return tmp == value
}

const onRSAsignatureCancel = function () {
	props.debug && console.log('onRSAsignatureCancel')
	webAuthnMachine.send('CANCEL_REGISTRATION')
}

const onRSAsignature = function (rsaContext: RSASignContext) {
	props.debug && console.log('onRSAsignature', rsaContext)
	webAuthnMachine.send('REGISTER_CREDENTIAL', { registrationRSASignature: rsaContext.signatureB64 })
}

const redirectToLogin = function () {
	props.debug && console.log('redirectToLogin')
	emit('cancel')
	useUserStore().logOut()
	useSessionStore().machine.send('RESTART')
}
</script>

<template>
	<div class="wrapper bg-white">
		<!--INICIA DEBUG-->
		<details v-if="webAuthnMachine.state.value.context.debug" class="debug">
			<summary class="p5">
				Máquina: <span class="bold">{{ webAuthnMachine.service.id }}</span> | Modo: <span class="bold">{{ webAuthnMachine.state.value.context.mode }}</span> | Estado:
				<span class="bold">{{ webAuthnMachine.state.value.value }}</span>
			</summary>
			<div class="p5 m5 bss bw1 bclg">
				<button v-for="(event, index) in nextEvents" :key="index" class="btn" @click="webAuthnMachine.send(event)">
					{{ event }}
				</button>
			</div>
			<pre class="code"> Contexto: {{ JSON.stringify(webAuthnMachine.state.value.context, undefined, 2) }}</pre>
		</details>

		<!--INICIA COMPONENTE-->
		<div class="content mh100">
			<!--INICIA HEADER-->
			<div class="row bg-light-gray black p5">
				<div class="col-md-2 text-left bold ma">
					<img :src="keyImgUrl" alt="USB key" height="20" />
				</div>
				<div class="col-md-8 text-center bold ma">
					<span v-if="isContextValue('mode', 'LOGIN')">Inicio de sesión con llave de seguridad</span>
					<span v-if="isContextValue('mode', 'SIGN')">Rúbrica de información con llave de seguridad</span>
					<span v-if="isContextValue('mode', 'REGISTER')">Registro de llave de seguridad</span>
				</div>
				<div class="col-md-2 text-right bold ma">
					<img :src="fidoImgUrl" alt="logo Fido2" height="25" />
				</div>
			</div>

			<div v-if="isContextValue('mode', 'SIGN')" class="row bss bw1 bclg p5 dark-gray">
				<div class="col-md-6 fs-s bold wb-ba ma text-center">
					{{ webAuthnMachine.state.value.context.firmante }}
				</div>
				<div class="col-md-6 fs-s bold wb-ba ma text-center">
					{{ webAuthnMachine.state.value.context.rol }}
				</div>
			</div>

			<va-card>
				<!--WEBAUTH_NOT_SUPPORTED-->
				<va-card-content v-if="webAuthnMachine.state.value.value == 'webauthn_not_supported'" class="p0">
					<div class="alert alert-warning">Su navegador no soporta el uso de llaves de seguridad</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'error'" class="p0">
					<div class="alert alert-warning">
						Error en la configuración<br /><br />
						Debe seleccionar un modo y challenge correcto
					</div>
				</va-card-content>

				<!--LOGIN_STANDBY-->
				<div v-if="webAuthnMachine.state.value.value == 'login_standby'" class="row pt20 pb20">
					<div class="col-md-3 text-center">
						<img :src="usbImgUrl" alt="usb key image" width="50" />
					</div>
					<div class="col-md-6 text-center bold ma">
						<button class="btn btn-primary btn-sm bold active" @click="webAuthnMachine.send('REQUEST_LOGIN')">Acceso con llave de seguridad</button>
					</div>
					<div class="col-md-3 text-center"></div>
				</div>

				<!-- REGISTER_STANDBY-->
				<div v-if="webAuthnMachine.state.value.value == 'register_standby'" class="row pt20 pb20">
					<div class="col-md-2 text-center">
						<img :src="usbImgUrl" alt="usb key image" width="50" />
					</div>
					<div class="col-md-8 text-center ma">
						<div class="text-justify">Es necesario registrar una llave de seguridad para utilizar el sistema. Esto te facilitará las tareas de inicio de sesión y rúbrica de información.</div>
						<br />
						<button class="btn btn-primary btn-sm bold" @click="webAuthnMachine.send('REQUEST_KEY')">Registrar llave de seguridad</button>
						<button class="btn btn-danger btn-sm bold ml20" @click="redirectToLogin">Cerrar sesión</button>
					</div>
					<div class="col-md-2 text-center"></div>
				</div>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'preview_assertion'">
					<div class="row">
						<div class="col-md-1 text-center ma">
							<img :src="usbImgUrl" alt="usb key image" width="50" />
						</div>

						<div class="col-md-10 text-center bold">
							<div class="bss bw1 bclg p20 dark-gray mt5 ml10 wb-ba">
								<div class="text-center ff-mono mb10 bold">CADENA ORIGINAL</div>
								<hr class="m0 pb10" />
								<va-scroll-container style="min-height: 100px; max-height: 200px" color="primary" vertical size="medium">
									<div class="ff-mono fs-s text-justify wb-ba">{{ webAuthnMachine.state.value.context.challenge }}</div>
								</va-scroll-container>
								<hr class="m10" />
								<img :src="shieldIconSource" width="15" class="vcenter mr5" alt="Código de integridad SHA-256" />
								<span class="fs-s bold tt-uppercase">SHA-256: </span>
								<div class="ff-mono wb-ba mt5 uppercase text-center">{{ webAuthnMachine.state.value.context.challengeSHA256 }}</div>
							</div>

							<div class="row bold mt20">
								<div class="col-md-12 text-center">
									<button cyid="BtnFirmarLlaveSeguridad" class="btn btn-primary active btn-sm" @click="webAuthnMachine.send('REQUEST_ASSERTION')">Firmar con llave de seguridad</button>
								</div>
							</div>
						</div>
						<div class="col-md-1 text-center ma"></div>
					</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'waiting_key'">
					<div class="row">
						<div class="col-md-1 text-center">
							<img :src="usbImgUrl" alt="usb key image" width="50" />
						</div>
						<div class="col-md-10 text-center bold">
							Conecte la llave de seguridad y siga las instrucciones <br /><br />
							<div class="row">
								<div class="col-md-6 text-right">
									<button v-if="webAuthnMachine.state.value.context.mode == 'REGISTER'" class="btn btn-primary btn-sm" @click="webAuthnMachine.send('REQUEST_CREDENTIAL')">Continuar</button>
									<button v-if="webAuthnMachine.state.value.context.mode != 'REGISTER'" class="btn btn-primary btn-sm" @click="webAuthnMachine.send('REQUEST_ASSERTION')">Continuar</button>
								</div>
								<div class="col-md-6 text-left">
									<button class="btn btn-danger btn-sm" @click="webAuthnMachine.send('CANCEL_WAITING_KEY')">Cancelar</button>
								</div>
							</div>
						</div>
						<div class="col-md-1 text-center"></div>
					</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'waiting_credential' || webAuthnMachine.state.value.value == 'waiting_assertion'">
					<div class="w100p text-center mb25">Esperando llave de seguridad</div>
					<va-inner-loading loading class="text-center"> </va-inner-loading>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'credential_error'" class="p0">
					<div class="alert alert-warning">
						Ocurrió un error al obtener la llave de seguridad<br /><br />
						<button class="btn btn-danger" @click="webAuthnMachine.send('RETRY_CREDENTIAL')">Reintentar</button>
					</div>
				</va-card-content>

				<div v-if="webAuthnMachine.state.value.value == 'assertion_error'" class="p0">
					<div class="alert alert-warning">
						{{ webAuthnMachine.state.value.context.assertionErrorMessage || 'Ocurrió un error al obtener la llave' }} <br /><br />
						<div class="row">
							<div class="col-md-3"></div>
							<div class="col-md-3">
								<button class="btn btn-primary btn-sm bold" @click="webAuthnMachine.send('RETRY_ASSERTION')">Reintentar</button>
							</div>
							<div class="col-md-3"><button class="btn btn-danger btn-sm bold" @click="webAuthnMachine.send('CANCEL_ASSERTION')">Cancelar</button></div>
							<div class="col-md-3"></div>
						</div>
					</div>
				</div>

				<div v-if="webAuthnMachine.state.value.value == 'assertion_success'" class="p0">
					<div class="row alert alert-success">
						<div class="col-md-2 text-center">
							<img :src="usbImgUrl" alt="usb key" width="50" />
						</div>
						<div class="col-md-8 text-center ma">
							<div class="bold">Firmado Exitoso</div>
							<div class="bold fs-xs wb-ba mt20">{{ webAuthnMachine.state.value.context.signatureB64 }}</div>
							<slot name="assertion_success"> </slot>
						</div>
						<div class="col-md-2 text-center"></div>
					</div>
				</div>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'getting_register_message'">
					<div class="w100p text-center mb25">Esperando solicitud de registro</div>
					<va-inner-loading loading class="text-center"> </va-inner-loading>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'getting_register_message_ok'">
					<div class="row">
						<div class="col-md-12 text-center">
							<div class="text-justify mb20 alert alert-info bold">Por favor acepte y firme la solicitud de uso de la llave de seguridad.</div>
							<RSASignature
								:debug="false"
								mode="SIGN"
								:message="webAuthnMachine.state.value.context.registrationRSAMessage as string"
								:certificado-b64="useUserStore().User.certificadoB64"
								@cancel="onRSAsignatureCancel"
								@validSignature="onRSAsignature"
							/>
						</div>
					</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'getting_register_message_error'">
					<div class="row">
						<div class="col-md-12 text-center alert alert-warning">
							{{ webAuthnMachine.state.value.context.registrationRSAErrorMessage }}
							<br /><br />
							<button class="btn btn-primary active btn-sm mt25" @click="webAuthnMachine.send('RETRY_GET_MESSAGE')">Reintentar</button>
							<button class="btn btn-danger btn-sm bold mt25 ml20" @click="webAuthnMachine.send('CANCEL_REGISTRATION')">Cancelar</button>
						</div>
					</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'registering_credential'">
					<div class="w100p text-center mb25">Registrando llave de seguridad...</div>
					<va-inner-loading loading class="text-center" />
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'register_error'" class="p0">
					<div class="alert alert-warning">
						{{ webAuthnMachine.state.value.context.activationErrorMessage }}<br /><br />
						<button class="btn btn-danger" @click="webAuthnMachine.send('REGISTER_RETRY')">Reintentar</button>
					</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'register_success'">
					<div class="row mt25">
						<div class="col-md-2 text-center">
							<img :src="usbImgUrl" alt="usb key image" width="50" />
						</div>
						<div class="col-md-10 text-cente">
							<div class="bold mb25 text-justify">El registro de su llave de seguridad ha concluido con éxito</div>
							<div class="text-justify mb10">A partir de este momento puede iniciar sesión en el sistema sin necesidad de contraseñas o firma electrónica.</div>
						</div>
					</div>
					<div class="row mt20">
						<div class="col-md-12">
							<button class="btn btn-success" type="button" @click="redirectToLogin">Ir al Inicio de sesión</button>
						</div>
					</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'login'">
					<div class="w100p text-center mb25 bold">Iniciando sesión...</div>
					<va-inner-loading loading class="text-center" />
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'login_error'" class="p0">
					<div class="alert alert-warning">
						{{ webAuthnMachine.state.value.context.loginErrorMessage || 'Ocurrió un error al iniciar sesión' }}<br /><br />
						<button class="btn btn-primary btn-sm bold" @click="webAuthnMachine.send('LOGIN_RETRY')">Reintentar</button>
						<button class="btn btn-danger btn-sm bold ml20" @click="webAuthnMachine.send('CANCEL_LOGIN')">Cancelar</button>
					</div>
				</va-card-content>

				<va-card-content v-if="webAuthnMachine.state.value.value == 'login_success'" class="">
					<div class="row alert alert-success">
						<div class="col-md-2 text-center">
							<img :src="usbImgUrl" alt="usb key image" width="50" />
						</div>
						<div class="col-md-8 text-center ma">
							<div class="bold">Operación Exitosa</div>
						</div>
						<div class="col-md-2 text-center"></div>
					</div>
				</va-card-content>
			</va-card>
		</div>
	</div>
	<!--TERMINA MODO REGISTRO-->
</template>
<style scoped>
.debug {
	border: 1px dotted red;
	border-radius: 4px;
}
</style>
