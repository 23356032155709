/**
 * @package ModificacionesMedicamentosBBV
 * @author @Jorel254
 * @version 0.0.2
 *
 * @description Registro de modificaciones a medicamentos Biológicos, Biotecnológicos y Vacunas
 */

import { EModificacionesMedicamentoSeccionesOficio, EModificacionesMedicamentoEtiquetas, EModificacionesMedicamentoTipo, IModificacionMedicamento } from './IModificacionesMedicamentos'
import { EMedicamentoTipo } from '../InsumoSalud'
export const CModificacionesMedicamentosBBV: IModificacionMedicamento[] = [
	{
		numeral: '8.1.1',
		nombre: 'Cambio o actualización de la Unidad de Farmacovigilancia',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio o actualización de la Unidad de Farmacovigilancia',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.UNIDAD_FARMACOVIGILANCIA],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.VACUNA, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		numeral: '8.1.3',
		nombre:
			'Cambio o actualización de la razón social y/o domicilio del fabricante del fármaco, del fabricante que realiza los subprocesos al fármaco (micronización, mezcla, diluciones, entre otros), fabricante del diluyente, fabricante del medicamento y acondicionador. ',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante del fármaco (nacional o extranjero).',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion:
					'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante que realiza los subprocesos al fármaco (micronización, mezcla, diluciones, entre otros) (nacional o extranjero).',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante del medicamento (nacional o extranjero).',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_MEDICAMENTO, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del fabricante del diluyente (nacional o extranjero).',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FABRICANTE_DILUYENTE, EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio o actualización de la razón social y/o actualización del domicilio del acondicionador (nacional o extranjero).',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL
				],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.VACUNA, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		numeral: '8.1.5',
		nombre: 'Cambio, adición o eliminación del material de envase secundario',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio, adición o eliminación del material de envase secundario',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.PRESENTACION],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.VACUNA, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		numeral: '8.1.8',
		nombre: 'Cambio, adición o eliminación del acondicionador secundario y/o almacén y/o distribuidor del medicamento',
		incisos: [
			{
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio, adición o eliminación del acondicionador secundario y/o almacén y/o distribuidor de medicamentos que no requieran red o cadena de frío',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.DISTRIBUIDOR,
					EModificacionesMedicamentoSeccionesOficio.ALMACEN
				],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.VACUNA, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.1',
		tituloPadre: 'Modificaciones al fármaco/biofármaco',
		numeral: '8.3.1.1',
		nombre: 'Fabricación',
		incisos: [
			{
				referenciaWHO: 'WHO-B-1',
				titulo: 'Cambio en las instalaciones de fabricación del fármaco/biofármaco',
				letra: EModificacionesMedicamentoEtiquetas.B,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				descripcion: 'Conversión de un sitio de fabricación del fármaco/biofármaco, de un solo producto a múltiples productos',
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-1',
				titulo: 'Cambio en las instalaciones de fabricación del fármaco/biofármaco',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de un sitio de fabricación o fabricante de un producto intermedio del fármaco/biofármaco o a granel',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL, EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: true,
					notas: ['Medicamentos biotecnológicos']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-2',
				titulo: 'Cambios en los bancos celulares',
				letra: EModificacionesMedicamentoEtiquetas.B,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				descripcion: 'Generación de un nuevo BCM',
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-2',
				titulo: 'Cambios en los bancos celulares',
				letra: EModificacionesMedicamentoEtiquetas.C,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				descripcion: 'Generación de un nuevo Banco Celular de Trabajo (BCT)',
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-3',
				titulo: 'Cambio en el sitio de fabricación del banco celular',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				descripcion: '',
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-4',
				titulo: 'Cambio en el sitio de análisis/almacenamiento del banco celular',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				descripcion: '',
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-5',
				titulo: 'Cambio en el protocolo de calificación del banco celular',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-5',
				titulo: 'Cambio en el protocolo de calificación del banco celular',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-6',
				titulo: 'Cambio al proceso de fermentación o cultivo celular, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: `Un cambio con potencial moderado de impacto en la calidad del fármaco/biofármaco o producto terminado (p.e., extensión de la edad celular in vitro más allá de los parámetros validados)`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-6',
				titulo: 'Cambio al proceso de fermentación o cultivo celular, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: `Un cambio no crítico con potencial mínimo de impacto en la calidad del fármaco/biofármaco o producto terminado, tales como: 
								- un cambio en los procedimientos de recolección y/o agrupación que no afecte el método de fabricación, recobro, condiciones de almacenamiento intermedio, sensibilidad de detección de agentes adventicios o escala de producción; 
								- duplicación de un tren de fermentación; o 
								- adición de biorreactores similares/comparables.
                                `,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-7',
				titulo: 'Cambio en el proceso de purificación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: `Cambio con potencial moderado de impacto en la calidad del fármaco/biofármaco o producto terminado (p.e., un cambio en el método químico de separación, tal como CLAR de intercambio iónico a CLAR de fase inversa)`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-7',
				titulo: 'Cambio en el proceso de purificación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: `Un cambio no crítico con potencial mínimo de impacto en la calidad del fármaco/biofármaco o el producto final (p.e., adición de una etapa en la línea de filtración equivalente a la etapa de filtración aprobada)`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-9',
				titulo: 'Introducción de etapas de reprocesamiento',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-10',
				titulo: 'Adición de un nuevo paso de espera o cambio en los parámetros de una etapa de espera aprobada',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-11',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: `Introducción de equipo nuevo con diferentes principios de operación y diferentes materiales en contacto con el producto`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-11',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: `Introducción de equipo nuevo con los mismos principios de operación, pero diferente material de contacto`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-11',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: `Introducción de equipo nuevo con diferentes principios de operación, pero el mismo material de contacto`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-11',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: `Reemplazo del equipo en contacto con el producto por un equipo equivalente`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-11',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: `Cambio de equipo en contacto con el producto de uso exclusivo a uno de uso compartido`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-11',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: `Reubicación del equipo principal a otra habitación dentro de la misma instalación/suite/local`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-13',
				titulo: 'Cambio en el fabricante de materias primas de origen biológico (p.e., suero fetal bovino, insulina, tripsina)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: ``,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-14',
				titulo: 'Cambio en la fuente de materias primas de origen biológico (p.e., tripsina bovina a tripsina porcina)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: ``,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-15',
				titulo: 'Cambio en las pruebas en proceso y/o criterios de aceptación aplicados durante la fabricación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: `Reducción de los límites en proceso aprobados`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-15',
				titulo: 'Cambio en las pruebas en proceso y/o criterios de aceptación aplicados durante la fabricación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: `Adición de nuevas pruebas y límites en proceso`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-15',
				titulo: 'Cambio en las pruebas en proceso y/o criterios de aceptación aplicados durante la fabricación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: `Eliminación de las pruebas en proceso no significativas`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-15',
				titulo: 'Cambio en las pruebas en proceso y/o criterios de aceptación aplicados durante la fabricación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: `Ampliación de los límites de las pruebas en proceso aprobados`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-15',
				titulo: 'Cambio en las pruebas en proceso y/o criterios de aceptación aplicados durante la fabricación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: `Eliminación de prueba(s) en proceso que pueden tener un efecto significativo en la calidad del fármaco/biofármaco.`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-15',
				titulo: 'Cambio en las pruebas en proceso y/o criterios de aceptación aplicados durante la fabricación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: `Adición o sustitución de una prueba en proceso como resultado de un problema de seguridad o calidad`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-16',
				titulo: 'Cambio del sitio donde se efectúan las pruebas de los controles en proceso',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: ``,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-17',
				titulo: 'Cambio en el espacio de diseño aprobado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: `Reducción del espacio de diseño aprobado (cualquier cambio que reduzca o limite el rango de parámetros utilizados para definir el espacio de diseño)`,
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.1',
		tituloPadre: 'Modificaciones al fármaco/biofármaco',
		numeral: '8.3.1.2',
		nombre: 'Control del fármaco/biofármaco',
		incisos: [
			{
				referenciaWHO: 'WHO-B-18',
				titulo: 'Cambios que afectan las pruebas de control de calidad (liberación y estabilidad) del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion:
					'Transferencia de las actividades de prueba de control de calidad para un ensayo no farmacopeico a una nueva compañía no aprobada en el Registro Sanitario, o a un sitio diferente dentro de la misma compañía.',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-18',
				titulo: 'Cambios que afectan las pruebas de control de calidad (liberación y estabilidad) del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Transferencia de las actividades de pruebas de control de calidad para un ensayo farmacopeico a una nueva compañía no aprobada en el Registro Sanitario actual.',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-19',
				titulo: 'Cambio en la monografía (esto es, las especificaciones) empleadas para liberar el fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio de una monografía farmacopeica a una monografía interna.',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-19',
				titulo: 'Cambio en la monografía (esto es, las especificaciones) empleadas para liberar el fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Cambio de monografía interna a una monografía farmacopeica o de monografía farmacopeica a otra monografía farmacopeica',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-20',
				titulo: 'Cambio en las especificaciones del fármaco/biofármaco con el propósito de cumplir con la actualización de la monografía farmacopeica',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-21',
				titulo: 'Cambios en la estrategia de control del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de un nuevo atributo crítico de calidad en la estrategia de control',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-21',
				titulo: 'Cambios en la estrategia de control del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de un atributo critico de calidad de la estrategia de control',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-22',
				titulo: 'Cambio en la especificación/método analítico empleado para liberar el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-22',
				titulo: 'Cambio en la especificación/método analítico empleado para liberar el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-22',
				titulo: 'Cambio en la especificación/método analítico empleado para liberar el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-22',
				titulo: 'Cambio en la especificación/método analítico empleado para liberar el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios a un método analítico aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-22',
				titulo: 'Cambio en la especificación/método analítico empleado para liberar el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio de un método analítico interno a un método analítico farmacopeico/compendial reconocido',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-22',
				titulo: 'Cambio en la especificación/método analítico empleado para liberar el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Ampliación de un criterio de aceptación aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-22',
				titulo: 'Cambio en la especificación/método analítico empleado para liberar el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.G,
				descripcion: 'Reducción de un criterio de aceptación aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.1',
		tituloPadre: 'Modificaciones al fármaco/biofármaco',
		numeral: '8.3.1.3',
		nombre: 'Estándares o Materiales de Referencia',
		incisos: [
			{
				referenciaWHO: 'WHO-B-23',
				titulo: 'Sustitución de un estándar de referencia primario',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-24',
				titulo: 'Cambio del estándar de referencia de farmacopeico o internacional a un estándar de referencia interno (no relacionado con el estándar internacional)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-25',
				titulo: 'Cambio de un estándar de referencia interno (no relacionado con el estándar de referencia internacional) a un estándar de referencia farmacopeico o internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-26',
				titulo:
					'Calificación de un nuevo lote de estándar de referencia contra el estándar de referencia aprobado (incluyendo la calificación de un nuevo lote de estándar de referencia secundario contra el estándar primario aprobado)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-27',
				titulo: 'Cambio del protocolo de calificación del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-28',
				titulo: 'Ampliación de la vida útil del estándar de referencia o período de reanálisis',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.1',
		tituloPadre: 'Modificaciones al fármaco/biofármaco',
		numeral: '8.3.1.4',
		nombre: 'Sistema contenedor-cierre',
		incisos: [
			{
				referenciaWHO: 'WHO-B-29',
				titulo: 'Cambio del envase primario en el sistema contenedor-cierre para el almacenamiento y transporte del fármaco/biofármaco',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-30',
				titulo: 'Cambio del fabricante del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Sustitución o adición de un fabricante',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-30',
				titulo: 'Cambio del fabricante del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Eliminación de un fabricante',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-31',
				titulo: 'Cambio en las especificaciones/métodos analíticos del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-31',
				titulo: 'Cambio en las especificaciones/métodos analíticos del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-31',
				titulo: 'Cambio en las especificaciones/métodos analíticos del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-31',
				titulo: 'Cambio en las especificaciones/métodos analíticos del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios menores a un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-31',
				titulo: 'Cambio en las especificaciones/métodos analíticos del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Ampliación de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-31',
				titulo: 'Cambio en las especificaciones/métodos analíticos del envase primario en el sistema contenedor-cierre del fármaco/biofármaco, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.1',
		tituloPadre: 'Modificaciones al fármaco/biofármaco',
		numeral: '8.3.1.5',
		nombre: 'Estabilidad',
		incisos: [
			{
				referenciaWHO: 'WHO-B-33',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion:
					'Cambio significativo del protocolo de estabilidad posterior a la aprobación o al compromiso de estabilidad, tal como la eliminación de una prueba, la sustitución de un método analítico, cambio en la temperatura de almacenamiento',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-33',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de la(s) prueba(s) en el protocolo de estabilidad posterior a la aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-33',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de un tiempo de análisis del protocolo de estabilidad post-aprobación dentro de la vida útil aprobada',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-34',
				titulo: 'Cambio en las condiciones de almacenamiento para el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Adición o cambio de las condiciones de almacenamiento para el fármaco/biofármaco (p.e., ampliación o reducción de la condición de temperatura)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-34',
				titulo: 'Cambio en las condiciones de almacenamiento para el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una leyenda de advertencia',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-34',
				titulo: 'Cambio en las condiciones de almacenamiento para el fármaco/biofármaco, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de una leyenda de advertencia',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.2',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.3.2.2',
		nombre: 'Fabricación',
		incisos: [
			{
				referenciaWHO: 'WHO-B-37',
				titulo: 'Cambio en el espacio de diseño aprobado, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Reducción de un espacio de diseño aprobado (cualquier cambio que reduzca o limite el rango de parámetros usados para definir el espacio de diseño)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-38',
				titulo: 'Cambios en las instalaciones del sitio de fabricación/fabricante del producto terminado, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Conversión de una instalación de fabricación de productos farmacéuticos de un solo producto a una instalación de múltiples productos',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-38',
				titulo: 'Cambios en las instalaciones del sitio de fabricación/fabricante del producto terminado, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Eliminación de una instalación de fabricación o sitio de acondicionamiento de producto terminado',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_MEDICAMENTO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL
				],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-39',
				titulo: 'Cambios en el proceso de fabricación de producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Adición de un tamaño de lote limitado por la escala aprobada o disminución del tamaño de lote',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-39',
				titulo: 'Cambios en el proceso de fabricación de producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio de un equipo en contacto con el producto, de dedicado a compartido (p.e., tanque de formulación, carcasa del filtro, línea y cabezal de llenado, liofilizador)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-40',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Reducción de los límites en proceso aprobados',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-40',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de nuevas pruebas en proceso y límites',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-40',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de una prueba en proceso no significativa',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-40',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Ampliación de límites aprobados de las pruebas en proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-40',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Eliminación de una prueba en el proceso que tenga un efecto significativo en la calidad general de producto terminado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-40',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Adición o cambio de una prueba en el proceso como resultado de un problema de seguridad o de calidad',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-41',
				titulo: 'Cambio en el sitio donde se llevan a cabo las pruebas de control en proceso',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-42',
				titulo: 'Cambios en la especificación/método analítico utilizado para liberar excipientes, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-42',
				titulo: 'Cambios en la especificación/método analítico utilizado para liberar excipientes, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-42',
				titulo: 'Cambios en la especificación/método analítico utilizado para liberar excipientes, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-42',
				titulo: 'Cambios en la especificación/método analítico utilizado para liberar excipientes, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios menores en un método analítico aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-42',
				titulo: 'Cambios en la especificación/método analítico utilizado para liberar excipientes, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio de un método analítico interno por un método analítico farmacopeico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-42',
				titulo: 'Cambios en la especificación/método analítico utilizado para liberar excipientes, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Ampliación de un criterio de aceptación aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-42',
				titulo: 'Cambios en la especificación/método analítico utilizado para liberar excipientes, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.G,
				descripcion: 'Reducción de un criterio de aceptación aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-43',
				titulo: 'Cambios en el estándar/monografía (esto es, especificaciones) requeridos para el excipiente',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-50',
				titulo: 'Cambio en el sitio donde se efectúan las pruebas de control de calidad de los excipientes',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.2',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.3.2.3',
		nombre: 'Control de producto terminado',
		incisos: [
			{
				referenciaWHO: 'WHO-B-51',
				titulo: 'Cambios que afectan las pruebas de control de calidad de producto terminado (liberación y estabilidad), que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion:
					'Transferencia de las actividades de pruebas de control de calidad para un ensayo no farmacopeico (interno) a una nueva empresa no aprobada en la autorización actual o a un sitio diferente dentro de la misma empresa',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-51',
				titulo: 'Cambios que afectan las pruebas de control de calidad de producto terminado (liberación y estabilidad), que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Transferencia de las actividades de pruebas de control de calidad para un ensayo farmacopeico a una nueva empresa no aprobada en la autorización actual',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-52',
				titulo: 'Cambio en la monografía (esto es, especificaciones) declaradas para el producto terminado, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Un cambio de una monografía farmacopeica a una monografía interna',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-52',
				titulo: 'Cambio en la monografía (esto es, especificaciones) declaradas para el producto terminado, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Un cambio de una monografía interna a una monografía farmacopeica o de una monografía farmacopeica a una monografía de otra farmacopea',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-53',
				titulo: 'Cambio en las especificaciones para el producto terminado para cumplir con la actualización de la monografía farmacopeica',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: 'Eliminación de una instalación de fabricación o sitio de acondicionamiento de producto terminado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-54',
				titulo: 'Cambios en la estrategia de control para el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de un nuevo atributo crítico de calidad en la estrategia de control',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-54',
				titulo: 'Cambios en la estrategia de control para el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de un atributo crítico de calidad de la estrategia de control',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-55',
				titulo: 'Cambio en las especificaciones/métodos analíticos empleados para liberar el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba analítica y/o criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-55',
				titulo: 'Cambio en las especificaciones/métodos analíticos empleados para liberar el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-55',
				titulo: 'Cambio en las especificaciones/métodos analíticos empleados para liberar el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-55',
				titulo: 'Cambio en las especificaciones/métodos analíticos empleados para liberar el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios a un método de análisis aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-55',
				titulo: 'Cambio en las especificaciones/métodos analíticos empleados para liberar el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio de un método de análisis interno a un método de análisis farmacopeico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-55',
				titulo: 'Cambio en las especificaciones/métodos analíticos empleados para liberar el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Ampliación de un criterio de aceptación aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-55',
				titulo: 'Cambio en las especificaciones/métodos analíticos empleados para liberar el producto terminado, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.G,
				descripcion: 'Reducción de un criterio de aceptación aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.2',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.3.2.4',
		nombre: 'Estándares de Referencia',
		incisos: [
			{
				referenciaWHO: 'WHO-B-56',
				titulo: 'Sustitución de un estándar de referencia primario',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-57',
				titulo: 'Cambio en el estándar de referencia farmacopeico o internacional a uno interno (sin ninguna relación con el estándar internacional)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-58',
				titulo: 'Cambio en el estándar de referencia interno (sin ninguna relación con el estándar internacional) a un estándar farmacopeico o internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-59',
				titulo:
					'Calificación de un nuevo lote del estándar de referencia contra el estándar de referencia aprobado (incluyendo la calificación de un lote nuevo del estándar secundario de referencia contra el estándar primario aprobado)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-60',
				titulo: 'Cambio del protocolo de calificación del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-61',
				titulo: 'Ampliación de la vida útil del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.2',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.3.2.5',
		nombre: 'Sistema contenedor-cierre',
		incisos: [
			{
				referenciaWHO: 'WHO-B-62',
				titulo: 'Cambio en el sistema contenedor-cierre primario (p.e., nuevo recubrimiento, adhesivo, tapón, tipo de vidrio)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.PRESENTACION, EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-63',
				titulo: 'Cambio de un envase reusable a desechable sin cambios en el material de contacto con el producto (p.e., cambio de una pluma reusable a una pluma desechable)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.PRESENTACION],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-64',
				titulo: 'Eliminación de un sistema contenedor-cierre',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_FARMACO,
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_MEDICAMENTO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.PRESENTACION,
					EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL,
					EModificacionesMedicamentoSeccionesOficio.FORMULA
				],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-65',
				titulo: 'Cambio del fabricante del envase primario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio o adición de un fabricante',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-65',
				titulo: 'Cambio del fabricante del envase primario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Eliminación de un fabricante',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-66',
				titulo: 'Cambio en las especificaciones utilizadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-66',
				titulo: 'Cambio en las especificaciones utilizadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-66',
				titulo: 'Cambio en las especificaciones utilizadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-66',
				titulo: 'Cambio en las especificaciones utilizadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios menores a un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-66',
				titulo: 'Cambio en las especificaciones utilizadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Ampliación de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-66',
				titulo: 'Cambio en las especificaciones utilizadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.2',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.3.2.6',
		nombre: 'Estabilidad',
		incisos: [
			{
				referenciaWHO: 'WHO-B-68',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio al protocolo de estabilidad posterior a la aprobación, tal como eliminación de una prueba, reemplazo de un método analítico o cambio en la temperatura de almacenamiento.',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-68',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de prueba(s) en el protocolo de estabilidad posterior a la aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-B-68',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de tiempo(s) de muestreo en el protocolo de estabilidad posterior a la aprobación dentro del tiempo de vida útil aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.3.2',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.3.2.7',
		nombre: 'Productos biológicos derivados de sangre o plasma',
		incisos: [
			{
				referenciaWHO: '70',
				titulo: 'Cambio en el Expediente Maestro de Plasma, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Cambio de la preparación del pool/mezcla del plasma (p.e., método de fabricación, tamaño del pool/mezcla, almacenamiento de las muestras del pool/mezcla)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: '70',
				titulo: 'Cambio en el Expediente Maestro de Plasma, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Eliminación del país de origen del plasma',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.FORMULA],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['Conforme al numeral 5.7.6.6 de la NOM-072-SSA1-2012, Etiquetado de medicamentos y de remedios herbolarios']
				},
				instructivo: {
					aplica: true,
					notas: ['Conforme al numeral 5.7.6.6 de la NOM-072-SSA1-2012, Etiquetado de medicamentos y de remedios herbolarios']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: '71',
				titulo: 'Cambio en los productos intermedios durante el fraccionamiento del plasma',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Reducción de la vida útil de la columna',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: '71',
				titulo: 'Cambio en los productos intermedios durante el fraccionamiento del plasma',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Aumento de la vida útil de la columna',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: '71',
				titulo: 'Cambio en los productos intermedios durante el fraccionamiento del plasma',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Introducción de controles internos más estrictos en el proceso de fraccionamiento del plasma',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: '71',
				titulo: 'Cambio en los productos intermedios durante el fraccionamiento del plasma',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Vida útil para el almacenamiento de productos intermedios',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO]
	},
	{
		padre: '8.4.2',
		tituloPadre: 'Modificaciones al antígeno',
		numeral: '8.4.2.1',
		nombre: 'Fabricación',
		incisos: [
			{
				referenciaWHO: 'WHO-V-3',
				titulo: 'Cambio en el proceso de fermentación del antígeno, propagación viral o propagación celular, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion:
					'Un cambio no crítico con potencial mínimo de impacto en la calidad del antígeno o producto terminado (p.e., cambio en los procesos de cosecha y/o mezcla que no afectan el método de fabricación, la recuperación, las condiciones de almacenamiento del producto intermedio, la sensibilidad de detección de agentes adventicios o la escala de producción; o la duplicación de la línea de fermentación)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-9',
				titulo: 'Cambios en los bancos celulares, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Generación de un nuevo Banco Celular Maestro (BCM)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-9',
				titulo: 'Cambios en los bancos celulares, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Generación de un nuevo Banco Celular de Trabajo (BCT)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-9',
				titulo: 'Cambios en los bancos celulares, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Cambio en el sitio de almacenamiento del banco celular',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-10',
				titulo: 'Cambios en el lote semilla, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Generación de un nuevo Lote Semilla de Trabajo (LST)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-10',
				titulo: 'Cambios en el lote semilla, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Generación de un nuevo lote semilla de trabajo (LST) mediante la ampliación del número de pases de un LST por arriba del nivel aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-10',
				titulo: 'Cambios en el lote semilla, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambio del sitio de almacenamiento del lote semilla',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-11',
				titulo: 'Cambio del sitio de prueba de los bancos celulares/lotes semilla',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-13',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Introducción de nuevo equipo con los mismos principios de operación, pero diferente material de contacto',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-13',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Introducción del nuevo equipo con diferentes principios de operación, pero el mismo material de contacto',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-13',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Reemplazo de equipo con equipo equivalente (incluyendo filtro)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Reducción de los límites de las pruebas en proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de nuevas pruebas y límites en proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de las pruebas en proceso no significativas',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Ampliación de los límites de las pruebas en proceso aprobados',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Eliminación de prueba(s) en proceso que pueden tener un efecto significativo en la calidad del antígeno',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Adición o sustitución de una prueba en proceso como resultado de un problema de seguridad o calidad',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-16',
				titulo: 'Cambio en el sitio donde se efectúan las pruebas de los controles en proceso',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		tituloPadre: 'Modificaciones al antígeno',
		numeral: '8.4.2.2',
		nombre: 'Control del antígeno',
		incisos: [
			{
				referenciaWHO: 'WHO-V-17',
				titulo: 'Cambios que afectan las pruebas de control de calidad (CC) de liberación y estabilidad del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Transferencia de pruebas de CC de métodos no farmacopeicos a otro sitio',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-17',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Transferencia de pruebas de CC de métodos farmacopeicos a otro sitio',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en la especificación empleada para liberar el antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en la especificación empleada para liberar el antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambios menores a un método analítico aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en la especificación empleada para liberar el antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Un cambio de un método analítico interno a un método analítico farmacopeico reconocido ',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en la especificación empleada para liberar el antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.H,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		tituloPadre: 'Modificaciones al antígeno',
		numeral: '8.4.2.3',
		nombre: 'Estándares o Materiales de Referencia',
		incisos: [
			{
				referenciaWHO: 'WHO-V-19',
				titulo: 'Calificación de un nuevo estándar de referencia frente a un nuevo estándar primario internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-20',
				titulo: 'Cambio en el estándar de referencia interno (sin relación con un estándar internacional) a un estándar farmacopeico o internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-21',
				titulo:
					'Calificación de un nuevo lote de estándar de referencia contra el estándar de referencia aprobado (incluyendo la calificación de un nuevo lote de estándar de referencia secundario contra el estándar primario aprobado)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-22',
				titulo: 'Cambio del protocolo de calificación del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-23',
				titulo: 'Ampliación de la vida útil del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		tituloPadre: 'Modificaciones al antígeno',
		numeral: '8.4.2.4',
		nombre: 'Sistema contenedor-cierre',
		incisos: [
			{
				referenciaWHO: 'WHO-V-24',
				titulo: 'Cambio en el envase primario del sistema contenedor-cierre para el almacenamiento y transporte del antígeno',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios menores a un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Ampliación de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		tituloPadre: 'Modificaciones al antígeno',
		numeral: '8.4.2.5',
		nombre: 'Estabilidad',
		incisos: [
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion:
					'Cambio significativo al protocolo de estabilidad posterior a la aprobación o al compromiso de estabilidad, tal como la eliminación de una prueba, la sustitución de un método analítico, cambio en la temperatura de almacenamiento',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de un tiempo de análisis en el protocolo de estabilidad post-aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Adición de prueba(s) en el protocolo de estabilidad posterior a la aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Eliminación de un tiempo de análisis en el protocolo de estabilidad post-aprobación más allá de la vida útil aprobada',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Eliminación de un tiempo de análisis del protocolo de estabilidad post-aprobación dentro de la vida útil aprobada',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-28',
				titulo: 'Cambio en las condiciones de almacenamiento para el antígeno, que involucre lo siguiente:',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Adición o cambio de las condiciones de almacenamiento para el antígeno (p.e., ampliación o reducción del criterio de temperatura).',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		numeral: '8.4.2.1',
		nombre: 'Fabricación',
		incisos: [
			{
				referenciaWHO: 'WHO-V-3',
				titulo: 'Cambio en el proceso de fermentación del antígeno, propagación viral o propagación celular, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion:
					'Un cambio no crítico con potencial mínimo de impacto en la calidad del antígeno o producto terminado (p.e., cambio en los procesos de cosecha y/o mezcla que no afectan el método de fabricación, la recuperación, las condiciones de almacenamiento del producto intermedio, la sensibilidad de detección de agentes adventicios o la escala de producción; o la duplicación de la línea de fermentación)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-9',
				titulo: 'Cambios en los bancos celulares, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Generación de un nuevo Banco Celular Maestro (BCM)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-9',
				titulo: 'Cambios en los bancos celulares, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Generación de un nuevo Banco Celular de Trabajo (BCT)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-9',
				titulo: 'Cambios en los bancos celulares, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Cambio en el sitio de almacenamiento del banco celular',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-10',
				titulo: 'Cambios en el lote semilla, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Generación de un nuevo Lote Semilla de Trabajo (LST)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-10',
				titulo: 'Cambios en el lote semilla, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Generación de un nuevo lote semilla de trabajo (LST) mediante la ampliación del número de pases de un LST por arriba del nivel aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-10',
				titulo: 'Cambios en el lote semilla, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambio del sitio de almacenamiento del lote semilla',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-11',
				titulo: 'Cambio del sitio de prueba de los bancos celulares/lotes semilla',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-13',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Introducción de nuevo equipo con los mismos principios de operación, pero diferente material de contacto',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-13',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Introducción del nuevo equipo con diferentes principios de operación, pero el mismo material de contacto',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-13',
				titulo: 'Cambio en el equipo utilizado en el proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Reemplazo de equipo con equipo equivalente (incluyendo filtro)',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Reducción de los límites de las pruebas en proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de nuevas pruebas y límites en proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de las pruebas en proceso no significativas',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Ampliación de los límites de las pruebas en proceso aprobados',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Eliminación de prueba(s) en proceso que pueden tener un efecto significativo en la calidad del antígeno',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-15',
				titulo: 'Cambio en las pruebas y/o criterios de aceptación en proceso de fabricación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Adición o sustitución de una prueba en proceso como resultado de un problema de seguridad o calidad',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-16',
				titulo: 'Cambio del sitio donde se efectúan las pruebas de los controles en proceso',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		numeral: '8.4.2.2',
		nombre: 'Control del antígeno',
		incisos: [
			{
				referenciaWHO: 'WHO-V-17',
				titulo: 'Cambios que afectan las pruebas de control de calidad (CC) de liberación y estabilidad del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Transferencia de pruebas de CC de métodos no farmacopeicos a otro sitio',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-17',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Transferencia de pruebas de CC de métodos farmacopeicos a otro sitio',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambios menores a un método analítico aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Un cambio de un método analítico interno a un método analítico farmacopeico ',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-18',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.H,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		numeral: '8.4.2.3',
		nombre: 'Estándares o Materiales de Referencia',
		incisos: [
			{
				referenciaWHO: 'WHO-V-19',
				titulo: 'Calificación de un nuevo estándar de referencia frente a un nuevo estándar primario internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-20',
				titulo: 'Cambio en el estándar de referencia interno (sin relación con un estándar internacional) a un estándar farmacopeico o internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-21',
				titulo:
					'Calificación de un nuevo lote de estándar de referencia contra el estándar de referencia aprobado (incluyendo la calificación de un nuevo lote de estándar de referencia secundario contra el estándar primario aprobado)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-22',
				titulo: 'Cambio del protocolo de calificación del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-23',
				titulo: 'Ampliación de la vida útil del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		numeral: '8.4.2.4',
		nombre: 'Fabricación',
		incisos: [
			{
				referenciaWHO: 'WHO-V-24',
				titulo: 'Cambio en el envase primario del sistema contenedor-cierre para el almacenamiento y transporte del antígeno',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios menores a un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Ampliación de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-25',
				titulo: 'Cambio en las especificaciones del envase primario en el sistema contenedor-cierre del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.2',
		numeral: '8.4.2.5',
		nombre: 'Estabilidad',
		incisos: [
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion:
					'Cambio significativo al protocolo de estabilidad posterior a la aprobación o al compromiso de estabilidad, tal como la eliminación de una prueba, la sustitución de un método analítico, cambio en la temperatura de almacenamiento',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de un tiempo de análisis en el protocolo de estabilidad post-aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Adición de prueba(s) en el protocolo de estabilidad posterior a la aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Eliminación de un tiempo de análisis en el protocolo de estabilidad post aprobación más allá de la vida útil aprobada',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-27',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Eliminación de un tiempo de análisis del protocolo de estabilidad post-aprobación dentro de la vida útil aprobada',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-28',
				titulo: 'Cambio en el protocolo de estabilidad posterior a la aprobación del antígeno, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Adición o cambio de las condiciones de almacenamiento para el antígeno (p.e., ampliación o reducción del criterio de temperatura).',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.3',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.4.3.3',
		nombre: 'Fabricación',
		incisos: [
			{
				referenciaWHO: 'WHO-V-33',
				titulo: 'Cambios en las instalaciones del sitio de fabricación/fabricante del producto terminado, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de un sitio de fabricación para producto terminado',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_MEDICAMENTO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL
				],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-34',
				titulo: 'Cambios en el proceso de fabricación de producto terminado, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Adición de un tamaño de lote limitado por la escala aprobada o disminución del tamaño de lote',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-35',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Reducción de límites en el proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-35',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de nuevas pruebas en proceso y límites',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-35',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Eliminación de una prueba no significativa en el proceso',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-35',
				titulo: 'Cambio en los controles (pruebas en proceso y/o criterios de aceptación) aplicados durante el proceso de fabricación o en productos intermedios, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Adición o cambio de una prueba en el proceso como resultado de un problema de seguridad o calidad',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-36',
				titulo: 'Cambio en el sitio donde se llevan a cabo las pruebas de control en proceso.',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-37',
				titulo: 'Cambios en las especificaciones utilizadas para liberar excipientes, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba de excipientes conocidos',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-37',
				titulo: 'Cambios en las especificaciones utilizadas para liberar excipientes, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-37',
				titulo: 'Cambios en las especificaciones utilizadas para liberar excipientes, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método analítico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-37',
				titulo: 'Cambios en las especificaciones utilizadas para liberar excipientes, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios menores a un método analítico aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-37',
				titulo: 'Cambios en las especificaciones utilizadas para liberar excipientes, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Cambio de un método analítico interno por un método analítico farmacopeico',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-37',
				titulo: 'Cambios en las especificaciones utilizadas para liberar excipientes, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Ampliación de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-37',
				titulo: 'Cambios en las especificaciones utilizadas para liberar excipientes, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.G,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-44',
				titulo: 'Cambio en el sitio donde se efectúan las pruebas de control de calidad de los excipientes',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.3',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.4.3.4',
		nombre: 'Control de producto terminado',
		incisos: [
			{
				referenciaWHO: 'WHO-V-45',
				titulo: 'Cambios que afectan las pruebas de control de calidad (CC) de producto terminado (liberación y estabilidad), que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion:
					'Transferencia de las actividades de pruebas de CC para un ensayo no farmacopeico a una nueva empresa no aprobada en la autorización o licencia de comercialización actual, o a un sitio diferente dentro de la misma empresa',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-45',
				titulo: 'Cambios que afectan las pruebas de control de calidad (CC) de producto terminado (liberación y estabilidad), que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Transferencia de las actividades de pruebas de CC para un ensayo farmacopeico a una nueva empresa no aprobada en la autorización o licencia de comercialización actual',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.3',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.4.3.5',
		nombre: 'Estándares o Materiales de Referencia',
		incisos: [
			{
				referenciaWHO: 'WHO-V-47',
				titulo: 'Calificación de un nuevo estándar de referencia frente a un nuevo estándar primario internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-48',
				titulo: 'Cambio en el estándar de referencia de uso interno (sin relación con un estándar internacional) a un estándar farmacopeico o internacional',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-49',
				titulo:
					'Calificación de un lote nuevo del estándar de referencia contra el estándar de referencia aprobado (incluyendo la calificación de un lote nuevo del estándar secundario de referencia contra el estándar primario aprobado)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-50',
				titulo: 'Cambio del protocolo de calificación del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-51',
				titulo: 'Ampliación de la vida útil del estándar de referencia',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.3',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.4.3.6',
		nombre: 'Sistema contenedor-cierre',
		incisos: [
			{
				referenciaWHO: 'WHO-V-52',
				titulo: 'Cambio en el sistema contenedor-cierre primario (p.e., nuevo recubrimiento, adhesivo, tapón, tipo de vidrio).',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.PRESENTACION, EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-53',
				titulo: 'Cambio de un envase reusable a desechable sin cambios en el material de contacto con el producto (p.e., cambio de una pluma reusable a una pluma desechable)',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.PRESENTACION],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['']
				}
			},

			{
				referenciaWHO: 'WHO-V-54',
				titulo: 'Eliminación de un sistema contenedor-cierre',
				letra: EModificacionesMedicamentoEtiquetas.UNICO,
				descripcion: '',
				seccionesOficio: [
					EModificacionesMedicamentoSeccionesOficio.FABRICANTE_MEDICAMENTO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.ACONDICIONADOR_SECUNDARIO,
					EModificacionesMedicamentoSeccionesOficio.PRESENTACION,
					EModificacionesMedicamentoSeccionesOficio.VIDA_UTIL,
					EModificacionesMedicamentoSeccionesOficio.ENVASE_PRIMARIO,
					EModificacionesMedicamentoSeccionesOficio.FORMULA
				],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: true,
					notas: ['']
				},
				instructivo: {
					aplica: true,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: true,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-55',
				titulo: 'Cambio de fabricante de los componentes del envase primario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio o adición del fabricante',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-55',
				titulo: 'Cambio de fabricante de los componentes del envase primario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Eliminación de un fabricante',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-56',
				titulo: 'Cambio en las especificaciones usadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Eliminación de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-56',
				titulo: 'Cambio en las especificaciones usadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de una prueba',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-56',
				titulo: 'Cambio en las especificaciones usadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Sustitución de un método de análisis',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-56',
				titulo: 'Cambio en las especificaciones usadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Cambios menores a un método de análisis',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-56',
				titulo: 'Cambio en las especificaciones usadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Ampliación de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-56',
				titulo: 'Cambio en las especificaciones usadas para liberar un componente del envase primario o un componente funcional del envase secundario, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.F,
				descripcion: 'Reducción de un criterio de aceptación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	},
	{
		padre: '8.4.3',
		tituloPadre: 'Modificaciones al producto terminado',
		numeral: '8.4.3.7',
		nombre: 'Estabilidad',
		incisos: [
			{
				referenciaWHO: 'WHO-V-58',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.A,
				descripcion: 'Cambio al protocolo de estabilidad posterior a la aprobación, tal como eliminación de una prueba, reemplazo de un método analítico, o cambio en la temperatura de almacenamiento',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-58',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.B,
				descripcion: 'Adición de tiempo(s) de muestreo en el protocolo de estabilidad posterior a la aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-58',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.C,
				descripcion: 'Adición de prueba(s) en el protocolo de estabilidad posterior a la aprobación',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MENOR,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-58',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.D,
				descripcion: 'Eliminación de tiempo(s) de muestreo en el protocolo de estabilidad posterior a la aprobación más alla del tiempo de vida útil aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			},
			{
				referenciaWHO: 'WHO-V-58',
				titulo: 'Cambio en el protocolo de estabilidad del producto terminado posterior a la aprobación, que involucre lo siguiente',
				letra: EModificacionesMedicamentoEtiquetas.E,
				descripcion: 'Eliminación de tiempo(s) de muestreo en el protocolo de estabilidad posterior a la aprobación dentro del tiempo de vida útil aprobado',
				seccionesOficio: [EModificacionesMedicamentoSeccionesOficio.NO_REFLEJADAS_OFICIO],
				tipo: EModificacionesMedicamentoTipo.MODERADA,
				etiqueta: {
					aplica: false,
					notas: ['']
				},
				instructivo: {
					aplica: false,
					notas: ['']
				},
				informacionParaPrescribir: {
					aplica: false,
					notas: ['']
				}
			}
		],
		tiposMedicamento: [EMedicamentoTipo.VACUNA]
	}
]
