/**
 * @name DevSettings.store.ts
 * @description Store para compartir en el prroducto los settings de desarrollo
 * @version 1.0.2
 * @changelog 1.0.1 - 24/oct/24 - @tirsomartinezreyes - Se agregó la propiedad detalleFechasAdministrativas
 * @changelog 1.0.2 - 25/oct/24 - @tirsomartinezreyes - Se agregó la propiedad infoModalidad,infoActivoInformacion
 */
import { defineStore, IDevSettings, useMetaStore } from 'src/bom'

const useDevSettings = defineStore('DevSettings', {
	state: () => {
		const state = {
			...(getDefaultDevSettings() as Partial<IDevSettings>)
		}
		state.debug ?? console.log('Init state useDevSettings')
		return state
	},
	persist: true
})

const CDefaultDevSettings: IDevSettings = {
	debug: false,
	trace: false,
	show: false,
	class: '',
	homoclaveModoDebug: false,
	homoclaveModoTrace: false,
	autoPopulate: false,
	minWords: 5,
	maxWords: 10,
	logRocket: false,
	detalleFechasAdministrativas: false,
	infoModalidad: false,
	infoActivoInformacion: false
}
export const getDefaultDevSettings = (): IDevSettings => {
	const response: IDevSettings = { ...CDefaultDevSettings }
	response.homoclaveModoDebug = useMetaStore().env.DEV
	response.homoclaveModoTrace = useMetaStore().env.DEV
	return response
}

export { useDevSettings }
