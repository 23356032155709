/**
 * @name Dispositivo
 * @author @tirsomartinezreyes
 * @version 0.0.8
 *
 * @description Interfaces y enumeraciones para la definición de dispositivos médicos en el activo de Información IInsumoSalud["dispositivo"]
 * @changelog 0,0,8 - 20/sep/24 - Se agrega la enumeración EDispositivoGrupoPago y se agrega la propiedad IDispositivo.grupoPago
 * @changelog 0.0.7 - Se actualiza la enumeración EDispositivoCategoriaLGS262 fusionando elementos en EDispositivoCategoriaLGS262.MATERIALES_QUIRURGICOS_CURACION y EDispositivoCategoriaLGS262.PROTESIS_ORTESIS_AYUDAS_FUNCIONALES
 */

import {
	IInsumoSaludSujeto //Definición de sujetos relacionados con la cadena de suministro del dispositivo Médico
} from './InsumoSalud' //Proporciona interfaces y enumeraciones de Insumos para la salud

/**
 * @name IDispositivoIDispositivo
 * @description Interfaz para la definición de dispositivos médicos
 */
export interface IDispositivo {
	categoriaDispositivoLGS262: EDispositivoCategoriaLGS262 //Categoría de uso sanitario del dispositivo según la Ley General de Salud, artículo 262
	claseDispositivoRIS83: EDispositivoClaseRIS83 //Clase de riesgo del dispositivo según el Reglamento de Insumos para la Salud, artículo 83
	grupoPago: EDispositivoGrupoPago //Grupo de pago del dispositivo
	esDispositivoInvitro?: boolean //Indica si el dispositivo es de diagnóstico in vitro
	IMDRFCategoriaSaMD?: EDispositivoIMDRFCategoriaSaMD //Clasificación de dispositivos Médicos de Software como Dispositivos Médicos de acuerdo con IMRDF
	IMDRFClasificacionRiesgoDMDIV?: EDispositivoIMDRFClasificacionRiesgoDMDIV //Clasificación de riesgo de dispositivos Médicos In Vitro de acuerdo con IMRDF
	indicacionesUso?: string //Indicaciones de uso del dispositivo
	producto?: IDispositivoProducto //Producto terminado del dispositivo
	presentaciones?: IDispositivoPresentacion[] //Presentaciones del dispositivo
	formulas?: IDispositivoFormula[] //Fórmulas de medicamentos que acompañen al dispositivo
}

/**
 * @name EDispositivoGrupoPago
 */
export enum EDispositivoGrupoPago {
	DISPOSITIVO_PAGO_CLASE_I = 'DISPOSITIVO_PAGO_CLASE_I', //Grupo de pago clase I
	DISPOSITIVO_PAGO_CLASE_II = 'DISPOSITIVO_PAGO_CLASE_II', //Grupo de pago clase II
	DISPOSITIVO_PAGO_CLASE_III = 'DISPOSITIVO_PAGO_CLASE_III', //Grupo de pago clase III
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No se ha seleccionado un grupo de pago
}

/**
 * @name EDispositivoIMDRFClasificacionRiesgoDMDIV
 * @description Enumeración de clasificación de riesgo de dispositivos Médicos In Vitro de acuerdo con IMRDF
 * @ref https://iris.paho.org/bitstream/handle/10665.2/56258/OPSHSSMT220028_spa.pdf?sequence=1&isAllowed=y
 */
export enum EDispositivoIMDRFClasificacionRiesgoDMDIV {
	A = 'A', //Riesgo bajo para la persona y riesgo bajo para la salud pública. ejemplo: Analizador de química clínica, medios de cultivo generales
	B = 'B', //Riesgo moderado para la persona o riesgo bajo para la salud pública. Ejemplo: Vitamina B12, prueba autoadministrada de embarazo, anticuerpos antinucleares, tiras reactivas de orina
	C = 'C', //Riesgo alto para la persona o riesgo moderado para la salud pública. Ejemplo: Autodeterminación de la glucemia, tipificación de HLA, prueba de PSA, rubéola
	D = 'D', //Riesgo alto para la persona y riesgo alto para la salud pública. Ejemplo: Tamizaje de donantes de sangre para detectar el VIH y diagnóstico del VIH en sangre.
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No se ha seleccionado un grupo de pago
}

/**
 * @name EDispositivoIMDRFCategoriaSaMD
 * @description Enumeración de clasificación de dispositivos Médicos de Software como Dispositivos Médicos de acuerdo con IMRDF
 * @ref https://www.imdrf.org/sites/default/files/docs/imdrf/final/technical/imdrf-tech-140918-samd-framework-risk-categorization-141013.pdf
 */
export enum EDispositivoIMDRFCategoriaSaMD {
	I = 'I', //Dispositivos médicos de software de clase I
	II = 'II', //Dispositivos médicos de software de clase II
	III = 'III', //Dispositivos médicos de software de clase III
	IV = 'IV' //Dispositivos médicos de software de clase IV
}

/**
 * @name EDispositivoCategoriaLGS262
 * @description Enumeración de tipos de dispositivos Médicos por su categoría de uso según la Ley General de Salud, artículo 262
 * @reference https://transparencia.cofepris.gob.mx/index.php/es/marco-juridico/leyes
 * @reference https://www.diputados.gob.mx/LeyesBiblio/pdf/LGS.pdf
 * @comment Se separan Prótesis, Órtesis, Ayudas Funcionales, Materiales Quirúrgicos y materiales de Curación de sus categorías generales, a razón de que son utilizadas de manera individual en los oficios de autorización
 */
export enum EDispositivoCategoriaLGS262 {
	I_EQUIPO_MEDICO = 'I_EQUIPO_MEDICO',
	II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES = 'II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES',
	III_AGENTES_DIAGNOSTICO = 'III_AGENTES_DIAGNOSTICO',
	IV_INSUMOS_USO_ODONTOLOGICO = 'IV_INSUMOS_USO_ODONTOLOGICO',
	V_MATERIALES_QUIRURGICOS_CURACION = 'V_MATERIALES_QUIRURGICOS_CURACION',
	VI_PRODUCTOS_HIGIENICOS = 'VI_PRODUCTOS_HIGIENICOS',
	VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS = 'VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS',
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No se ha seleccionado una clasificación
}

/**
 * @name EDispositivoClaseRIS83
 * @description Enumeración de clasificación de dispositivos Médicos de acuerdo con el riesgo sanitario según el Reglamento de Insumos para la Salud, artículo 83
 * @reference https://transparencia.cofepris.gob.mx/index.php/es/allcategories-es-es/41-transparencia/marco-juridico/reglamentos
 * @reference http://www.ordenjuridico.gob.mx/Documentos/Federal/pdf/wo88318.pdf
 */
export enum EDispositivoClaseRIS83 {
	I_BAJO_RIESGO = 'I_BAJO_RIESGO', //No se encuentra referencia particular
	I = 'I', //Aquellos insumos conocidos en la práctica médica y que su seguridad y eficacia están comprobadas y, generalmente, no se introducen al organismo
	II = 'II', // Aquellos insumos conocidos en la práctica médica y que pueden tener variaciones en el material con el que están elaborados o en su concentración y, generalmente, se introducen al organismo permaneciendo menos de 30 días
	III = 'III', // Aquellos insumos nuevos o recientemente aceptados en la práctica médica, o bien que se introducen al organismo y permanecen en él, por más de 30 días
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No se ha seleccionado una clasificación
}

/**
 * @name IDispositivoProducto
 * @description Interfaz para la definición de productos terminados de dispositivos médicos
 */
export interface IDispositivoProducto {
	descripcion?: string //Descripción del producto terminado
	fabricantes: IInsumoSaludSujeto[] //Fabricante del producto terminado ¿Quién produce el producto?
	importadores?: IInsumoSaludSujeto[] //Importadores del producto terminado (Solo para productos de fabricación extranjera) ¿Quién trajo el producto desde el país de fabricación a méxico desde el extranjero?
	distribuidores?: IInsumoSaludSujeto[] //Distribuidores del producto terminado ¿Quien se encarga de distribuir el producto en el mercado ?
	almacenes?: IInsumoSaludSujeto[] //Almacenes del producto terminado
	maquiladores?: IInsumoSaludSujeto[] //Maquiladores del producto terminado
	caducidad?: string //Caducidad del producto terminado
	publicidadDirigida?: string //¿Hacia quién(es) va dirigida la publicidad dirigida del producto terminado
	notas?: string[] //Notas adicionales sobre el producto terminado
}

/**
 * @name IDispositivoPresentacion
 * @description Interfaz para la definición de presentaciones de dispositivos médicos
 */
export interface IDispositivoPresentacion {
	tipo: string //Resumen de la presentación del dispositivo, (p,e, Disco cervical Artificial pequeño de 5 mm)
	presentacion: IDispositivoPresentacionItem[] //Descripción de la presentación del dispositivo, (p,e, Disco cervical Artificial pequeño de 5 mm)
	accesorio?: IDispositivoEspecificacionItem //Elementos que acompañan al dispositivo, pero no son intrínsecos a él, pero si son intrínsecos a la presentación
	consumible?: IDispositivoEspecificacionItem //Consumibles del dispositivo
}
export interface IDispositivoPresentacionItem {
	identificador: string //Identificador de la presentación del dispositivo, (p,e, "Disco cervical Artificial pequeño de 5 mm")
	descripcion: string //Descripción de la presentación del dispositivo, (p,e, "Disco cervical Artificial pequeño de 5 mm")
	envases?: IDispositivoEnvase
	notaEspecifica?: IDispositivoNotaEspecifica //Etiqueta de la presentación del dispositivo (p,e, "Con base móvil de acero inoxidable")
	claveCNIS?: string //Clave (si aplica) de coincidencia de la presentación del dispositivo con el Compendio Nacional de Insumos para la Salud
}
/**
 * @name IDispositivoEnvase
 * @description Interfaz para la definición de envases de dispositivos médicos
 */
export interface IDispositivoEnvase {
	envasePrimario: string //p.e, Frasco
	envaseSecundario: string // p.e. Vidrio
}

/**
 * @name IDispositivoEspecificacionItem
 * @description Interfaz para la definición de elementos de la tabla de especificaciones de dispositivos médicos
 */
export interface IDispositivoEspecificacionItem {
	tipo: string //Tipo de elemento de la tabla de especificaciones, pe, "Envase"
	elementos: IDispositivoEspecificacionItemElemento[] //Elementos de la tabla de especificaciones
}
export interface IDispositivoEspecificacionItemElemento {
	identificador: string //Código de producto, ID o referencia externa que exista por el fabricante, pe "CD-02135"
	descripcion: string //Descripción del elemento de la tabla de especificaciones, pe, "Batería de litio 1200 ma"
	notaEspecifica?: IDispositivoNotaEspecifica //Nota adicional sobre el elemento de la tabla de especificaciones, pe, "No recargable"
}

export interface IDispositivoFormula {
	nombre: string //Nombre de la fórmula
	expresionFormula: string //Expresión de la fórmula
	componentes?: IDispositivoFormulaComponente[] //Componentes de la fórmula
	observaciones?: string //Observaciones sobre la fórmula
}
export interface IDispositivoFormulaComponente {
	componente: string //Componente de la fórmula
	contenido: string //Contenido de la fórmula
}
export interface IDispositivoNotaEspecifica {
	registroSanitario?: string //Registro sanitario de la presentación del dispositivo
	envases?: IDispositivoEnvase
	versionSoftware?: string //Versión del software del dispositivo
	esterilizacion?: string //Método de esterilización del dispositivo
	otros?: string //Otros datos del elemento de la tabla de especificaciones
}
