/**
 * @name Homoclaves.ts
 * @description Typos del Registro de Homoclaves de trámites de COFEPRIS
 * @author @tirsomartinezreyes
 * @version 1.0.11
 *@changelog  1.0.11 - Se centraliza la interfaz de IFirmaAutenticador en el módulo de Firma para no tener código repetido @Jorel254 @MrCalamitus
 * @changelog 1.0.10 - Se agregan propiedades en interafce de modalidades para integrar valores de cadena de pago e5cincoReferenciaDPA y e5PrefijoCadenaDependencia
 * @changelog 1.0.9 - Se agrega IHomoclaveRegistryModalidadSinFormatos.porcentajePago y grupoPago para el cálculo del pago del trámite
 * @changelog 1.0.8 - Se agrega domicilioEstablecimiento a IHomoclaveDatosGeneralesInsumoSalud
 * @changelog 1.0.7 - Se actualiza tipo de dato del dossier para simplificar la estructura y la profundidad
 * @changelog 1.0.6 - Se actualiza IHomoclaveDatosMedicamento.dossier y IHomoclaveDatosDispositivo.dossier como ICTD opcionales
 * @changelog 1.0.5 - Se agrega IHomoclaveRegistryModalidadInsumoSalud.prorrogaRetroactiva
 * @changelog 1.0.4 - Se agrega EHomoclaveGrupoCoordinacion HomoclaveRegistryModalidad.grupoCoordinacion
 * @changelog 1.0.3 - Se agregan infijos para dispositivos médicos
 * @changelog 1.0.2 - Se agregan infijos para COFEPRIS-2022-022-006-A,COFEPRIS-2022-022-007-A, COFEPRIS-2022-022-008-A, COFEPRIS-2022-022-009-A y COFEPRIS-2022-022-010-A
 * @changelog 1.0.1 - Se agrega HomoclaveRegistryModalidad.requiereEstructuraMedicamento como opcional
 *
 */
import { AnyEventObject, InvokeCreator } from 'xstate'
import { ERolType, RolType } from './Roles'
import { EActor, EOrganizacionUnidadesOrganizativas } from './Organizacion'
import {
	IHomoclaveDoGenerarSolicitudRequestInput,
	IHomoclaveEnviarSolicitudFirmadaRequestInput,
	IHomoclaveIDSolicitudRequestInput,
	IHomoclaveDesistirSolicitudFirmadaRequestInput,
	IHomoclaveEnviarEvaluacionFirmadaRequestInput,
	IHomoclaveActualizarPermisosRequestInput,
	IHomoclaveContextEvaluacionHumanaRequestInput,
	IHomoclaveType,
	IHomoclaveDoEditarSolicitudRequestInput
} from 'cofepris-typesafe/graphql/homoclave/request'
import { CUSTOM_TYPE, ICustomTypeAny, ICustomTypeUnion, ICustomValueUnion, IEnumValue, IFileValue, NIVEL_ACCESO } from 'cofepris-typesafe/src/primitives/IPrimitive'
import IFormato from 'cofepris-typesafe/src/forms/IFormato'
import { IDataAccessTokenSolicitanteMetaData } from './Auth'
import INodoRecurso from '../src/models/INodoRecurso'
import { IPugTemplateVersion } from './PugTemplates'
import { EnvironmentType } from '../Constants/Config'
import { EGrupoTipoPersona } from 'cofepris-typesafe/Types/Auth/enums/EGrupoTipoPersona'
import { ICustomTypeKeyUnion } from '../src/primitives/IPrimitive'
import {
	EAIInsumoSaludTipo,
	EDispositivoCategoriaLGS262,
	EDispositivoClaseRIS83,
	EDispositivoGrupoPago,
	EMedicamentoClaseLGS226,
	EMedicamentoGrupoPago,
	EMedicamentoFormaFarmaceutica,
	EMedicamentoTipo
} from 'cofepris-typesafe/Modules/InsumoSalud'
import { EModificacionesMedicamentoTipo, EModificacionesMedicamentoSeccionesOficio, IModificacion, IEstructuraModificaciones, IFormula } from 'cofepris-typesafe/Modules/ModificacionesMedicamentos'
import { IEstructuraMedicamento } from 'cofepris-typesafe/Modules/EstructuraInsumoSalud'
import { getDatoByTagFromFormatos } from 'cofepris-typesafe/src/utils/HomoclaveContextUtils'
import { EModificacionDispositivosTipo, EModificacionDispositivosSeccionOficio, IModificacionDatos, IEstructuraModificacionesDispositivos } from 'cofepris-typesafe/Modules/ModificacionesDispositivos'
import { EFechaAdministrativaOperacion } from 'cofepris-typesafe/Modules/FechasAdministrativas'
import { IFirmaAutenticador } from 'cofepris-typesafe/Modules/Firma'

export const esLegado = function esLegado(context: IHomoclaveContext | undefined): boolean {
	const evaluaciones = context?.evaluacion ?? []
	const ultimaEvaluacion = evaluaciones[evaluaciones.length - 1]
	const idAutorizacionDictaminador = getDatoByTagFromFormatos(ultimaEvaluacion?.datos?.formatos || [], 'TAG_PAI_NUMERO_AUTORIZACION_INICIAL')

	const idActivoInformacion = context?.metadatos?.idActivoInformacion?.trim() ?? ''

	if (idAutorizacionDictaminador === idActivoInformacion) {
		return true
	}
	const isLegado = idActivoInformacion == ''
	return isLegado
}

export enum EHomoclaveContextTipoResolucion {
	ATENDIDO = 'ATENDIDO',
	AUTORIZADO = 'AUTORIZADO',
	DESECHADO = 'DESECHADO',
	DESECHADO_EXPIRADO = 'DESECHADO_EXPIRADO',
	PREVENIDO = 'PREVENIDO',
	REVOCADO = 'REVOCADO',
	DESISTIDO = 'DESISTIDO',
	CANCELADO = 'CANCELADO',
	AFIRMATIVA_FICTA = 'AFIRMATIVA_FICTA',
	NEGATIVA_FICTA = 'NEGATIVA_FICTA'
}

export type HomoclaveContextResultadoEvaluacion = 'ATENDIDO' | 'AUTORIZADO' | 'DESECHADO' | 'PREVENIDO' | EHomoclaveContextResultadoEvaluacion
export enum EHomoclaveContextResultadoEvaluacion {
	ATENDIDO = 'ATENDIDO',
	AUTORIZADO = 'AUTORIZADO',
	DESECHADO = 'DESECHADO',
	PREVENIDO = 'PREVENIDO',
	DESECHADO_EXPIRADO = 'DESECHADO_EXPIRADO'
}

/**
 * REGISTRO DE HOMOCLAVES
 * Datos relacionados al almacén de homoclaves y modalidades que utiliza el sistema
 * para la presentación de solicitudes de trámites.
 * El registro mantiene N homoclaves, y cada homoclave puede tener M modalidades (variantes) de la homoclave para atender un trámite particular.
 */
export interface IHomoclaveContextSolicitudFirma extends IHomoclaveContextCadenaOriginal, IHomoclaveContextFirma {
	algoritmo: 'sha256WithRSAEncryption'
	certificado: string
	numeroCertificado: string
	curpFirmante: string
	nombreFirmante: string
	sha256: string
	integrityElements?: ISSOFirmaIntegrityElementsData
}

export type HomoclaveRegistry = HomoclaveRegistryItem[]

export interface HomoclaveRegistryItem {
	homoclave: string
	titulo: string
	modalidades?: HomoclaveRegistryModalidad[]
}

export interface IHomoclaveRegistryModalidadFormatos {
	informacion?: IHomoclaveRegistryFormato[]
	solicitud?: IHomoclaveRegistryFormato[]
	respuesta_prevencion?: IHomoclaveRegistryFormato[]
	evaluacion?: IHomoclaveRegistryFormato[]
	resolucion?: IHomoclaveRegistryFormato[]
}

export type EHomoclaveInfijoModalidad =
	| '000A'
	| '2203AA'
	| '2203AB'
	| '2203AO'
	| '2201AO'
	| '2201AR'
	| '2202AO'
	| '2202AR'
	| '2204AX'
	| '2205AX'
	| '226AAA'
	| '226AAB'
	| '226AAC'
	| '226AAD'
	| '226AAE'
	| '226AAF'
	| '226ABO'
	| '226ABR'
	| '227AAO'
	| '227AAR'
	| '227ABO'
	| '227ABR'
	| '228AAO'
	| '228AAR'
	| '228ABO'
	| '228ABR'
	| '229AAO'
	| '229AAR'
	| '2210AO'
	| '2210AR'
	| '2211AA'
	| '2211AB'
	| '2211AC'
	| '2212AA'
	| '2212AB'
	| '2212AC'
	| '2213AA'
	| '2213AB'
	| '2213AC'
	| '401AC1'
	| '401AC2'
	| '401AC3'
	| '401BC1'
	| '401BC2'
	| '401BC3'
	| '401CC1'
	| '401CC2'
	| '401CC3'
	| '401DC1'
	| '401EC2'
	| '401EC3'
	| '401FC1'
	| '401FC2'
	| '401FC3'
	| '401GC1'
	| '401GC2'
	| '401GC3'
	| '401HC1'
	| '401HC2'
	| '401HC3'
	| '401IXX'
	| '401JXX'
	| '401KXX'
	| '404AAX'
	| '404ABX'
	| '404BAX'
	| '404BBX'
	| '404BCX'
	| '404CAX'
	| '404CBX'
	| '404DAX'
	| '404DBX'
	| '404DCX'
	| '404EBX'
	| '404FBX'
	| '404GBX'
	| '404HBX'
	| '404IBX'
	| '404JBX'
	| '404KAX'
	| '405XRX'
	| '405XMX'
	| '405XPX'
	| '406AXX'
	| '406BXX'
	| '407AXX'
	| '407BXX'
	| '408AXX'
	| '408BXX'
	| '410A'
	| '410B'
	| '410C'
	| '410D'
	| '412AXX'
	| '412BXX'
	| '912XA'
	| '912XB'
	| '912XC'
	| '912XD'
	| '912XE'
	| '912XF'
	| '912XG'
	| '912XH'
	| '912XI'
	| '912XJ'
	| '912XK'
	| '912XL'
	| '1001AA'
	| '1001AB'
	| '1001AC'
	| 'EL87RX'
	| 'EL87MX'
	| 'EL87PX'
	| 'ES45A'
	| 'ES45B'
	| 'ES47X'
	| 'X'

export enum EHomoclaveRegistryGrupoCoordinacion {
	MEDICAMENTOS_FQ_CON_REGISTRO = 'MEDICAMENTOS_FQ_CON_REGISTRO', //Farmoquímicos con registro sanitario
	MEDICAMENTOS_FQ_SIN_REGISTRO = 'MEDICAMENTOS_FQ_SIN_REGISTRO', //Farmoquímicos sin registro sanitario
	MEDICAMENTOS_BBV = 'MEDICAMENTOS_BBV', //Biológico Biotecnológico Vacuna
	MEDICAMENTOS_HHV = 'MEDICAMENTOS_HHV', //Homeopáticos, Herbolarios y Vitamínicos
	DISPOSITIVOS_CON_REGISTRO = 'DISPOSITIVOS_CON_REGISTRO', //Dispositivos con Registro Sanitario
	DISPOSITIVOS_SIN_REGISTRO_CLASE_I = 'DISPOSITIVOS_SIN_REGISTRO_CLASE_I', //Dispositivos Clase I
	DISPOSITIVOS_SIN_REGISTRO_CLASE_II = 'DISPOSITIVOS_SIN_REGISTRO_CLASE_II', //Dispositivos Clase II
	DISPOSITIVOS_SIN_REGISTRO_CLASE_III = 'DISPOSITIVOS_SIN_REGISTRO_CLASE_III', //Dispositivos Clase III
	DISPOSITIVOS_ACUERDO_EQUIVALENCIA = 'DISPOSITIVOS_EQUIVALENCIA' //Dispositivos con acuerdo de equivalencia
}

export interface IHomoclaveRegistryModalidadSinFormatos {
	modalidad: string
	modalidadConamer?: string //Código de la modalidad en el catálogo de CONAMER en https://catalogonacional.gob.mx/
	subtitulo: string
	titulo: string
	descripcion: string
	tituloLista?: string
	elementosLista?: string[]
	atencion: number // días que COFEPRIS tiene para darle resoloución a una solicitud
	atencionPrevencion?: number // días que COFEPRIS tiene para responder a una prevención
	tiempoRespuestaSolicitante: number // días que el solicitante tiene para responder a una solicitud de información adicional por parte de COFEPRIS
	calculoAtencion?: EFechaAdministrativaOperacion
	calculoAtencionPrevencion?: EFechaAdministrativaOperacion
	calculoTiempoRespuestaSolicitante?: EFechaAdministrativaOperacion
	version: number
	infijoIdTramite: EHomoclaveInfijoModalidad
	unidadOrganizativa: EOrganizacionUnidadesOrganizativas | string
	configuracionEvaluacion?: IHomoclaveRegistryModalidadConfiguracionEvaluacion
	porcentajePago?: number
	grupoPago?: EMedicamentoGrupoPago | EDispositivoGrupoPago
	e5cincoReferenciaDPA?: string //Clave de la dependencia para la referencia de pago
	e5PrefijoCadenaDependencia?: string //Prefijo de la cadena de la dependencia
}

export interface IHomoclaveRegistryPathCampo {
	formato: string
	seccion: string
	tagCampo: string
	etapa: string
}

export interface HomoclaveRegistryModalidad extends IHomoclaveRegistryModalidadSinFormatos {
	formatos?: IHomoclaveRegistryModalidadFormatos
	formatosAntecedentes?: IHomoclaveRegistryModalidadFormatos
	requiereEstructuraMedicamento?: boolean
	requiereAntecedenteActivoInformacion?: EHomoclaveRegistryAntecedenteActivoInformacion
	produceActivoInformacion?: EHomoclaveRegistryAntecedenteActivoInformacion
	omitirCamposDigital?: IHomoclaveRegistryPathCampo[]
	evaluaciones: EHomoclaveContextResultadoEvaluacion[]
	insumoSalud?: IHomoclaveRegistryModalidadInsumoSalud //Interface agregada para Insumos de Salud
	grupoCoordinacion?: EHomoclaveRegistryGrupoCoordinacion //Mesa de trabajo que atiende la homoclave / modalidad
	//formatosAntecedentesDictaminador?: IHomoclaveRegistryModalidadFormatos
}

export interface IHomoclaveRegistryModalidadInsumoSalud {
	tipoInsumo: EAIInsumoSaludTipo //Medicamento o Dispositivo Médico
	banderasInsumos?: EHomoclaveBanderasModificaciones[]
	prorrogaRetroactiva?: boolean
	medicamento?: IHomoclaveRegistryModalidadInsumoSaludMedicamento //Nodo específico para homoclaves que involucran Medicamentos
	dispositivo?: IHomoclaveRegistryModalidadInsumoSaludDispositivo //Nodo específico para homoclaves que involucran Dispositivos Médicos
	accion: EHomoclaveRegistryModalidadInsumoSaludAccion //Acción a realizar en el trámite
}

export interface IHomoclaveRegistryModalidadInsumoSaludMedicamento {
	tipoModificaciones?: EModificacionesMedicamentoTipo[] //Tipos de modificaciones que se pueden realizar a un medicamento (Moderaas, menores o mayores)
	tipoMedicamentos?: EMedicamentoTipo[] //Tipos de medicamentos a los que aplican las modificaciones
}

export interface IHomoclaveRegistryModalidadInsumoSaludDispositivo {
	tipoModificaciones?: EModificacionDispositivosTipo[] //Tipos de modificaciones que se pueden realizar a un dispositivo médico (Moderaas, menores o mayores)
	tipoDispositivo?: EDispositivoCategoriaLGS262[]
	claseDispositivo?: EDispositivoClaseRIS83[]
}
export enum EHomoclaveRegistryModalidadInsumoSaludAccion {
	AUTORIZACION = 'AUTORIZACION',
	RECONOCIMIENTO_HUERFANO = 'RECONOCIMIENTO_HUERFANO',
	MODIFICACION = 'MODIFICACION',
	PRIMERA_PRORROGA = 'PRIMERA_PRORROGA',
	SEGUNDA_PRORROGA = 'SEGUNDA_PRORROGA',
	CESION = 'CESION',
	REVOCACION = 'REVOCACION'
}

export enum EHomoclaveRegistryAntecedenteActivoInformacion {
	AI_ENSAYO_CLINICO = 'AI_ENSAYO_CLINICO',
	AI_INSUMO_SALUD = 'AI_INSUMO_SALUD'
}

export type IHomoclaveRegistryModalidad = HomoclaveRegistryModalidad

//Formatos (formularios) a cargar desde el motor de persistencia y a mostrar con formify
export interface HomoclaveRegistryFormato {
	titulo?: string
	formato: string
	secciones: string[]
	definicionFormato?: IFormato
	actores?: EActor[]
}
export type IHomoclaveRegistryFormato = HomoclaveRegistryFormato

export interface IHomoclaveRegistryModalidadConfiguracionEvaluacion {
	permitirOk?: boolean
	permitirWarning?: boolean
	permitirError?: boolean
	permitirObservaciones?: boolean
	longitudMaximaObservaciones?: number
}

/**
 * FSM DE SOLICITUDES
 * La máquina de estados que controla el flujo (ciclo de vida) de una solicitud.
 * una FSM se comporta principalmente de 3 partes:
 *  1. Estados y transiciones: Son los estados y transiciones que puede tener la FSM, estas se apegan al modelo de máquina genérica definida para la atención de todas las homoclaves.
 *  2. Contexto: Es el contexto de la FSM, es decir, los datos que se van a utilizar para el funcionamiento de la FSM.
 *  3. Configuración: Es la configuración de la FSM, es decir, el código de las funciones que se va a ejecutar en cada estado y transición según la configuración de la FSM:
 *      3.1 Acciones (actions): Son las acciones que se ejecutan en cada transición (síncronas y puras).
 *      3.2 Servicios (services): Son los servicios que se ejecutan en cada estado (asíncronos).
 *      3.3 Guardias (guards): Son las validaciones que se ejecutan en cada transición y que deben cumplimse a un valor booleano de verdad para permitir la transición(síncronas y puras).
 *
 */

//1.- ESTADOS Y TRANSICIONES DEL TRÁMITE
export interface IHomoclaveMachine {
	id: string
	initial: string
	context: IHomoclaveContext
	states: {
		idle: object
		informacion: object
		generando_solicitud: object
		generando_solicitud_error: object
		solicitud: object
		evaluacion: object
		resolucion: object
		borrado: object
		respuesta_prevencion: object
	}
}

export interface IHomoclaveEventPayload {
	GENERA_SOLICITUD: IHomoclaveDoGenerarSolicitudRequestInput

	EDITA_SOLICITUD: IHomoclaveDoEditarSolicitudRequestInput

	FIRMA_SOLICITUD: IHomoclaveEnviarSolicitudFirmadaRequestInput

	CONFIRMA_BORRADO: IHomoclaveIDSolicitudRequestInput

	DESISTE_TRAMITE: IHomoclaveDesistirSolicitudFirmadaRequestInput

	EDITA_EVALUACION: IHomoclaveContextEvaluacionHumanaRequestInput

	SOLICITA_RUBRICA: {
		type?: 'SOLICITA_RUBRICA'
		evaluacion: HomoclaveContextEvaluacionHumana
	}

	EMITE_RUBRICA: {
		type?: 'EMITE_RUBRICA'
		evaluacion: HomoclaveContextEvaluacionHumana
		firma: IHomoclaveContextEvaluacionHumanaFirma
	}

	CANCELA_RUBRICA: {
		type?: 'CANCELA_RUBRICA'
		evaluacion: HomoclaveContextEvaluacionHumana
	}

	ENVIA_RUBRICA: IHomoclaveEnviarEvaluacionFirmadaRequestInput

	CANCELA_EVALUACION: {
		type?: 'CANCELA_EVALUACION'
		idSolicitud: string
		jwt: string
	}

	SOLICITA_FIRMA: {
		type?: 'SOLICITA_FIRMA'
		idSolicitud: string
		jwt: string
	}

	FIRMA_RESOLUCION: {
		type?: 'FIRMA_RESOLUCION'
		idSolicitud: string
		firma: HomoclaveContextResolucionHumanaFirma
		jwt: string
	}

	CANCELA_VERIFICACION: {
		type?: 'CANCELA_VERIFICACION'
		idSolicitud: string
		jwt: string
	}

	/*
	EDITA_RESPUESTA_PREVENCION: {
		type?: 'EDITA_RESPUESTA_PREVENCION'
		idSolicitud: string
		promises: any[]
		jwt: string
	}
	*/
	EDITA_RESPUESTA_PREVENCION: IHomoclaveDoEditarSolicitudRequestInput

	FIRMA_RESPUESTA_PREVENCION: {
		type?: 'FIRMA_RESPUESTA_PREVENCION'
		idSolicitud: string
		firma: IHomoclaveContextSolicitudFirma
		jwt: string
	}

	ACTUALIZA_PERMISOS: IHomoclaveActualizarPermisosRequestInput
}

//2.- CONTEXTO DE TRÁMITE
//export type HomoclaveContextDatoItem = { k: string; v: string };
export enum EEvaluacionValor {
	OK = 'OK',
	WARNING = 'WARNING',
	ERROR = 'ERROR'
}
export interface IComentarioCampo {
	idCampo: string
	evaluacion?: EEvaluacionValor
	observaciones?: string
	fechaCreacion?: number // Fecha en la que se crea el comentario
	fechaActualizacion?: number // Fecha en la que se actualiza el comentario
}

export interface ISugerenciaDatoFormularioValor {
	valor: ICustomValueUnion //string
	apariciones: number
	index?: number
}
export interface ISugerenciaDatoFormulario {
	idCustomType: string
	sugerencias: ISugerenciaDatoFormularioValor[]
}

export interface IDatoFormulario {
	idCampo: string
	idDato?: string
	outDataPath?: string
	guid: string
	idCustomType?: string | ICustomTypeAny
	valor?: ICustomTypeUnion
	valuePath: ICustomTypeKeyUnion
	typeName?: string
	type: CUSTOM_TYPE
	accessLevel?: NIVEL_ACCESO
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IComentarioFormularioInput extends IComentarioCampo {}

export interface IDatoFormularioInput extends IDatoFormulario {
	valueString?: string

	valueNumber?: number

	valueDate?: Date

	valueBoolean?: boolean

	valueFile?: IFileValue[]

	valueEnum?: IEnumValue[]

	valueTable?: IDatoFormulario[][] //cells

	type: CUSTOM_TYPE
	valuePath: ICustomTypeKeyUnion
	guid: string
	typeName?: string

	accessLevel?: NIVEL_ACCESO

	idDato?: string
}
export interface IDatosFormulario {
	idFormulario: string
	campos: IDatoFormulario[]
}
export interface IDatosFormato {
	idFormato: string | IFormato
	secciones: IDatosSeccion[]
}

export interface IDatosSeccion {
	idSeccion: string
	idDuplicado?: string
	indiceDuplicado?: number
	formularios?: IDatosFormulario[]
}

export interface HomoclaveContextDatos {
	formatos?: IDatosFormato[]
	insumosSalud?: IHomoclaveDatosInsumosSalud //Nodo para agregar informaciónd el negocio de Insumos para la salud
}

/**
 * @name IHomoclaveDatosInsumosSalud
 * @description Nodo para persistir información relacionada al estado de solicitud específicamente para insumos para la salud
 */
export interface IHomoclaveDatosInsumosSalud {
	insumoSalud?: IHomoclaveDatosInsumoSalud //Información que es genérica para cualquier tipo de insumo
	dispositivo?: IHomoclaveDatosDispositivoMedico //información específica para insumos de tipo dispositivo médico
	medicamento?: IHomoclaveDatosMedicamento //Información específica para insumos de tipo medicamento
}
/**
 * @name IHomoclaveInsumoDatosEvaluados
 * @description Nodo para persistir información generica del insumo para la salud relacionada al estado de solicitud
 */
export interface IHomoclaveInsumoDatosEvaluados {
	draftActivoSalud?: string
	autorizacion: IInusmoDatos
	prevencion: IInusmoDatos
	desecho: IInusmoDatos
}
/**
 * @name IInusmoDatos
 * @description Nodo para persistir información de la dictaminación de un insumo para la salud
 */
export interface IInusmoDatos {
	fundamentoSolicitud: string
	textoLibre?: string
	formulas?: IFormula[]
	fundamentosFormulas?: string
	motivoPrevencion?: string
	motivoDesecho?: string
}

/**
 * @name IHomoclaveDatosInsumoSalud
 * @description Nodo para persistir información generica del insumo para la salud relacionada al estado de solicitud
 */
export interface IHomoclaveDatosInsumoSalud {
	idActivoInformacion: string
	tipoAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD
	tipoInsumoSalud: EAIInsumoSaludTipo
	datosGenerales?: IHomoclaveDatosGeneralesInsumoSalud
}

export interface IHomoclaveDatosGeneralesInsumoSalud {
	domicilioEstablecimiento?: string
	direccionTitular?: string
	direccionRepresentante?: string
	ultimoOficioAutorizadoRegistroSanitario?: string
	denominacionGenerica?: string
	categoriaDispositivoLGS262?: EDispositivoCategoriaLGS262
	claseDispositivoRIS83?: EDispositivoClaseRIS83[]
	claseMedicamentoLGS226?: EMedicamentoClaseLGS226
	formaFarmaceuticaMedicamento?: EMedicamentoFormaFarmaceutica
}
/**
 * @name IHomoclaveDatosMedicamento
 * @description Nodo para persistir información relacionada a medicamentos en el estado de solicitud
 */
export interface IHomoclaveDatosMedicamento {
	estructuraMedicamento?: IEstructuraMedicamento
	modificacionesMedicamento?: IModificacion[]
	modificacionesMedicamentoSeccionesOficio?: EModificacionesMedicamentoSeccionesOficio[]
	formatosModificacionesMedicamento?: IEstructuraModificaciones
	llavesPago?: IHomoclaveLlavePagoInsumosSalud[]
	dossier?: string
	datosEvaluados?: IHomoclaveInsumoDatosEvaluados
}

export interface IHomoclaveLlavePagoInsumosSalud {
	llave: string
	monto: string
}
/**
 * @name IHomoclaveDatosDispositivoMedico
 * @description Nodo para persistir información relacionada a dispositivos médicos en el estado de solicitud
 */
export interface IHomoclaveDatosDispositivoMedico {
	modificacionesDispositivosSecionesOficio?: EModificacionDispositivosSeccionOficio[]
	formatosModificacionesDispositivo?: IEstructuraModificacionesDispositivos
	modificacionesDispositivos?: IModificacionDatos[]
	llavesPago?: IHomoclaveLlavePagoInsumosSalud[]
	dossier?: string
	datosEvaluados?: IHomoclaveInsumoDatosEvaluados
}

export interface IHomoclaveContextDatos extends HomoclaveContextDatos, IHomoclaveType {}

export interface IComentariosFormulario {
	idFormulario: string
	campos?: IComentarioCampo[]
}
export interface IComentariosFormato {
	idFormato: string | IFormato
	secciones?: IComentariosSeccion[]
}

export interface IComentariosSeccion {
	idSeccion: string
	formularios?: IComentariosFormulario[]
	idDuplicado?: string
	indiceDuplicado?: number
}
export interface IHomoclaveContextComentarios {
	formatos?: IComentariosFormato[]
}

export type HomoclaveContextTipoResolucion =
	| EHomoclaveContextTipoResolucion.ATENDIDO
	| EHomoclaveContextTipoResolucion.AUTORIZADO
	| EHomoclaveContextTipoResolucion.DESECHADO
	| EHomoclaveContextTipoResolucion.DESECHADO_EXPIRADO
	| EHomoclaveContextTipoResolucion.PREVENIDO
	| EHomoclaveContextTipoResolucion.REVOCADO
	| EHomoclaveContextTipoResolucion.DESISTIDO
	| EHomoclaveContextTipoResolucion.CANCELADO
	| EHomoclaveContextTipoResolucion.AFIRMATIVA_FICTA
	| EHomoclaveContextTipoResolucion.NEGATIVA_FICTA

export type HomoclaveContextTipoEvaluacion = 'HUMANA' | 'AUTOMATIZADA'
export type HomoclaveContextTipoFirma = 'HUMANA' | 'AUTOMATIZADA'
export type HomoclaveContextOrigenDesistimiento = 'evaluacion' | 'respuesta_prevencion' | 'verificacion' | 'firma'
export type HomoclaveContextOrigenPermisos = 'evaluacion' | 'resolucion' | 'respuesta_prevencion' | 'verificacion' | 'firma'
export type HomoclaveContextTipoAccionLog =
	| 'CREACION_SOLICITANTE'
	| 'ACTUALIZACION'
	| 'BORRADO_SOLICITANTE'
	| 'FIRMA_SOLICITANTE'
	| 'EVALUACION_FIRMADA'
	| 'DESISTE_SOLICITANTE'
	| 'PERMISO_ACCESO_AGREGA'
	| 'PERMISO_ACCESO_REVOCA'
	| 'CANCELACION_EVALUACION_FIRMADA'
	| 'EVALUACION_VERIFICADA'
	| EHomoclaveContextTipoAccionLog

export enum EHomoclaveContextTipoAccionLog {
	CREACION_SOLICITANTE = 'CREACION_SOLICITANTE',
	ACTUALIZACION = 'ACTUALIZACION',
	BORRADO_SOLICITANTE = 'BORRADO_SOLICITANTE',
	FIRMA_SOLICITANTE = 'FIRMA_SOLICITANTE',
	EVALUACION_FIRMADA = 'EVALUACION_FIRMADA',
	DESISTE_SOLICITANTE = 'DESISTE_SOLICITANTE',
	PERMISO_ACCESO_AGREGA = 'PERMISO_ACCESO_AGREGA',
	PERMISO_ACCESO_REVOCA = 'PERMISO_ACCESO_REVOCA',
	CANCELACION_EVALUACION_FIRMADA = 'CANCELACION_EVALUACION_FIRMADA',
	EVALUACION_VERIFICADA = 'EVALUACION_VERIFICADA',
	RESOLUCION_FIRMADA = 'RESOLUCION_FIRMADA'
}

export type HomoclaveContextActor = 'DICTAMINADOR_PRINCIPAL' | 'DICTAMINADOR_SECUNDARIO' | 'AUXILIAR'
export type HomoclaveContexPermissionOperations = 'READ' | 'UPDATE' | 'DELETE' | 'CREATE' | EHomoclaveContexPermissionOperations

export enum EHomoclaveContexPermissionOperations {
	READ = 'READ',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	CREATE = 'CREATE'
}
export interface HomoclaveContextEvaluacionAutomatizadaFirma extends IHomoclaveContextFirma {
	algoritmo: 'ES256'
	llavePublica: string
	sha256: string
}
export type IHomoclaveContextEvaluacionAutomatizadaFirma = HomoclaveContextEvaluacionAutomatizadaFirma
export interface HomoclaveContextPermissionItem {
	id: string
	uuid: string
	grupo: string
	rol: ERolType
	actor: EActor
	recurso: string
	operaciones?: HomoclaveContexPermissionOperations[]
	inicio: number
	fin: number
	otorganteUuid: string | undefined
	otorganteRol: RolType | undefined
	revocado?: boolean | undefined
	revocanteUuid?: string | undefined
	revocanteRol?: RolType | undefined
	fechaRevocacion?: number | undefined // Fecha en la que se revocó el permiso
}
export type IHomoclaveContextPermissionItem = HomoclaveContextPermissionItem

//EVALUACIÓN

export interface IHomoclaveRegistryItemBase {
	homoclave: string
	titulo?: string
}

export interface IHomoclaveRegistryItemSinFormatos extends IHomoclaveRegistryItemBase {
	modalidades?: IHomoclaveRegistryModalidadSinFormatos[]
}
export interface IHomoclaveRegistryItem extends IHomoclaveRegistryItemBase {
	modalidades?: IHomoclaveRegistryModalidad[]
}
export enum EHomoclaveContextOrigenDesistimiento {
	EVALUACION = 'EVALUACION',
	FIRMA = 'FIRMA',
	VERIFICACION = 'VERIFICACION',
	RESPUESTA_PREVENCION = 'RESPUESTA_PREVENCION'
}
export enum EHomoclaveContextOrigenPermisos {
	EVALUACION = 'EVALUACION',
	RESOLUCION = 'RESOLUCION',
	RESPUESTA_PREVENCION = 'RESPUESTA_PREVENCION',
	VERIFICACION = 'VERIFICACION',
	FIRMA = 'FIRMA'
}
export interface HomoclaveContextMetadatos {
	grupo?: string | undefined
	solicitante?: string | undefined //uuid de la solicitud
	idSolicitud?: string | undefined //se genera al firmar la solicitud
	grupoTipoPersona?: EGrupoTipoPersona | undefined
	idTramite?: string | undefined
	fechaCreacion?: number | undefined // Fecha en la que se crea la solicitud
	fechaActualizacion?: number | undefined // Fecha de última actualización de la solicitud
	fechaInicioTramite?: number | undefined // Fecha en la que se firma la solicitud por primera vez
	fechaEvaluacionTramite?: number | undefined // Fecha en la que se rubrica el trámite por un dictaminador, se desecha por tiempo o se evalua de forma automatizada. Debe borrarse al entrar al estado de evaluación
	fechaResolucionTramite?: number | undefined // Fecha en la que se firma la autoridad una resolución dada por el dictaminador o cuando se desiste o descha una solicitud
	fechaRespuestaPrevencion?: number | undefined // Fecha en la que el solicitante firma la respuesta a la prevención. En caso de multiples prevenciones será la última firmada
	estado?: EstadoHomoclave | undefined
	tipoEvaluacion?: HomoclaveContextTipoEvaluacion
	origenDesistimiento?: EHomoclaveContextOrigenDesistimiento | undefined
	origenPermisos?: EHomoclaveContextOrigenPermisos | undefined
	contadorEvaluaciones?: number | undefined
	contadorResoluciones?: number | undefined
	contadorRespuestasPrevenciones?: number | undefined
	resolucion?: EHomoclaveContextTipoResolucion | undefined
	resultadoEvaluacion?: EHomoclaveContextResultadoEvaluacion
	fechaLimiteRespuestaPrevencion?: number | undefined
	idAutorizacion?: string
	idAutorizacionLegado?: string
	environment?: EnvironmentType
	tipoAntecedenteActivoInformacion?: EHomoclaveRegistryAntecedenteActivoInformacion
	idActivoInformacion?: string
	idVersionActivoInformacion?: string
	//antecedenteDescripcion?: string
}

export type IHomoclaveContextMetadatos = HomoclaveContextMetadatos
export interface IHomoclaveContextMetadatosBySolicitud {
	homoclave?: IHomoclaveRegistryItem
	modalidad?: Partial<IHomoclaveRegistryModalidadSinFormatos>
	metadatos: IHomoclaveContextMetadatos
}

export interface IHomoclaveContextCadenaOriginal {
	cadenaOriginal: string
}

export interface ISSOFirmaIntegrityElementsData {
	integrityElementsUri: string
	s3Bucket: string
}

export interface IHomoclaveContextFirma extends IHomoclaveContextCadenaOriginal {
	firma: string
	integrityElements?: ISSOFirmaIntegrityElementsData
}

export interface IHomoclaveContextEvaluacionHumanaFirma extends IHomoclaveContextCadenaOriginal, IHomoclaveContextFirma {
	algoritmo: 'ES256'
	firmante: string
	resolucion: EHomoclaveContextResultadoEvaluacion
	sha256: string
	idCredencial: string
	llavePublica: string
	autenticador: IFirmaAutenticador
}

//TODO: definir correctamente el contenido de evaluación en función del tipo de homoclave evaluada
export interface HomoclaveContextEvaluacionHumana {
	evaluacion?: EHomoclaveContextResultadoEvaluacion
	datos: HomoclaveContextDatos
}
export type IHomoclaveContextEvaluacionHumana = HomoclaveContextEvaluacionHumana
export interface HomoclaveContextRespuestaPrevencionFirma extends IHomoclaveContextCadenaOriginal, IHomoclaveContextFirma {
	algoritmo: 'sha256WithRSAEncryption'
	certificado: string
	numeroCertificado: string
	curpFirmante: string
	nombreFirmante: string
	sha256: string
}
export type IHomoclaveContextRespuestaPrevencionFirma = HomoclaveContextRespuestaPrevencionFirma
export interface HomoclaveContextResolucionDatos {
	resolucion?: EHomoclaveContextTipoResolucion
	type?: string
}
export type IHomoclaveContextResolucionDatos = HomoclaveContextResolucionDatos

export interface HomoclaveContextResolucionProductos {
	oficioResolucion?: string
}

export type IHomoclaveContextResolucionProductos = HomoclaveContextResolucionProductos

export interface HomoclaveContextDesistimientoFirma extends IHomoclaveContextCadenaOriginal, IHomoclaveContextFirma {
	algoritmo: 'sha256WithRSAEncryption'
	certificado: string
	numeroCertificado: string
	curpFirmante: string
	nombreFirmante: string
	sha256: string
}
export type IHomoclaveContextDesistimientoFirma = HomoclaveContextDesistimientoFirma

export interface HomoclaveContextResolucionAutomatizadaFirma extends IHomoclaveContextCadenaOriginal, IHomoclaveContextFirma {
	algoritmo: 'sha256WithRSAEncryption'
	llavePublica: string
	sha256: string
	resolucion: EHomoclaveContextTipoResolucion
}
export type IHomoclaveContextResolucionAutomatizadaFirma = HomoclaveContextResolucionAutomatizadaFirma
export interface HomoclaveContextRespuestaPrevencionDatos {
	fechaLimiteRespuestaPrevencion?: number | undefined
}
export type IHomoclaveContextRespuestaPrevencionDatos = HomoclaveContextRespuestaPrevencionDatos

export interface HomoclaveContextRespuestaPrevencionProductos {
	acuseRecepcion?: string
}
export type IHomoclaveContextRespuestaPrevencionProductos = HomoclaveContextRespuestaPrevencionProductos

export interface HomoclaveContextEvaluacionProductos {
	resultado?: HomoclaveContextResultadoEvaluacion | undefined
	cedulaEvaluacion?: string | undefined
}
export type IHomoclaveContextEvaluacionProductos = HomoclaveContextEvaluacionProductos

export enum EHomoclaveContextTipoEvaluacion {
	HUMANA = 'HUMANA',
	AUTOMATIZADA = 'AUTOMATIZADA'
}
export interface IHomoclaveContextEvaluacion {
	evaluacion?: EHomoclaveContextResultadoEvaluacion
	datos?: IHomoclaveContextDatos
	datosEvaluados?: IHomoclaveContextDatos
	productos?: HomoclaveContextEvaluacionProductos
	fechaEvaluacionTramite?: number
	firmaEvaluacionHumana?: IHomoclaveContextEvaluacionHumanaFirma
	firmaEvaluacionAutomatizada?: HomoclaveContextEvaluacionAutomatizadaFirma
	tipoEvaluacion?: EHomoclaveContextTipoEvaluacion
	comentarios?: IHomoclaveContextComentarios
}
export interface HomoclaveContextEvaluacionAutomatizadaFirma extends IHomoclaveContextCadenaOriginal, IHomoclaveContextFirma {
	algoritmo: 'ES256'
	llavePublica: string
	sha256: string
}

//RESPUESTA PREVENCIÓN
export interface HomoclaveContextRespuestaPrevencion {
	firma?: IHomoclaveContextRespuestaPrevencionFirma | undefined
	datos?: HomoclaveContextDatos
	productos?: IHomoclaveContextRespuestaPrevencionProductos
	fechaLimiteRespuestaPrevencion?: number | undefined // Fecha límite para responder a la prevención por parte del solicitante antes de que sea desecho por tiempo. Se actualiza cuando se firma la prevención por parte de la autoridad
	fechaRespuestaPrevencion?: number | undefined // Fecha en la que el solicitante firma la respuesta a la prevención.
}
export type IHomoclaveContextRespuestaPrevencion = HomoclaveContextRespuestaPrevencion

//RESOLUCIÓN

export interface HomoclaveContextResolucion {
	datos?: IHomoclaveContextDatos
	productos?: {
		oficioResolucion?: string | undefined
	}
	comentarios?: IHomoclaveContextComentarios
	resolucion?: HomoclaveContextTipoResolucion
	fechaResolucionTramite?: number | undefined // Fecha en que la autoridad firma una resolución dada por el dictaminador o cuando se desiste o descha una solicitud
	firmaDesistimiento?: IHomoclaveContextDesistimientoFirma | undefined
	firmaResolucionHumana?: IHomoclaveContextResolucionHumanaFirma | undefined
	firmaResolucionAutomatizada?: IHomoclaveContextResolucionAutomatizadaFirma | undefined
}
export type IHomoclaveContextResolucion = HomoclaveContextResolucion

export interface HomoclaveContextResolucionHumanaFirma extends IHomoclaveContextCadenaOriginal, IHomoclaveContextFirma {
	algoritmo: 'sha256WithRSAEncryption'
	certificado: string
	numeroCertificado: string
	curpFirmante: string
	nombreFirmante: string
	sha256: string
	resolucion: EHomoclaveContextTipoResolucion
}
export type IHomoclaveContextResolucionHumanaFirma = HomoclaveContextResolucionHumanaFirma

//FIRMADO

export interface IHomoclaveContextFaseFirmable {
	solicitud: IHomoclaveContextSolicitud
	evaluacion: IHomoclaveContextEvaluacion
	respuesta_prevencion: HomoclaveContextRespuestaPrevencion
	resolucion: HomoclaveContextResolucion
}

export interface IHomoclaveContextFaseFirmableDatos {
	solicitud: IHomoclaveContextSolicitud['datos']
	evaluacion: IHomoclaveContextEvaluacion['datos']
	respuesta_prevencion: HomoclaveContextRespuestaPrevencion['datos']
	resolucion: HomoclaveContextResolucion['datos']
}

//3.- CONFIGURACIÓN DE TRÁMITE
export interface HomoclaveRegistryTramite {
	homoclave: HomoclaveRegistryItem
	modalidad: HomoclaveRegistryModalidad
}
export interface HomoclaveContextSolicitudProductos {
	solicitudFirmada?: string
	acuseRecepcion?: string
	constancia?: string
}
export type IHomoclaveContextSolicitudProductos = HomoclaveContextSolicitudProductos

export interface IHomoclaveContextMetadatosSolicitud {
	grupoTipoPersona: string
	personaFisica?: IDataAccessTokenSolicitanteMetaData
	personaMoral?: IDataAccessTokenSolicitanteMetaData
}
export interface IHomoclaveContextSolicitud {
	firma?: IHomoclaveContextSolicitudFirma | undefined
	datos?: IHomoclaveContextDatos
	productos?: HomoclaveContextSolicitudProductos
	metaDatos?: IHomoclaveContextMetadatosSolicitud
}
export interface HomoclaveContextLogItem {
	fecha: number
	accion: EHomoclaveContextTipoAccionLog
	grupo: string
	uuid: string
	role: string
}

export interface IHomoclaveModalidadMetadatosResponse {
	homoclave?: IHomoclaveRegistryItem
	modalidad?: IHomoclaveRegistryModalidadSinFormatos
	metadatos: IHomoclaveContextMetadatos
}

export type IHomoclaveContextLogItem = HomoclaveContextLogItem

/**
 * Nodo de la máquina de estados que representa el trámite de una solicitud de homoclave.
 * @author tirsomartinezreyes
 * @export
 * @interface IHomoclaveContext
 * @typedef {IHomoclaveContext}
 * @extends {INodoRecurso}
 */
export interface IHomoclaveContext extends INodoRecurso {
	homoclave?: IHomoclaveRegistryItem | undefined
	modalidad?: Partial<IHomoclaveRegistryModalidad> | undefined
	debug?: boolean
	metadatos: Partial<IHomoclaveContextMetadatos>

	/**
	 * @property {IHomoclaveContextSolicitud} solicitud
	 * @description Nodo único que contiene la información de la etapa de solicitud de homoclave. (Solicitud)
	 */
	solicitud?: Partial<IHomoclaveContextSolicitud>
	/**
	 * @property {IHomoclaveContextEvaluacion[]} evaluacion
	 * @description Nodo de tipo array que contiene la información de cada una de las etapas de evaluación por las que ha pasado la solicitud. (Dictaminación, evaluación )
	 */
	evaluacion?: IHomoclaveContextEvaluacion[] | undefined
	/**
	 * @property {HomoclaveContextRespuestaPrevencion[]} respuesta_prevencion
	 * @description Nodo de tipo array que contiene la información de cada una de las etapas de respuesta a prevención por las que ha pasado la solicitud. (Respuesta a prevención)
	 */
	respuesta_prevencion?: IHomoclaveContextRespuestaPrevencion[] | undefined

	/**
	 * @property {HomoclaveContextResolucion[]} resolucion
	 * @description Nodo de tipo array que contiene la información de cada una de las etapas de resolución por las que ha pasado la solicitud. Ya se dictamino la solicitud y se marco como autorizada, desechada, prevenida, revocada o desistida. (Resolución)
	 */
	resolucion?: Partial<IHomoclaveContextResolucion>[] | undefined
	permisos?: IHomoclaveContextPermissionItem[]
	log?: IHomoclaveContextLogItem[] | undefined
	versionesFormatos: IPugTemplateVersion[]
	versionesFormatosExtra?: IPugTemplateVersion[]
}

export interface IHomoclaveConfigServices {
	doGenerarSolicitud?: InvokeCreator<unknown, AnyEventObject>
	doEditarSolicitud?: InvokeCreator<unknown, AnyEventObject>
	doBorrarSolicitud?: InvokeCreator<unknown, AnyEventObject>
	doDesistirSolicitud?: InvokeCreator<unknown, AnyEventObject>
	doEditarEvaluacion?: InvokeCreator<unknown, AnyEventObject>
	doEnviarSolicitudFirmada?: InvokeCreator<unknown, AnyEventObject>
	doEnviarEvaluacionFirmada?: InvokeCreator<unknown, AnyEventObject>
	doEditarRespuestaPrevencion?: InvokeCreator<unknown, AnyEventObject>
	doEnviarRespuestaPrevencionFirmada?: InvokeCreator<unknown, AnyEventObject>
	doEnviarResolucionFirmada?: InvokeCreator<unknown, AnyEventObject>
	doActualizarPermisos?: InvokeCreator<unknown, AnyEventObject>
	doCancelarEvaluacion?: InvokeCreator<unknown, AnyEventObject>
	doCancelarVerificacion?: InvokeCreator<unknown, AnyEventObject>
	doSolicitarFirma?: InvokeCreator<unknown, AnyEventObject>
}

export interface IHomoclaveConfigAssignations {
	setDebug?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setContext?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenDesistimientoEvaluacion?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenDesistimientoRespuestaPrevencion?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenDesistimientoVerificacion?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenDesistimientoFirma?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setSolicitudSignature?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setDesistimientoSignature?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setRespuestaPrevencionSignature?: (context: IHomoclaveContext, event: any) => IHomoclaveContext

	setOrigenPermisosEvaluacion?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenPermisosRespuestaPrevencion?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenPermisosResolucion?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenPermisosVerificacion?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
	setOrigenPermisosFirma?: (context: IHomoclaveContext, event: any) => IHomoclaveContext
}

export interface IHomoclaveConfigGuards {
	isEvaluacionAutomatizada?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isEvaluacionHumana?: (context: IHomoclaveContext, event: AnyEventObject) => boolean

	isResolucionAutorizado?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isResolucionDesechado?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isResolucionDesechadoExpirado?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isResolucionPrevenido?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isResolucionRevocado?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isResolucionDesistido?: (context: IHomoclaveContext, event: AnyEventObject) => boolean

	isPrevioEstadoSolicitud?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isPrevioEstadoEvaluacion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isPrevioEstadoRespuestaPrevencion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isPrevioEstadoResolucion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isPrevioEstadoFirma?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isPrevioEstadoVerificacion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean

	isOrigenDesistimientoEvaluacion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isOrigenDesistimientoVerificacion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isOrigenDesistimientoFirma?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isOrigenDesistimientoRespuestaPrevencion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean

	isOrigenPermisosRespuestaPrevencion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isOrigenPermisosEvaluacion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isOrigenPermisosVerificacion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isOrigenPermisosFirma?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
	isOrigenPermisosResolucion?: (context: IHomoclaveContext, event: AnyEventObject) => boolean
}

/**
 * RESPUESTA DE SERVICIOS
 * Tipado de las respuestas de los servicios que se invocan en la FSM.
 */

export interface HomoclaveServiceError {
	error: string
}
export enum EstadoHomoclave {
	evaluacion = 'evaluacion',
	respuesta_prevencion = 'respuesta_prevencion',
	verificacion = 'verificacion',
	firma = 'firma',
	resolucion = 'resolucion',
	borrado = 'borrado',
	solicitud = 'solicitud'
}
export enum EHomoclaveBanderasModificaciones {
	CONSTANCIA = 'CONSTANCIA',
	SELECCION_AI = 'SELECCION_AI',
	ESTRUCTURA_MEDICAMENTO = 'ESTRUCTURA_MEDICAMENTO',
	MODIFICACIONES = 'MODIFICACIONES',
	INFO_SOLICITANTE = 'INFO_SOLICITANTE',
	PAGO = 'PAGO'
}
export function prepareContext(context: Partial<IHomoclaveContext>): IHomoclaveContext {
	if (context.metadatos == undefined) {
		context.metadatos = {
			resultadoEvaluacion: undefined
		}
	}

	if (context.metadatos.contadorEvaluaciones == undefined) {
		context.metadatos.contadorEvaluaciones = 0
	}

	if (context.metadatos.contadorRespuestasPrevenciones == undefined) {
		context.metadatos.contadorRespuestasPrevenciones = 0
	}

	if (context.metadatos.contadorResoluciones == undefined) {
		context.metadatos.contadorResoluciones = 0
	}

	if (!context.solicitud) {
		context.solicitud = {}
	}

	if (!context.solicitud.productos) {
		context.solicitud.productos = {}
	}

	if (context.evaluacion == undefined) {
		context.evaluacion = []
	}
	if (context.respuesta_prevencion == undefined) {
		context.respuesta_prevencion = []
	}

	if (context.resolucion == undefined) {
		context.resolucion = []
	}
	if (!context.log) {
		context.log = []
	}

	if (!context.permisos) {
		context.permisos = []
	}

	return context as IHomoclaveContext
}
