/**
 * @name AIInsumoSaludReglasDeNegocio.ts
 * @description Validación de las reglas de negocio de AIInsumoSalud
 * @version 1.0.4
 *
 * @changelog 1.0.4 - 11/nov/24 - @tirsomartinezreyes - Se agrega EHomoclaveRegistryModalidadInsumoSaludAccion.CESION a la lista de acciones que requieren validar el tipo de insumo
 * @changelog 1.0.3 - 05/nov/24 - @tirsomartinezreyes - Se agrega la excepción de permitir modificaciones de medicamentos controlados a fórmulas enterales y huérfanos
 * @changelog 1.0.2 - 29/oct/24 - @tirsomartinezreyes - Se corrige alerta de medicamento controlados para modificaciones
 * @changelog 1.0.1 - 25/oct/24 - @tirsomartinezreyes - Se agrega lista blance de roles a los que se omiten reglas de negocio
 * @changelog 1.0.0 - 23/oct/24 - @tirsomartinezreyes - Versión inicial del archivo
 */

import { EHomoclaveRegistryModalidadInsumoSaludAccion } from 'cofepris-typesafe/Types/Homoclave'
import { getDateByMillis } from 'cofepris-typesafe/Modules/Dates'
import { IFechaAdministrativaSolicitud, EFechaAdministrativaOperacion, calcularFechaAdministrativa } from 'cofepris-typesafe/Modules/FechasAdministrativas'
import {
	IAIInsumoSalud,
	EAIInsumoSaludTipo,
	EAIInsumoSaludEstadoAutorizacion,
	EAIInsumoSaludTipoAutorizacion,
	EAIInsumoSaludAlertaSanitariaEstado,
	EAIInsumoSaludAlertaSanitariaNivel,
	obtenerDiasNaturalesMinimosSolicitudProrroga
} from './AIInsumoSalud'
import { ERolType } from 'cofepris-typesafe/Types/Roles'
import { EMedicamentoTipo } from './Medicamento'
import { EDispositivoClaseRIS83 } from './Dispositivo'
import { EModificacionesMedicamentoTipo } from 'cofepris-typesafe/Modules/ModificacionesMedicamentos'
import { EModificacionDispositivosTipo } from 'cofepris-typesafe/Modules/ModificacionesDispositivos'

export interface IAIInsumoSaludValidarReglasDeNegocioInput {
	rol: ERolType
	insumoSalud: IAIInsumoSalud
	accion: EHomoclaveRegistryModalidadInsumoSaludAccion
	tipoInsumoSalud: EAIInsumoSaludTipo
	diasNoHabiles: string | number[]
	prorrogaRetroactiva?: boolean
	tiposMedicamento?: EMedicamentoTipo[]
	clasesDispositivoRIS83?: EDispositivoClaseRIS83[]
	tipoModificacionesMedicamento?: EModificacionesMedicamentoTipo[]
	tipoModificacionesDispositivo?: EModificacionDispositivosTipo[]
}

const listaBlancaRolesIgnorarReglasDeNegocio = [ERolType.ADMINISTRADOR, ERolType.EDITOR_DE_ACTIVOS]

/**
 * @name insumoSaludValidarReglasDeNegocio
 * @description Valida las reglas de negocio de un insumo de salud
 */
export const insumoSaludValidarReglasDeNegocio = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []

	if (listaBlancaRolesIgnorarReglasDeNegocio.includes(args.rol)) {
		return respuesta
	}
	try {
		respuesta.push(...validarFechaVigencia(args))
		respuesta.push(...validarInsumoCompatible(args))
		switch (args.accion) {
			case EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION:
				//No hay reglas de negocio para autorización
				break
			case EHomoclaveRegistryModalidadInsumoSaludAccion.RECONOCIMIENTO_HUERFANO:
				//No hay reglas de negocio para reconocimiento huérfano
				break
			case EHomoclaveRegistryModalidadInsumoSaludAccion.REVOCACION:
				respuesta.push(...validarAccionRevocacion(args))
				break
			case EHomoclaveRegistryModalidadInsumoSaludAccion.CESION:
				respuesta.push(...validarAccionCesion(args))
				break
			case EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA:
				respuesta.push(...validarAccionPrimeraProrroga(args))
				break
			case EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA:
				respuesta.push(...validarAccionSegundaProrroga(args))
				break
			case EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION:
				respuesta.push(...validarAccionModificacion(args))
				break
			default:
				//No hay reglas de negocio para otras acciones
				break
		}
	} catch (e) {
		console.error(e)
		respuesta.push('Error al procesar reglas de negocio')
	}
	return respuesta
}

/**
 * @name validarFechaVigencia
 * @description Valida que la fecha de vigencia de la autorización del insumo de salud esté presente
 */
const validarFechaVigencia = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	return args.insumoSalud.vigenciaFinAutorizacion ? [] : ['No se cuenta con la fecha de vigencia de la autorización del insumo de salud']
}

/**
 *
 * @name validarInsumoCompatible
 * @description Valida que el insumo de salud sea compatible con los tipos de medicamento o clase de dispositivo permitidos
 */
const validarInsumoCompatible = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	if (
		[
			EHomoclaveRegistryModalidadInsumoSaludAccion.RECONOCIMIENTO_HUERFANO,
			EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION,
			EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA,
			EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA,
			EHomoclaveRegistryModalidadInsumoSaludAccion.CESION
		].includes(args.accion)
	) {
		if (args.tipoInsumoSalud == EAIInsumoSaludTipo.MEDICAMENTO) {
			const AIMedicamentoVersionActual = args.insumoSalud.versiones[args.insumoSalud.versionActual - 1].insumoSalud.medicamento
			const tipoMedicamento = AIMedicamentoVersionActual?.tipo ? EMedicamentoTipo[AIMedicamentoVersionActual?.tipo] : undefined
			if (!args.tiposMedicamento?.includes(tipoMedicamento as EMedicamentoTipo)) {
				respuesta.push(`El tipo de medicamento (${tipoMedicamento}) no es permitido para la modalidad seleccionada (${args.tiposMedicamento?.join()})`)
			}
		}

		if (args.tipoInsumoSalud == EAIInsumoSaludTipo.DISPOSITIVO) {
			const claseDispositivo = args.insumoSalud.resumenPublico.claseDispositivoRIS83 ?? undefined
			if (!args.clasesDispositivoRIS83?.includes(claseDispositivo as EDispositivoClaseRIS83)) {
				respuesta.push(`La clase de dispositivo (${claseDispositivo}) no es permitido para la modalidad seleccionada (${args.clasesDispositivoRIS83?.join()})`)
			}
		}
	}
	return respuesta
}

/**
 * @name validarAccionRevocacion
 * @description Valida las reglas de negocio para la acción de revocación
 */
const validarAccionRevocacion = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	const estadosAutorizacionValidos = [EAIInsumoSaludEstadoAutorizacion.AUTORIZADO]
	if (args.accion == EHomoclaveRegistryModalidadInsumoSaludAccion.REVOCACION) {
		if (!estadosAutorizacionValidos.includes(args.insumoSalud.estadoAutorizacion)) {
			respuesta.push('Estado de autorización no válido para revocación: ' + args.insumoSalud.estadoAutorizacion)
		}
	}
	return respuesta
}

/**
 * @name validarAccionCesion
 * @description Valida las reglas de negocio para la acción de cesión
 * */

const validarAccionCesion = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	const estadosAutorizacionValidos = [EAIInsumoSaludEstadoAutorizacion.AUTORIZADO]
	const now = Date.now()
	if (args.accion == EHomoclaveRegistryModalidadInsumoSaludAccion.CESION) {
		if (!estadosAutorizacionValidos.includes(args.insumoSalud.estadoAutorizacion)) {
			respuesta.push('Estado de autorización no válido para cesión: ' + args.insumoSalud.estadoAutorizacion)
		}
		if (args.insumoSalud.vigenciaFinAutorizacion && args.insumoSalud.vigenciaFinAutorizacion < now && !args.insumoSalud.prorrogaEnEvaluacion) {
			respuesta.push('La vigencia del insumo expiró: ' + getDateByMillis(args.insumoSalud.vigenciaFinAutorizacion, true))
		}
	}
	return respuesta
}

/**
 * @name validarAccionModificacion
 * @description Valida las reglas de negocio para las modificaciones
 * */

const validarAccionModificacion = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	const estadosAutorizacionValidos = [EAIInsumoSaludEstadoAutorizacion.AUTORIZADO]
	const now = Date.now()
	if (args.accion == EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION) {
		if (!estadosAutorizacionValidos.includes(args.insumoSalud.estadoAutorizacion)) {
			respuesta.push('Estado de autorización no válido para modificación: ' + args.insumoSalud.estadoAutorizacion)
		}

		if (args.insumoSalud.vigenciaFinAutorizacion && args.insumoSalud.vigenciaFinAutorizacion < now && !args.insumoSalud.prorrogaEnEvaluacion) {
			respuesta.push('La vigencia del insumo expiró: ' + getDateByMillis(args.insumoSalud.vigenciaFinAutorizacion, true))
		}

		if (args.tipoInsumoSalud == EAIInsumoSaludTipo.MEDICAMENTO) {
			respuesta.push(...validarAccionModificacionMedicamento(args))
		}

		if (args.tipoInsumoSalud == EAIInsumoSaludTipo.DISPOSITIVO) {
			respuesta.push(...validarAccionModificacionDispositivo(args))
		}
	}
	return respuesta
}

/**
 *
 * @name validarAccionModificacionMedicamento
 * @description Valida las reglas de negocio para las modificaciones de medicamentos
 */
const validarAccionModificacionMedicamento = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	if (args.tipoInsumoSalud == EAIInsumoSaludTipo.MEDICAMENTO) {
		const AIMedicamentoVersionActual = args.insumoSalud.versiones[args.insumoSalud.versionActual - 1].insumoSalud.medicamento

		if (!args.tipoModificacionesMedicamento?.includes(EModificacionesMedicamentoTipo.MAYOR)) {
			if (AIMedicamentoVersionActual?.estupefacientePsicotropicoPrecursor) {
				if (![EMedicamentoTipo.FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA, EMedicamentoTipo.HUERFANO].includes(AIMedicamentoVersionActual.tipo))
					respuesta.push(
						'Todas las MCRS de medicamentos controlados (fracciones I, II y III del art. 226 de la LGS o medicamentos que sean o contengan estupefacientes, psicotrópicos; incluyendo sal, derivado o precursor) se clasifican como MAYORES'
					)
			}
			if (args.insumoSalud.alertasSanitarias instanceof Array) {
				args.insumoSalud.alertasSanitarias.forEach(alerta => {
					if (alerta.estadoAlertaSanitaria == EAIInsumoSaludAlertaSanitariaEstado.ACTIVA) {
						if (alerta.nivelAlertaSanitaria == EAIInsumoSaludAlertaSanitariaNivel.PELIGRO) {
							respuesta.push(
								`El Insumo para la salud cuenta con alerta sanitaria activa de riesgo: ${alerta.idAlertaSanitaria} ${alerta.urlAlertaSanitaria}, por lo que solo pueden solicitarse modificaciones mayores`
							)
						}
					}
				})
			}
		}
	}
	return respuesta
}
/**
 *
 * @name validarAccionModificacionDispositivo
 * @description Valida las reglas de negocio para las modificaciones de dispositivos
 */
const validarAccionModificacionDispositivo = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	if (args.tipoInsumoSalud == EAIInsumoSaludTipo.DISPOSITIVO) {
		if (!args.tipoModificacionesDispositivo?.includes(EModificacionDispositivosTipo.TECNICAS)) {
			if (args.insumoSalud.alertasSanitarias instanceof Array) {
				args.insumoSalud.alertasSanitarias.forEach(alerta => {
					if (alerta.estadoAlertaSanitaria == EAIInsumoSaludAlertaSanitariaEstado.ACTIVA) {
						if (alerta.nivelAlertaSanitaria == EAIInsumoSaludAlertaSanitariaNivel.PELIGRO) {
							respuesta.push(
								`El Insumo para la salud cuenta con alerta sanitaria activa de riesgo: ${alerta.idAlertaSanitaria} ${alerta.urlAlertaSanitaria}, por lo que solo pueden solicitarse modificaciones administrativas`
							)
						}
					}
				})
			}
		}
	}
	return respuesta
}

/**
 * @name validarAccionCualquierProrroga
 * @description Valida las reglas de negocio para la acción de prórroga (primera y segunda)
 * */

const validarAccionCualquierProrroga = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	const estadosAutorizacionValidos = [EAIInsumoSaludEstadoAutorizacion.AUTORIZADO]
	const now = Date.now()
	if ([EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA, EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA].includes(args.accion)) {
		if (!estadosAutorizacionValidos.includes(args.insumoSalud.estadoAutorizacion)) {
			respuesta.push('Estado de autorización no válido para prórroga: ' + args.insumoSalud.estadoAutorizacion)
		}

		if (args.insumoSalud.vigenciaFinAutorizacion && args.insumoSalud.vigenciaFinAutorizacion < now && !args.insumoSalud.prorrogaEnEvaluacion) {
			respuesta.push('La vigencia del insumo expiró: ' + getDateByMillis(args.insumoSalud.vigenciaFinAutorizacion, true))
		}

		if (args.insumoSalud.prorrogaEnEvaluacion) {
			respuesta.push(...validarAccionCualquierProrrogaEnEvaluacion(args))
		}

		respuesta.push(...validarAccionCualquierProrrogaFechaLimite(args))
	}
	return respuesta
}

/**
 * @name validarAccionCualquierProrrogaEnEvaluacion
 * @description Valida las reglas de negocio para la acción de prórroga (primera y segunda) que esta en evaluación
 * */

const validarAccionCualquierProrrogaEnEvaluacion = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	if ([EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA, EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA].includes(args.accion)) {
		if (args.insumoSalud.prorrogaEnEvaluacion) {
			if (args.prorrogaRetroactiva) {
				if (!args.insumoSalud.prorrogaRetroactiva) {
					respuesta.push('Este insumo de salud no puede ingresar prórroga retroactiva')
				}
			} else if (args.insumoSalud.prorrogaRetroactiva) {
				respuesta.push(
					`El insumo de salud cuenta actualmente con una prórroga en evaluación, pero puede ingresar una ${args.insumoSalud.prorrogado ? 'segunda o subsecuente' : 'primer'} prórroga retroactiva`
				)
			} else {
				respuesta.push('El insumo de salud cuenta actualmente con una prórroga en evaluación')
			}
		}
	}
	return respuesta
}

/**
 * @name validarAccionCualquierProrrogaFechaLimite
 * @description Valida las reglas de negocio para la acción de prórroga (primera y segunda) en fecha límite
 * */

const validarAccionCualquierProrrogaFechaLimite = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	const now = Date.now()
	if ([EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA, EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA].includes(args.accion)) {
		const diasNaturalesMinimosProrroga = obtenerDiasNaturalesMinimosSolicitudProrroga(
			args.insumoSalud.tipoInsumoSalud,
			args.insumoSalud.versiones[args.insumoSalud.versionActual - 1].insumoSalud.medicamento?.tipo
		)

		const solicitudFecha: IFechaAdministrativaSolicitud = {
			fechaEntrada: args.insumoSalud.vigenciaFinAutorizacion,
			operacion: EFechaAdministrativaOperacion.RESTAR_DIAS_NATURALES,
			cantidad: diasNaturalesMinimosProrroga,
			diasNoHabiles: args.diasNoHabiles
		}
		const limiteSolicitudProrroga = calcularFechaAdministrativa(solicitudFecha)
		if (args.insumoSalud.vigenciaFinAutorizacion && now > limiteSolicitudProrroga.fechaResultado && !args.insumoSalud.prorrogaEnEvaluacion) {
			if (args.insumoSalud.tipoAutorizacion == EAIInsumoSaludTipoAutorizacion.REGISTRO_SANITARIO) {
				respuesta.push(
					`La solicitud de prórroga debe realizarse ${diasNaturalesMinimosProrroga} días naturales antes de la fecha de vencimiento del registro sanitario. La fecha límite expiró el ${getDateByMillis(
						limiteSolicitudProrroga.fechaResultado
					)}`
				)
			}
		}
	}
	return respuesta
}

/**
 * @name validarAccionPrimeraProrroga
 * @description Valida las reglas de negocio para la acción de primera prórroga
 * */

const validarAccionPrimeraProrroga = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	if (args.accion == EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA) {
		respuesta.push(...validarAccionCualquierProrroga(args))
		if (
			args.insumoSalud.prorrogado &&
			args.insumoSalud.tipoAutorizacion == EAIInsumoSaludTipoAutorizacion.REGISTRO_SANITARIO &&
			args.insumoSalud.versiones[args.insumoSalud.versionActual - 1].insumoSalud.medicamento?.tipo != EMedicamentoTipo.FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA
		) {
			respuesta.push('El insumo ya cuenta con una primer prórroga')
		}
	}
	return respuesta
}

/**
 * @name validarAccionSegundaProrroga
 * @description Valida las reglas de negocio para la acción de segunda prórroga y subsecuentes
 * */

const validarAccionSegundaProrroga = function (args: IAIInsumoSaludValidarReglasDeNegocioInput): string[] {
	const respuesta: string[] = []
	if (args.accion == EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA) {
		respuesta.push(...validarAccionCualquierProrroga(args))
		if (!args.insumoSalud.prorrogado) {
			respuesta.push('El insumo no cuenta con una primer prórroga')
		}
		if (args.insumoSalud.alertasSanitarias instanceof Array) {
			args.insumoSalud.alertasSanitarias.forEach(alerta => {
				if (alerta.estadoAlertaSanitaria == EAIInsumoSaludAlertaSanitariaEstado.ACTIVA) {
					if (alerta.nivelAlertaSanitaria == EAIInsumoSaludAlertaSanitariaNivel.PELIGRO) {
						respuesta.push(
							`El Insumo para la salud cuenta con alerta sanitaria activa de nivel ${alerta.nivelAlertaSanitaria} ${alerta.idAlertaSanitaria}. No puede solicitarse constancia de segunda prórroga. Debe ingresar una Modificación Mayor para corregir la alerta sanitaria primero.`
						)
					}
				}
			})
		}
	}
	return respuesta
}
