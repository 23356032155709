import { useUserStore } from 'src/store/auth'
import { Service } from 'typedi'
import { IGetAccessToken } from './grapqhlApi.service'

@Service()
export default class GetAccessTokenService implements IGetAccessToken {
	private static JWT: string
	getUserAccessToken(): string {
		const jwt = useUserStore()?.User?.AccessJWT
		if (GetAccessTokenService.JWT != jwt) {
			GetAccessTokenService.JWT = jwt
			//console.log('TOKEN RENOVADO', jwt)
		}
		return GetAccessTokenService.JWT
	}
}
