/**
 * @name InsumoSalud
 * @author @tirsomartinezreyes
 * @version 0.0.12
 *
 * @description Estructura de una versión específica del Activo de información de un insumo para la salud
 */

import {
	IDispositivo //Interfaz de Insumo de salud de Dispositivo
} from './Dispositivo' // Proporciona interfaces y enumeraciones de Insumos para la salud

import {
	IMedicamento //Interfaz de Insumo de salud de Medicamento
} from './Medicamento' // Proporciona interfaces y enumeraciones de Insumos para la salud

/**
 * @name IInsumoSalud
 * @description Estructura de un insumo para la salud
 */
export interface IInsumoSalud {
	denominacionDistintiva: string //Nombre comercial del producto
	denominacionGenerica: string //Nombre genérico del producto
	fundamentoLegal?: string //Fundamento legal de la autorización del insumo para la salud
	acuerdoEquivalencia?: EInsumoSaludAcuerdoEquivalencia //Acuerdo de equivalencia por el cual se registró el insumo para la salud
	titular: IInsumoSaludSujeto //Persona física u organización que tiene la titularidad de la autorización del insumo para la salud
	representante?: IInsumoSaludSujeto //Persona física o moral mexicana con RFC que representa al titular extranjero del insumo para la salud, efectuando los trámites relacionados con la solicitud y/o autorización
	medicamento?: IMedicamento //Datos del medicamento, en caso de ser un insumo de salud de tipo Medicamento
	dispositivo?: IDispositivo //Datos del dispositivo, en caso de ser un insumo de salud de tipo Dispositivo
	observacionesAutorizacion?: string[] //Notas adicionales sobre la autorización del insumo para la salud (aparecen en el oficio de autorización)
}

/**
 * @name IInsumoSaludSujeto
 * @description Sujeto genérico relacionado con el insumo para la salud
 */
export interface IInsumoSaludSujeto {
	nombre: string
	tipo?: EInsumoSaludTipoSujeto //Tipo específico de sujeto
	rfc?: string //Solo para el caso de Nacionales y Titulares o Representantes
	direccion?: string
	paisOrigen?: EInsumoSaludPaisOrigen
	notaAutorizacion?: string //Nota adicional sobre la autorización del insumo para la salud (aparece en el oficio de autorización)
}

/**
 * @name EInsumoSaludAcuerdoEquivalencia
 * @description Enumeración de acuerdos de equivalencia por los cuales se ingreso la solicitud de autorización del insumo para la salud
 */
export enum EInsumoSaludAcuerdoEquivalencia {
	FDA = 'FDA',
	HC = 'HC',
	MHLW = 'MHLW',
	OTROS = 'OTROS',
	NO_APLICA = 'NO_APLICA'
}

/**
 * @name EInsumoSaludTipoSujeto
 * @description Enumeración de tipos de sujetos relacionados con el insumo para la salud en la cadena de suministro
 */
export enum EInsumoSaludTipoSujeto {
	TITULAR = 'TITULAR', //Persona física u organización que tiene la titularidad de la autorización del insumo para la salud (Puede ser extranjera o nacional)
	REPRESENTANTE = 'REPRESENTANTE', //Persona física o moral mexicana con RFC que representa al titular extranjero del insumo para la salud (Exclusivamente medicamento), efectuando los trámites relacionados con la solicitud y/o autorización
	FABRICANTE = 'FABRICANTE', //Persona física u organización que produce el insumo para la salud
	PROCESADOR = 'PROCESADOR', //Persona física u organización que participa en el proceso de producción del insumo para la salud (p,e equilibrando el PH, microgranulando la sales del fármaco, etc)
	LICENCIANTE = 'LICENCIANTE', //Persona física u organización que otorga la licencia de uso de la marca comercial, derechos de autor, derechos de patente, etc
	LICENCIATARIO = 'LICENCIATARIO', //Persona física u organización que otorga la licencia de uso de la marca comercial, derechos de autor, derechos de patente, etc
	IMPORTADOR = 'IMPORTADOR', //Persona física u organización que trae el insumo para la salud desde el país de fabricación a méxico desde el extranjero
	DISTRIBUIDOR = 'DISTRIBUIDOR', //Persona física u organización que se encarga de distribuir el insumo para la salud en el mercado
	ACONDICIONADOR_PRIMARIO = 'ACONDICIONADOR_PRIMARIO', //Persona física u organización que se encarga de acondicionar el insumo para la salud en su presentación primaria(en contacto directo con el producto)
	ACONDICIONADOR_SECUNDARIO = 'ACONDICIONADOR_SECUNDARIO', //Persona física u organización que se encarga de acondicionar el insumo para la salud en su presentación secundaria (envase que contiene el envase primario)
	ALMACEN = 'ALMACEN', //Persona física u organización que se encarga de almacenar el insumo para la salud
	MAQUILADOR = 'MAQUILADOR', //Persona física u organización que realiza la maquila del insumo para la salud (Difiere del fabricante porque puede ser el mismo o un tercero quien lo construya)
	UNIDAD_FARMACOVIGILANCIA = 'UNIDAD_FARMACOVIGILANCIA' //Persona física u organización que se encarga de la farmacovigilancia del insumo para la salud
}

/**
 * @name EInsumoSaludPaisOrigen
 * @description Enumeración de países de origen de los sujetos relacionados con el insumo para la salud
 * @reference Obtendo el 23 de marzo de 2024 de https://omawww.sat.gob.mx/informacion_fiscal/factura_electronica/Paginas/Catalogos_comercio_exterior.aspx
 */
export enum EInsumoSaludPaisOrigen {
	AFG = 'AFG',
	ALA = 'ALA',
	ALB = 'ALB',
	DEU = 'DEU',
	AND = 'AND',
	AGO = 'AGO',
	AIA = 'AIA',
	ATA = 'ATA',
	ATG = 'ATG',
	SAU = 'SAU',
	DZA = 'DZA',
	ARG = 'ARG',
	ARM = 'ARM',
	ABW = 'ABW',
	AUS = 'AUS',
	AUT = 'AUT',
	AZE = 'AZE',
	BHS = 'BHS',
	BGD = 'BGD',
	BRB = 'BRB',
	BHR = 'BHR',
	BEL = 'BEL',
	BLZ = 'BLZ',
	BEN = 'BEN',
	BMU = 'BMU',
	BLR = 'BLR',
	MMR = 'MMR',
	BOL = 'BOL',
	BIH = 'BIH',
	BWA = 'BWA',
	BRA = 'BRA',
	BRN = 'BRN',
	BGR = 'BGR',
	BFA = 'BFA',
	BDI = 'BDI',
	BTN = 'BTN',
	CPV = 'CPV',
	KHM = 'KHM',
	CMR = 'CMR',
	CAN = 'CAN',
	QAT = 'QAT',
	BES = 'BES',
	TCD = 'TCD',
	CHL = 'CHL',
	CHN = 'CHN',
	CYP = 'CYP',
	COL = 'COL',
	COM = 'COM',
	PRK = 'PRK',
	KOR = 'KOR',
	CIV = 'CIV',
	CRI = 'CRI',
	HRV = 'HRV',
	CUB = 'CUB',
	CUW = 'CUW',
	DNK = 'DNK',
	DMA = 'DMA',
	ECU = 'ECU',
	EGY = 'EGY',
	SLV = 'SLV',
	ARE = 'ARE',
	ERI = 'ERI',
	SVK = 'SVK',
	SVN = 'SVN',
	ESP = 'ESP',
	USA = 'USA',
	EST = 'EST',
	ETH = 'ETH',
	PHL = 'PHL',
	FIN = 'FIN',
	FJI = 'FJI',
	FRA = 'FRA',
	GAB = 'GAB',
	GMB = 'GMB',
	GEO = 'GEO',
	GHA = 'GHA',
	GIB = 'GIB',
	GRD = 'GRD',
	GRC = 'GRC',
	GRL = 'GRL',
	GLP = 'GLP',
	GUM = 'GUM',
	GTM = 'GTM',
	GUF = 'GUF',
	GGY = 'GGY',
	GIN = 'GIN',
	GNB = 'GNB',
	GNQ = 'GNQ',
	GUY = 'GUY',
	HTI = 'HTI',
	HND = 'HND',
	HKG = 'HKG',
	HUN = 'HUN',
	IND = 'IND',
	IDN = 'IDN',
	IRQ = 'IRQ',
	IRN = 'IRN',
	IRL = 'IRL',
	BVT = 'BVT',
	IMN = 'IMN',
	CXR = 'CXR',
	NFK = 'NFK',
	ISL = 'ISL',
	CYM = 'CYM',
	CCK = 'CCK',
	COK = 'COK',
	FRO = 'FRO',
	SGS = 'SGS',
	HMD = 'HMD',
	FLK = 'FLK',
	MNP = 'MNP',
	MHL = 'MHL',
	PCN = 'PCN',
	SLB = 'SLB',
	TCA = 'TCA',
	UMI = 'UMI',
	VGB = 'VGB',
	VIR = 'VIR',
	ISR = 'ISR',
	ITA = 'ITA',
	JAM = 'JAM',
	JPN = 'JPN',
	JEY = 'JEY',
	JOR = 'JOR',
	KAZ = 'KAZ',
	KEN = 'KEN',
	KGZ = 'KGZ',
	KIR = 'KIR',
	KWT = 'KWT',
	LAO = 'LAO',
	LSO = 'LSO',
	LVA = 'LVA',
	LBN = 'LBN',
	LBR = 'LBR',
	LBY = 'LBY',
	LIE = 'LIE',
	LTU = 'LTU',
	LUX = 'LUX',
	MAC = 'MAC',
	MDG = 'MDG',
	MYS = 'MYS',
	MWI = 'MWI',
	MDV = 'MDV',
	MLI = 'MLI',
	MLT = 'MLT',
	MAR = 'MAR',
	MTQ = 'MTQ',
	MUS = 'MUS',
	MRT = 'MRT',
	MYT = 'MYT',
	MEX = 'MEX',
	FSM = 'FSM',
	MDA = 'MDA',
	MCO = 'MCO',
	MNG = 'MNG',
	MNE = 'MNE',
	MSR = 'MSR',
	MOZ = 'MOZ',
	NAM = 'NAM',
	NRU = 'NRU',
	NPL = 'NPL',
	NIC = 'NIC',
	NER = 'NER',
	NGA = 'NGA',
	NIU = 'NIU',
	NOR = 'NOR',
	NCL = 'NCL',
	NZL = 'NZL',
	OMN = 'OMN',
	NLD = 'NLD',
	PAK = 'PAK',
	PLW = 'PLW',
	PSE = 'PSE',
	PAN = 'PAN',
	PNG = 'PNG',
	PRY = 'PRY',
	PER = 'PER',
	PYF = 'PYF',
	POL = 'POL',
	PRT = 'PRT',
	PRI = 'PRI',
	GBR = 'GBR',
	CAF = 'CAF',
	CZE = 'CZE',
	MKD = 'MKD',
	COG = 'COG',
	COD = 'COD',
	DOM = 'DOM',
	REU = 'REU',
	RWA = 'RWA',
	ROU = 'ROU',
	RUS = 'RUS',
	ESH = 'ESH',
	WSM = 'WSM',
	ASM = 'ASM',
	BLM = 'BLM',
	KNA = 'KNA',
	SMR = 'SMR',
	MAF = 'MAF',
	SPM = 'SPM',
	VCT = 'VCT',
	SHN = 'SHN',
	LCA = 'LCA',
	STP = 'STP',
	SEN = 'SEN',
	SRB = 'SRB',
	SYC = 'SYC',
	SLE = 'SLE',
	SGP = 'SGP',
	SXM = 'SXM',
	SYR = 'SYR',
	SOM = 'SOM',
	LKA = 'LKA',
	SWZ = 'SWZ',
	ZAF = 'ZAF',
	SDN = 'SDN',
	SSD = 'SSD',
	SWE = 'SWE',
	CHE = 'CHE',
	SUR = 'SUR',
	SJM = 'SJM',
	THA = 'THA',
	TWN = 'TWN',
	TZA = 'TZA',
	TJK = 'TJK',
	IOT = 'IOT',
	ATF = 'ATF',
	TLS = 'TLS',
	TGO = 'TGO',
	TKL = 'TKL',
	TON = 'TON',
	TTO = 'TTO',
	TUN = 'TUN',
	TKM = 'TKM',
	TUR = 'TUR',
	TUV = 'TUV',
	UKR = 'UKR',
	UGA = 'UGA',
	URY = 'URY',
	UZB = 'UZB',
	VUT = 'VUT',
	VAT = 'VAT',
	VEN = 'VEN',
	VNM = 'VNM',
	WLF = 'WLF',
	YEM = 'YEM',
	DJI = 'DJI',
	ZMB = 'ZMB',
	ZWE = 'ZWE',
	ZZZ = 'ZZZ'
}
