/**
 * @name InsumoSaludDictionaries
 * @author @MrCalamitus
 * @version 0.0.2
 *
 * @description Diccionarios de las enumeraciones de InsumoSalud
 * @changelog
 * [0.0.2] se Agrega CInsumoSaludTipoSujeto.LICENCIATARIO
 */

import { EInsumoSaludAcuerdoEquivalencia, EInsumoSaludPaisOrigen, EInsumoSaludTipoSujeto } from './InsumoSalud'

/**
 * @name CInsumoSaludTipoSujeto
 */
export const CInsumoSaludTipoSujeto: { [key in EInsumoSaludTipoSujeto]: string } = {
	TITULAR: 'TITULAR', //Persona física u organización que tiene la titularidad de la autorización del insumo para la salud (Puede ser extranjera o nacional)
	REPRESENTANTE: 'REPRESENTANTE', //Persona física o moral mexicana con RFC que representa al titular extranjero del insumo para la salud (Exclusivamente medicamento), efectuando los trámites relacionados con la solicitud y/o autorización
	FABRICANTE: 'FABRICANTE', //Persona física u organización que produce el insumo para la salud
	PROCESADOR: 'PROCESADOR', //Persona física u organización que participa en el proceso de producción del insumo para la salud (p,e equilibrando el PH, microgranulando la sales del fármaco, etc)
	LICENCIANTE: 'LICENCIANTE', //Persona física u organización que otorga la licencia de uso de la marca comercial, derechos de autor, derechos de patente, etc
	LICENCIATARIO: 'LICENCIATARIO', //Persona física u organización que recibe la licencia de uso de la marca comercial, derechos de autor, derechos de patente, etc
	IMPORTADOR: 'IMPORTADOR', //Persona física u organización que trae el insumo para la salud desde el país de fabricación a méxico desde el extranjero
	DISTRIBUIDOR: 'DISTRIBUIDOR', //Persona física u organización que se encarga de distribuir el insumo para la salud en el mercado
	ACONDICIONADOR_PRIMARIO: 'ACONDICIONADOR PRIMARIO', //Persona física u organización que se encarga de acondicionar el insumo para la salud en su presentación primaria(en contacto directo con el producto)
	ACONDICIONADOR_SECUNDARIO: 'ACONDICIONADOR SECUNDARIO', //Persona física u organización que se encarga de acondicionar el insumo para la salud en su presentación secundaria (envase que contiene el envase primario)
	ALMACEN: 'ALMACÉN', //Persona física u organización que se encarga de almacenar el insumo para la salud
	MAQUILADOR: 'MAQUILADOR', //Persona física u organización que realiza la maquila del insumo para la salud (Difiere del fabricante porque puede ser el mismo o un tercero quien lo construya)
	UNIDAD_FARMACOVIGILANCIA: 'UNIDAD DE FARMACOVIGILANCIA' //Persona física u organización que se encarga de la farmacovigilancia del insumo para la salud
}

/**
 * @name CInsumoSaludPaisOrigen
 */
export const CInsumoSaludPaisOrigen: { [key in EInsumoSaludPaisOrigen]: string } = {
	AFG: 'Afganistán',
	ALA: 'Islas Åland',
	ALB: 'Albania',
	DEU: 'Alemania',
	AND: 'Andorra',
	AGO: 'Angola',
	AIA: 'Anguila',
	ATA: 'Antártida',
	ATG: 'Antigua y Barbuda',
	SAU: 'Arabia Saudita',
	DZA: 'Argelia',
	ARG: 'Argentina',
	ARM: 'Armenia',
	ABW: 'Aruba',
	AUS: 'Australia',
	AUT: 'Austria',
	AZE: 'Azerbaiyán',
	BHS: 'Bahamas (las)',
	BGD: 'Bangladés',
	BRB: 'Barbados',
	BHR: 'Baréin',
	BEL: 'Bélgica',
	BLZ: 'Belice',
	BEN: 'Benín',
	BMU: 'Bermudas',
	BLR: 'Bielorrusia',
	MMR: 'Myanmar',
	BOL: 'Bolivia, Estado Plurinacional de',
	BIH: 'Bosnia y Herzegovina',
	BWA: 'Botsuana',
	BRA: 'Brasil',
	BRN: 'Brunéi Darussalam',
	BGR: 'Bulgaria',
	BFA: 'Burkina Faso',
	BDI: 'Burundi',
	BTN: 'Bután',
	CPV: 'Cabo Verde',
	KHM: 'Camboya',
	CMR: 'Camerún',
	CAN: 'Canadá',
	QAT: 'Catar',
	BES: 'Bonaire, San Eustaquio y Saba',
	TCD: 'Chad',
	CHL: 'Chile',
	CHN: 'China',
	CYP: 'Chipre',
	COL: 'Colombia',
	COM: 'Comoras',
	PRK: 'Corea (la República Democrática Popular de)',
	KOR: 'Corea (la República de)',
	CIV: "Côte d'Ivoire",
	CRI: 'Costa Rica',
	HRV: 'Croacia',
	CUB: 'Cuba',
	CUW: 'Curaçao',
	DNK: 'Dinamarca',
	DMA: 'Dominica',
	ECU: 'Ecuador',
	EGY: 'Egipto',
	SLV: 'El Salvador',
	ARE: 'Emiratos Árabes Unidos (Los)',
	ERI: 'Eritrea',
	SVK: 'Eslovaquia',
	SVN: 'Eslovenia',
	ESP: 'España',
	USA: 'Estados Unidos (los)',
	EST: 'Estonia',
	ETH: 'Etiopía',
	PHL: 'Filipinas (las)',
	FIN: 'Finlandia',
	FJI: 'Fiyi',
	FRA: 'Francia',
	GAB: 'Gabón',
	GMB: 'Gambia (La)',
	GEO: 'Georgia',
	GHA: 'Ghana',
	GIB: 'Gibraltar',
	GRD: 'Granada',
	GRC: 'Grecia',
	GRL: 'Groenlandia',
	GLP: 'Guadalupe',
	GUM: 'Guam',
	GTM: 'Guatemala',
	GUF: 'Guayana Francesa',
	GGY: 'Guernsey',
	GIN: 'Guinea',
	GNB: 'Guinea-Bisáu',
	GNQ: 'Guinea Ecuatorial',
	GUY: 'Guyana',
	HTI: 'Haití',
	HND: 'Honduras',
	HKG: 'Hong Kong',
	HUN: 'Hungría',
	IND: 'India',
	IDN: 'Indonesia',
	IRQ: 'Irak',
	IRN: 'Irán (la República Islámica de)',
	IRL: 'Irlanda',
	BVT: 'Isla Bouvet',
	IMN: 'Isla de Man',
	CXR: 'Isla de Navidad',
	NFK: 'Isla Norfolk',
	ISL: 'Islandia',
	CYM: 'Islas Caimán (las)',
	CCK: 'Islas Cocos (Keeling)',
	COK: 'Islas Cook (las)',
	FRO: 'Islas Feroe (las)',
	SGS: 'Georgia del sur y las islas sandwich del sur',
	HMD: 'Isla Heard e Islas McDonald',
	FLK: 'Islas Malvinas [Falkland] (las)',
	MNP: 'Islas Marianas del Norte (las)',
	MHL: 'Islas Marshall (las)',
	PCN: 'Pitcairn',
	SLB: 'Islas Salomón (las)',
	TCA: 'Islas Turcas y Caicos (las)',
	UMI: 'Islas de Ultramar Menores de Estados Unidos (las)',
	VGB: 'Islas Vírgenes (Británicas)',
	VIR: 'Islas Vírgenes (EE.UU.)',
	ISR: 'Israel',
	ITA: 'Italia',
	JAM: 'Jamaica',
	JPN: 'Japón',
	JEY: 'Jersey',
	JOR: 'Jordania',
	KAZ: 'Kazajistán',
	KEN: 'Kenia',
	KGZ: 'Kirguistán',
	KIR: 'Kiribati',
	KWT: 'Kuwait',
	LAO: 'Lao, (la) República Democrática Popular',
	LSO: 'Lesoto',
	LVA: 'Letonia',
	LBN: 'Líbano',
	LBR: 'Liberia',
	LBY: 'Libia',
	LIE: 'Liechtenstein',
	LTU: 'Lituania',
	LUX: 'Luxemburgo',
	MAC: 'Macao',
	MDG: 'Madagascar',
	MYS: 'Malasia',
	MWI: 'Malaui',
	MDV: 'Maldivas',
	MLI: 'Malí',
	MLT: 'Malta',
	MAR: 'Marruecos',
	MTQ: 'Martinica',
	MUS: 'Mauricio',
	MRT: 'Mauritania',
	MYT: 'Mayotte',
	MEX: 'México',
	FSM: 'Micronesia (los Estados Federados de)',
	MDA: 'Moldavia (la República de)',
	MCO: 'Mónaco',
	MNG: 'Mongolia',
	MNE: 'Montenegro',
	MSR: 'Montserrat',
	MOZ: 'Mozambique',
	NAM: 'Namibia',
	NRU: 'Nauru',
	NPL: 'Nepal',
	NIC: 'Nicaragua',
	NER: 'Níger (el)',
	NGA: 'Nigeria',
	NIU: 'Niue',
	NOR: 'Noruega',
	NCL: 'Nueva Caledonia',
	NZL: 'Nueva Zelanda',
	OMN: 'Omán',
	NLD: 'Países Bajos (los)',
	PAK: 'Pakistán',
	PLW: 'Palaos',
	PSE: 'Palestina, Estado de',
	PAN: 'Panamá',
	PNG: 'Papúa Nueva Guinea',
	PRY: 'Paraguay',
	PER: 'Perú',
	PYF: 'Polinesia Francesa',
	POL: 'Polonia',
	PRT: 'Portugal',
	PRI: 'Puerto Rico',
	GBR: 'Reino Unido (el)',
	CAF: 'República Centroafricana (la)',
	CZE: 'República Checa (la)',
	MKD: 'Macedonia (la antigua República Yugoslava de)',
	COG: 'Congo',
	COD: 'Congo (la República Democrática del)',
	DOM: 'República Dominicana (la)',
	REU: 'Reunión',
	RWA: 'Ruanda',
	ROU: 'Rumania',
	RUS: 'Rusia, (la) Federación de',
	ESH: 'Sahara Occidental',
	WSM: 'Samoa',
	ASM: 'Samoa Americana',
	BLM: 'San Bartolomé',
	KNA: 'San Cristóbal y Nieves',
	SMR: 'San Marino',
	MAF: 'San Martín (parte francesa)',
	SPM: 'San Pedro y Miquelón',
	VCT: 'San Vicente y las Granadinas',
	SHN: 'Santa Helena, Ascensión y Tristán de Acuña',
	LCA: 'Santa Lucía',
	STP: 'Santo Tomé y Príncipe',
	SEN: 'Senegal',
	SRB: 'Serbia',
	SYC: 'Seychelles',
	SLE: 'Sierra leona',
	SGP: 'Singapur',
	SXM: 'Sint Maarten (parte holandesa)',
	SYR: 'Siria, (la) República Árabe',
	SOM: 'Somalia',
	LKA: 'Sri Lanka',
	SWZ: 'Suazilandia',
	ZAF: 'Sudáfrica',
	SDN: 'Sudán (el)',
	SSD: 'Sudán del Sur',
	SWE: 'Suecia',
	CHE: 'Suiza',
	SUR: 'Surinam',
	SJM: 'Svalbard y Jan Mayen',
	THA: 'Tailandia',
	TWN: 'Taiwán (Provincia de China)',
	TZA: 'Tanzania, República Unida de',
	TJK: 'Tayikistán',
	IOT: 'Territorio Británico del Océano Índico (el)',
	ATF: 'Territorios Australes Franceses (los)',
	TLS: 'Timor-Leste',
	TGO: 'Togo',
	TKL: 'Tokelau',
	TON: 'Tonga',
	TTO: 'Trinidad y Tobago',
	TUN: 'Túnez',
	TKM: 'Turkmenistán',
	TUR: 'Turquía',
	TUV: 'Tuvalu',
	UKR: 'Ucrania',
	UGA: 'Uganda',
	URY: 'Uruguay',
	UZB: 'Uzbekistán',
	VUT: 'Vanuatu',
	VAT: 'Santa Sede[Estado de la Ciudad del Vaticano] (la)',
	VEN: 'Venezuela, República Bolivariana de',
	VNM: 'Viet Nam',
	WLF: 'Wallis y Futuna',
	YEM: 'Yemen',
	DJI: 'Yibuti',
	ZMB: 'Zambia',
	ZWE: 'Zimbabue',
	ZZZ: 'Países no declarados'
}
export const CInsumoSaludAcuerdoEquivalencia: { [key in EInsumoSaludAcuerdoEquivalencia]: string } = {
	[EInsumoSaludAcuerdoEquivalencia.FDA]: 'Food and Drug Administration (Estados Unidos de América)',
	[EInsumoSaludAcuerdoEquivalencia.HC]: 'Health Canada (Canadá)',
	[EInsumoSaludAcuerdoEquivalencia.MHLW]: 'Ministry of Health, Labour and Welfare (Japón)',
	[EInsumoSaludAcuerdoEquivalencia.OTROS]: 'Otros',
	[EInsumoSaludAcuerdoEquivalencia.NO_APLICA]: 'No aplica'
}
