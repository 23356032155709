import 'reflect-metadata'
import Config, { configType } from 'cofepris-typesafe/Constants/Config'
import { ExceptionUtilities } from 'cofepris-typesafe/Modules/Utilities'
import TypeContainer from 'cofepris-typesafe/Types/Container'
import { EGraphqlResponseStatus, IGraphqlResponse, getStatusMessage } from 'cofepris-typesafe/Types/Graphql'
import { IREQUEST, grapqhlResponseRequestType } from 'cofepris-typesafe/graphql/GrapqhlQueriesBase'
import SpyProxy, { ISelectionSet } from 'cofepris-typesafe/Services/graphql/spyProxy'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export class IGetAccessToken {
	getUserAccessToken(): string {
		throw new Error('Implemented by typedi Method not implemented.')
	}
}
export interface IErrorHandler {
	retryFun?: Promise<unknown>
	msg?: string
	jwt?: string
	signal?: AbortSignal
	ignoreErrors?: boolean
}

export default class GrapqhlApi {
	private static Url: string = Config.ENVIRONMENTS[Config.ENVIRONMENT as keyof configType['ENVIRONMENTS']].API_SERVICES.GRAPQLH.URL
	private static accessToken(): string {
		return TypeContainer.get<IGetAccessToken>('IGetAccessToken').getUserAccessToken()
	}

	public static setUrl(url: string) {
		this.Url = url
	}

	private static setVariables<I extends Partial<IREQUEST> | undefined, O extends Partial<IGraphqlResponse> | undefined>(query: grapqhlResponseRequestType<I, O>): grapqhlResponseRequestType<I, O> {
		if ((query.interpolatedArgs ?? '') == '') {
			return query
		}
		const interpolatedArgs = JSON.parse(query.interpolatedArgs ?? '{}')
		if (interpolatedArgs != undefined) {
			for (const key of Object.keys(interpolatedArgs)) {
				const value = (query?.request?.args as any)[key]
				if (value == undefined) {
					interpolatedArgs[key] = undefined
				}
				interpolatedArgs[key] = value
			}
		}

		const interpolatedArgsJson = JSON.stringify(interpolatedArgs)
		const unquoted = interpolatedArgsJson.replace(/"([^"]+)":/g, '$1:')
		const _copy: grapqhlResponseRequestType<I, O> = JSON.parse(JSON.stringify(query))
		_copy.query = _copy.query.replace(query.interpolatedArgs ?? '', unquoted)
		delete _copy.request!.args
		return _copy
	}

	// eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
	private static async _doFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		options?: IErrorHandler
	): Promise<RESPONSE> {
		queryType = GrapqhlApi.setVariables(queryType)

		let variables = queryType.request
		if (variables != undefined && Object.keys(variables).length == 0) {
			variables = undefined
		}
		if (options == undefined) {
			options = {}
		}
		if (queryType.jwt != undefined) {
			options.jwt = queryType.jwt
		}
		const graphql: string = JSON.stringify({
			query: queryType.query,
			variables: variables ?? { args: {} }
		})
		return await this.fetchData<RESPONSE>(
			{
				data: graphql
			},
			options
		)
	}

	private static async _doCustomFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined, T>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		selectionSet: (r: RESPONSE, isDummy: boolean) => T,
		options?: IErrorHandler & { ignorePrefix?: string }
	): Promise<T> {
		queryType = GrapqhlApi.setVariables(queryType)

		let variables: REQUEST | undefined = queryType.request
		if (variables != undefined && Object.keys(variables).length == 0) {
			variables = undefined
		}
		const spyProxy = new SpyProxy({ addMetaData: true })
		const dummyObj = spyProxy.obj

		selectionSet(dummyObj, true)
		const querySelection: ISelectionSet = spyProxy.getUsed({ ignorePrefix: options?.ignorePrefix })
		if (querySelection != undefined) {
			if (variables == undefined) {
				variables = {} as REQUEST
			}
			if (variables!.args == undefined) {
				variables!.args = {}
			}
			variables!.args.querySelection = JSON.stringify(querySelection)
		}
		const graphql: string = JSON.stringify({
			query: queryType.simpleQuery.replace('<selection/>', spyProxy.getSelectionQuery()),
			variables: variables
			//querySelection: querySelection
		})

		let response
		try {
			response = await this.fetchData<RESPONSE>(
				{
					data: graphql
				},
				options
			)
			const versionCodeFE = import.meta.env.VITE_GITVERSION.toUpperCase()
			const versionCodeGraphQl = (response?.versionCodeDetails?.shortHash || '').toUpperCase()
			if (!versionCodeGraphQl?.includes(versionCodeFE) && !location.origin.includes('localhost')) {
				alert('La versión de graphql (' + versionCodeGraphQl + ') no coincide con la versión de la aplicación (' + versionCodeFE + ')')
			}
		} catch (err) {
			throw new Error(ExceptionUtilities.getExceptionMessage(err))
		}
		return selectionSet(response, false)
	}

	private static async _doStringQueryFetch(stringQuery: string, args?: any, options?: IErrorHandler): Promise<unknown> {
		return await this.fetchData(
			{
				data: JSON.stringify({ query: stringQuery, variables: { args } })
			},
			options
		)
	}

	public static async doStringQueryFetch(stringQuery: string, args?: any, options?: IErrorHandler): Promise<unknown> {
		try {
			return await this._doStringQueryFetch(stringQuery, args, options)
		} catch (err) {
			throw new Error(ExceptionUtilities.getExceptionMessage(err))
		}
	}

	public static async doCustomFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined, T>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		selectionSet: (r: RESPONSE) => T,
		options?: IErrorHandler & { ignorePrefix?: string }
	): Promise<T | undefined> {
		try {
			return await this._doCustomFetch(queryType, selectionSet, options)
		} catch (err) {
			return undefined
		}
	}

	public static async doFetch<REQUEST extends Partial<IREQUEST> | undefined, RESPONSE extends IGraphqlResponse | undefined>(
		queryType: grapqhlResponseRequestType<REQUEST, RESPONSE>,
		options?: IErrorHandler
	): Promise<RESPONSE> {
		try {
			return await this._doFetch(queryType, options)
		} catch (err) {
			if ((err as Error).message == '500: Unknown Error') {
				return {
					metaData: {
						status: EGraphqlResponseStatus.INTERNAL_ERROR,
						message: getStatusMessage(EGraphqlResponseStatus.INTERNAL_ERROR).statusNormalizedName
					}
				} as RESPONSE
			}
			return {
				metaData: {
					status: EGraphqlResponseStatus.INTERNAL_ERROR,
					message: ExceptionUtilities.getExceptionMessage(err)
				}
			} as RESPONSE
		}
	}
	private static async fetchData<RESPONSE extends IGraphqlResponse | undefined>(requestOptions: AxiosRequestConfig, options?: IErrorHandler): Promise<RESPONSE> {
		const requestConfig = this.prepareRequestConfig(requestOptions, options)
		const response = await this.makeRequest<RESPONSE>(requestConfig)
		return this.handleResponse<RESPONSE>(response)
	}

	private static prepareRequestConfig(requestOptions: AxiosRequestConfig, options?: IErrorHandler): AxiosRequestConfig {
		const config = { ...requestOptions }
		const jwt = (this.accessToken() ?? options?.jwt ?? '').toString().trim()
		config.headers = {
			...config.headers,
			'Content-Type': 'application/json',
			'authorization': jwt
		}

		config.signal = options?.signal
		config.data = config.data || JSON.stringify({ args: {} })
		config.method = 'post'
		config.responseType = 'json'
		config.url = this.Url
		config.timeout = Config.GENERAL.MAX_FETCH_TIMEOUT

		axios.defaults.timeout = Config.GENERAL.MAX_FETCH_TIMEOUT

		return config
	}

	private static async makeRequest<RESPONSE>(config: AxiosRequestConfig): Promise<AxiosResponse<{ data: RESPONSE; errors: { message?: string }[] }, any>> {
		try {
			return await axios(config)
		} catch (error: any) {
			const response: AxiosResponse = {
				status: error.response.status,
				statusText: error.message
			} as AxiosResponse
			return response
		}
	}

	private static handleResponse<RESPONSE>(response: AxiosResponse<{ data: RESPONSE; errors: { message?: string }[] }, any>): RESPONSE {
		if (response?.status !== 200) {
			this.handleErrorStatus(response.status, response.statusText)
		}

		const responseJson = response.data
		if (responseJson.errors) {
			throw new Error(responseJson.errors.map((e: any) => e.message).join(', \n'))
		}

		const data = Object.values(responseJson?.data || {})[0]
		return this.validateResponseData<RESPONSE>(data, responseJson)
	}

	private static handleErrorStatus(status: number, statusText: string): never {
		const statusMessages = {
			401: EGraphqlResponseStatus.UNAUTHORIZED,
			400: EGraphqlResponseStatus.BAD_REQUEST,
			403: EGraphqlResponseStatus.FORBIDDEN,
			404: EGraphqlResponseStatus.NOT_FOUND,
			500: EGraphqlResponseStatus.INTERNAL_ERROR,
			599: EGraphqlResponseStatus.NET_WORK_ERROR
		}

		const errorStatus = statusMessages[status as keyof typeof statusMessages]
		if (errorStatus) {
			throw new Error(getStatusMessage(errorStatus).statusNormalizedName)
		}
		throw new Error(`Unknown Error status: ${status} ${statusText}`)
	}

	private static validateResponseData<RESPONSE>(data: any, responseJson: any): RESPONSE {
		if (data?.metaData?.status !== EGraphqlResponseStatus.SUCCESS) {
			let errorMessage = data?.metaData?.message || ''
			if (!errorMessage) {
				errorMessage = responseJson.errors.map((e: any) => e.message).join(', \n')
			}
			if (errorMessage === 'datos.formatos is not iterable') {
				return data as RESPONSE
			}
			throw new Error(errorMessage)
		}
		return data as RESPONSE
	}
}
