/**
 * @name Medicamento
 * @author @tirsomartinezreyes
 * @version 0.0.9
 * @changelog 0.0.9 - 12/nov/24 - Se agrega elimina consultaPatente
 * @changelog 0.0.8 - 28/oct/24 - Se ajusta la estructura de la fórmula de un medicamento y pantentes
 * @description Estructura de un medicamento para el Activo de información de un insumo de salud IInsumoSalud["medicamento"]
 * @changelog 0.0.7 - 12/sep/24 - Se agrega propiedad a la enumeracion EMedicamentoFormaFarmaceutica
 * @changelog 0.0.6 - 29/ago/24 - Se agrega la propiedad "consultaPatente" para el número de consulta de patente y "pruebaIntercambiabilidad" para la descripción de la prueba de intercambiabilidad
 */

import { IFileType } from 'cofepris-typesafe/Types/FilesType'
import {
	IInsumoSaludSujeto // Definición de sujetos relacionados con la cadena de suministro del medicamento
} from './InsumoSalud' //Proporciona interfaces y enumeraciones para Insumos de salud

/**
 * @name IMedicamento
 * @description Estructura de un medicamento
 */
export interface IMedicamento {
	tipo: EMedicamentoTipo //Tipo de medicamento
	tipoAlopatico?: EMedicamentoTipoAlopatico //Tipo de medicamento
	grupoCoordinacion: EMedicamentoGrupoCoordinacion //Grupo que coordinación la atención de las homoclaves del medicamento
	grupoPago: EMedicamentoGrupoPago //Grupo de pago del medicamento
	descripcion?: string //Descripción del medicamento
	estupefacientePsicotropicoPrecursor: boolean //Indica si el medicamento es un estupefaciente, un psicotrópico o contiene una sustancia química precursora de fabricación de drogas
	deReferencia: boolean //Indica si el medicamento es la referencia para los genéricos
	claseMedicamentoLGS226: EMedicamentoClaseLGS226 //Clasificación de medicamentos según la Ley General de Salud, artículo 226
	formaFarmaceutica?: EMedicamentoFormaFarmaceutica //Presentación del medicamento
	viasAdministracion?: EMedicamentoViaAdministracion[] //Maneras en que el medicamento es administrado
	biologico?: IMedicamentoBiologico //Información adicional para medicamentos biológicos
	biotecnologico?: IMedicamentoBiotecnologico //Información adicional para medicamentos biotecnológicos
	consideracionesUsos?: EMedicamentoConsideracionUso[] //Información adicional relacionada con el uso del medicamento para manejar, prescribir, preparar o emplear correctamente
	indicacionesTerapeuticas?: string[] //Para qué sirve el medicamento (curar fiebre, dolor de cabeza, etc.)
	contraIndicaciones?: string[] //Cuando no se debe usar el medicamento
	unidadFarmacovigilancia?: IInsumoSaludSujeto //Unidad de Farmacovigilancia responsables del medicamento (Nacionales es el mismo fabricante, extranjeros puede ser diferente)
	vidaUtil?: string[] //Vida útil del medicamento, pueden ser múltiples
	farmacos?: IMedicamentoFarmaco[] //El Fármaco es el principio activo de un medicamento, es decir, la sustancia que tiene efecto farmacológico
	diluyentes?: IMedicamentoDiluyente[] //Diluyentes del medicamento para cambiar la concentración
	adyuvantes?: IMedicamentoAdyuvante[] //Adyuvante del medicamento (p,e, para reforzar la respuesta inmunitaria de una vacuna)
	presentaciones?: IMedicamentoPresentacion[] //Presentaciones autorizadas del medicamento para comercialización y distribución
	cadenaProduccionDistribucion?: IMedicamentoCadenaProduccionDistribucion //Sujetos que participan en la cadena de producción - Distribución del medicamento
	formulas?: IMedicamentoFormula[] //Fórmulas del medicamento
	anexos?: IMedicamentoAnexo[] //Lista de anexos que se adjuntan a la autorización del dispositivo
	patentes?: IMedicamentoPatentes[] //Patentes del medicamento
	medicamentoReferencia?: string //Medicamento de referencia
	informeTecnico?: IFileType[] //Informe técnico del medicamento
	pruebaIntercambiabilidad?: string //Texto descriptivo de la prueba de intercambiabilidad
	notas?: string[] //Notas adicionales sobre el medicamento
}

/**
 * @name EMedicamentoTipo
 * @description Enumeración de tipos de medicamentos
 */
export enum EMedicamentoTipo {
	VACUNA = 'VACUNA', //Medicamento biológico o biotecnológico que estimula el sistema inmunológico para prevenir enfermedades
	HEMODERIVADO = 'HEMODERIVADO', //Medicamento biológico o biotecnológico derivado de la sangre
	FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA = 'FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA', //Medicamento especializado para alimentación
	HERBOLARIO = 'HERBOLARIO', //Preparado de plantas medicinales, o sus partes, individuales o combinadas y sus derivados, presentado en forma farmacéutica, al cual se le atribuye por conocimiento popular o tradicional, el alivio para algunos síntomas participantes o aislados de una enfermedad
	HOMEOPATICO = 'HOMEOPATICO', //Medicamento a base de sustancias naturales diluidas
	VITAMINICO = 'VITAMINICO', //Producto que en su composición contiene únicamente vitaminas o minerales indicados para prevenir o tratar padecimientos por insuficiencia
	BIOLOGICO = 'BIOLOGICO', //Medicamento biológico
	BIOTECNOLOGICO = 'BIOTECNOLOGICO', //Los biofármacos y los biomedicamentos. Biofármaco es toda substancia que haya sido producida por biotecnología molecular, que tenga actividad farmacológica, que se identifique por sus propiedades físicas, químicas y biológicas, que reúna condiciones para ser empleada como principio activo de un medicamento o ingrediente de un medicamento. Biomedicamento es toda substancia que haya sido producida por biotecnología molecular, que tenga efecto terapéutico, preventivo o rehabilitatorio, que se presente en forma farmacéutica, que se identifique como tal por su actividad farmacológica y propiedades físicas, químicas y biológicas.
	HUERFANO = 'HUERFANO', //Medicamento para la atención a enfermedades raras
	FARMOQUIMICO = 'FARMOQUIMICO', //Medicamento convencional
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No seleccionado
}

/**
 * @name EMedicamentoTipoAlopatico
 */
export enum EMedicamentoTipoAlopatico {
	VACUNA = 'VACUNA', //Medicamento biológico o biotecnológico que estimula el sistema inmunológico para prevenir enfermedades
	GENERICO = 'GENERICO', //Medicamento que contiene el mismo principio activo que un medicamento de marca, pero no está protegido por una patente
	BIOLOGICO_MOLECULA_NUEVA = 'BIOLOGICO_MOLECULA_NUEVA', //Medicamento biológico con molécula nueva
	BIOLOGICO_BIOCOMPARABLE = 'BIOLOGICO_BIOCOMPARABLE', //Medicamento biológico con molécula similar a un medicamento biológico de referencia
	BIOTECNOLOGICO_INNOVADOR = 'BIOTECNOLOGICO_INNOVADOR', //Medicamento biotecnológico innovador
	BIOTECNOLOGICO_BIOCOMPARABLE = 'BIOTECNOLOGICO_BIOCOMPARABLE', //Medicamento biotecnológico con molécula similar a un medicamento biotecnológico de referencia
	FARMOQUIMICO_MOLECULA_NUEVA = 'FARMOQUIMICO_MOLECULA_NUEVA', //Medicamento farmoquímico con molécula nueva
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No seleccionado
}

/**
 * @name EMedicamentoGrupoCoordinacion
 */
export enum EMedicamentoGrupoCoordinacion {
	FQ = 'FQ', //Farmoquímico
	BBV = 'BBV', //Biológico Biotecnológico Vacuna
	HHV = 'HHV', //Homeopáticos, Herbolarios y Vitamínicos
	FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA = 'FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA', //Fórmula de alimentación enteral especializada
	HUERFANO = 'HUERFANO', //Medicamento huérfano
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No seleccionado
}

/**
 * @name EMedicamentoGrupoPago
 */
export enum EMedicamentoGrupoPago {
	MAXIMO = 'MAXIMO', //Grupo de pago máximo
	GENERICO_BIOCOMPARABLE = 'GENERICO_BIOCOMPARABLE', //Grupo de pago para genéricos biocomparables
	HHV = 'HHV', //Grupo de pago para homeopáticos, herbolarios y vitamínicos
	HUERFANO = 'HUERFANO', //Grupo de pago para medicamentos huérfanos
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No seleccionado
}

/**
 * @name EMedicamentoClaseLGS226
 * @description Enumeración de clasificación de medicamentos según la Ley General de Salud, artículo 226
 * @reference https://transparencia.cofepris.gob.mx/index.php/es/marco-juridico/leyes
 * @reference https://www.diputados.gob.mx/LeyesBiblio/pdf/LGS.pdf
 */
export enum EMedicamentoClaseLGS226 {
	I = 'I', //Medicamentos que sólo pueden adquirirse con receta o permiso especial, expedido por la Secretaría de Salud
	II = 'II', //Medicamentos que sólo pueden adquirirse con receta (receta retenida, registrada y con vigencia de 30 días), máximo 2 presentaciones por receta
	III = 'III', //Medicamentos que sólo pueden adquirirse con receta o autorización especial (receta sellada, registrada y retenida en el tercer surtido), máximo 3 surtidos por receta
	IV = 'IV', //Medicamentos que sólo pueden adquirirse con receta sin límite de surtido
	V = 'V', //Medicamentos no requieren receta para su adquisición en farmacias
	VI = 'VI', //Medicamentos no requieren receta para su adquisición en otros establecimiento que no sean farmacias,
	NO_APLICA = 'NO_APLICA', //No aplica clasificación
	NO_SELECCIONADO = 'NO_SELECCIONADO' //No seleccionado
}

/**
 * @name EMedicamentoFormaFarmaceutica
 * @description Enumeración de formas farmacéuticas de medicamentos
 * @reference Farmacopéa de los Estados Unidos Mexicanos
 */
export enum EMedicamentoFormaFarmaceutica {
	AEROSOL = 'AEROSOL',
	CAPSULA = 'CAPSULA',
	COLIRIO = 'COLIRIO',
	COMPRIMIDO = 'COMPRIMIDO',
	CREMA = 'CREMA',
	ELIXIR = 'ELIXIR',
	EMULSION = 'EMULSION',
	ESPUMA = 'ESPUMA',
	GAS_MEDICINAL = 'GAS_MEDICINAL',
	GEL = 'GEL',
	GLOBULO = 'GLOBULO',
	GOMA = 'GOMA',
	GRANULADO = 'GRANULADO',
	IMPLANTE = 'IMPLANTE',
	JABON = 'JABON',
	JALEA = 'JALEA',
	JARABE = 'JARABE',
	LAMINILLA = 'LAMINILLA',
	LINIMENTO = 'LINIMENTO',
	LOCION = 'LOCION',
	OBLEA = 'OBLEA',
	OVULO = 'OVULO',
	POMADA = 'POMADA',
	PARCHE = 'PARCHE',
	PASTA = 'PASTA',
	PASTILLA = 'PASTILLA',
	POLVO = 'POLVO',
	SISTEMA_LIBERACION = 'SISTEMA_LIBERACION',
	SOLUCION = 'SOLUCION',
	SOLUCION_HOMEOPATICA = 'SOLUCION_HOMEOPATICA',
	SUPOSITORIO = 'SUPOSITORIO',
	SUSPENSION = 'SUSPENSION',
	TABLETA = 'TABLETA',
	UNGUENTO = 'UNGUENTO',
	OTRO = 'OTRO'
}

/**
 * @name EMedicamentoViaAdministracion
 * @description Enumeración de vías de administración de medicamentos
 * @reference Farmacopéa de los Estados Unidos Mexicanos
 */
export enum EMedicamentoViaAdministracion {
	BUCAL = 'BUCAL',
	CUTANEA = 'CUTANEA',
	ENDOTRAQUEAL = 'ENDOTRAQUEAL',
	EPILESONAL = 'EPILESONAL',
	EPIDURAL = 'EPIDURAL',
	INHALACION = 'INHALACION',
	INTRAARTICULAR = 'INTRAARTICULAR',
	INTRALESIONAL = 'INTRALESIONAL',
	INTRAMUSCULAR = 'INTRAMUSCULAR',
	INTRAOCULAR = 'INTRAOCULAR',
	INTRAPERITONEAL = 'INTRAPERITONEAL',
	INTRATECAL = 'INTRATECAL',
	INTRAUTERINA = 'INTRAUTERINA',
	INTRAVENOSA = 'INTRAVENOSA',
	NASAL = 'NASAL',
	OFTALMICA = 'OFTALMICA',
	ORAL = 'ORAL',
	OTICA = 'OTICA',
	RECTAL = 'RECTAL',
	SUBCUTANEA = 'SUBCUTANEA',
	SUBLINGUAL = 'SUBLINGUAL',
	TOPICA = 'TOPICA',
	TRANSDERMICA = 'TRANSDERMICA',
	TRONCULAR = 'TRONCULAR',
	URETAL = 'URETAL',
	VAGINAL = 'VAGINAL'
}

/**
 * @name EMedicamentoConsideracionUso
 * @description Enumeración de consideraciones de uso de medicamentos
 * @reference Farmacopéa de los Estados Unidos Mexicanos
 */
export enum EMedicamentoConsideracionUso {
	DISPERSABLE = 'DISPERSABLE',
	EFERVESCENTE = 'EFERVESCENTE',
	INYECTABLE = 'INYECTABLE',
	LIBERACION_PROLONGADA = 'LIBERACION_PROLONGADA',
	LIBERACION_RETARDADA = 'LIBERACION_RETARDADA',
	MASTICABLE = 'MASTICABLE',
	ORODISPERSABLE = 'ORODISPERSABLE',
	DIALISIS_PERITONEAL = 'DIALISIS_PERITONEAL',
	ENEMA = 'ENEMA',
	INHALACION = 'INHALACION',
	IRRIGACION = 'IRRIGACION',
	NEBULIZACION = 'NEBULIZACION',
	SOLUCION = 'SOLUCION',
	SUSPENSION = 'SUSPENSION'
}

export enum EMedicamentoFormulaTipo {
	MEDICAMENTO = 'MEDICAMENTO',
	DILUYENTE = 'DILUYENTE'
}
export enum EMedicamentoFormulaProporcion {
	CADA_ML_CONTIENE = 'CADA_ML_CONTIENE',
	CADA_100_ML_CONTIENEN = 'CADA_100_ML_CONTIENEN',
	CADA_G_CONTIENE = 'CADA_G_CONTIENE',
	CADA_100_G_CONTIENEN = 'CADA_100_G_CONTIENEN',
	CADA_AMPOLLETA_CON_DILUYENTE_CONTIENE = 'CADA_AMPOLLETA_CON_DILUYENTE_CONTIENE',
	CADA_CAPSULA_CONTIENE = 'CADA_CAPSULA_CONTIENE',
	CADA_CARTUCHO_CONTIENE = 'CADA_CARTUCHO_CONTIENE',
	CADA_CILINDRO_DE_ACERO_CONTIENE = 'CADA_CILINDRO_DE_ACERO_CONTIENE',
	CADA_COMPRIMIDO_CONTIENE = 'CADA_COMPRIMIDO_CONTIENE',
	EL_DISPOSITIVO_INHALADOR_CONTIENE = 'EL_DISPOSITIVO_INHALADOR_CONTIENE',
	CADA_EMPLASTO_CONTIENE = 'CADA_EMPLASTO_CONTIENE',
	CADA_ENVASE_CONTIENE = 'CADA_ENVASE_CONTIENE',
	CADA_FRASCO_AMPULA_CONTIENE = 'CADA_FRASCO_AMPULA_CONTIENE',
	CADA_FRASCO_AMPULA_CON_DILUYENTE_CONTIENE = 'CADA_FRASCO_AMPULA_CON_DILUYENTE_CONTIENE',
	CADA_FRASCO_AMPULA_CON_LIOFILIZADO_CONTIENE = 'CADA_FRASCO_AMPULA_CON_LIOFILIZADO_CONTIENE',
	CADA_FRASCO_CON_LIOFILIZADO_CONTIENE = 'CADA_FRASCO_CON_LIOFILIZADO_CONTIENE',
	CADA_FRASCO_CON_POLVO_CONTIENE = 'CADA_FRASCO_CON_POLVO_CONTIENE',
	CADA_FRASCO_NEBULIZADOR_CONTIENE = 'CADA_FRASCO_NEBULIZADOR_CONTIENE',
	CADA_GLOBULO_CONTIENE = 'CADA_GLOBULO_CONTIENE',
	CADA_GLOBULO_HOMEOPATICO_CONTIENE = 'CADA_GLOBULO_HOMEOPATICO_CONTIENE',
	CADA_GOMA_CONTIENE = 'CADA_GOMA_CONTIENE',
	CADA_GRAGEA_CONTIENE = 'CADA_GRAGEA_CONTIENE',
	CADA_GRAGEA_HOMEOPATICA_CONTIENE = 'CADA_GRAGEA_HOMEOPATICA_CONTIENE',
	CADA_IMPLANTE_CONTIENE = 'CADA_IMPLANTE_CONTIENE',
	CADA_JABON_CONTIENE = 'CADA_JABON_CONTIENE',
	CADA_LAMINILLA_CONTIENE = 'CADA_LAMINILLA_CONTIENE',
	CADA_OBLEA_CONTIENE = 'CADA_OBLEA_CONTIENE',
	CADA_OVULO_CONTIENE = 'CADA_OVULO_CONTIENE',
	CADA_PARCHE_CONTIENE = 'CADA_PARCHE_CONTIENE',
	CADA_PASTILLA_CONTIENE = 'CADA_PASTILLA_CONTIENE',
	CADA_PASTILLA_HOMEOPATICA_CONTIENE = 'CADA_PASTILLA_HOMEOPATICA_CONTIENE',
	CADA_PERLA_CONTIENE = 'CADA_PERLA_CONTIENE',
	CADA_SISTEMA_DE_LIBERACION_CONTIENE = 'CADA_SISTEMA_DE_LIBERACION_CONTIENE',
	CADA_SOBRE_CONTIENE = 'CADA_SOBRE_CONTIENE',
	CADA_SUPOSITORIO_CONTIENE = 'CADA_SUPOSITORIO_CONTIENE',
	CADA_TABLETA_CONTIENE = 'CADA_TABLETA_CONTIENE',
	CADA_TABLETA_HOMEOPATICA_CONTIENE = 'CADA_TABLETA_HOMEOPATICA_CONTIENE',
	CADA_TROCISCO_CONTIENE = 'CADA_TROCISCO_CONTIENE',
	CADA_TROCISCO_HOMEOPATICO_CONTIENE = 'CADA_TROCISCO_HOMEOPATICO_CONTIENE',
	CADA_DOSIS_CONTIENE = 'CADA_DOSIS_CONTIENE',
	EL_FRASCO_AMPULA_CON_LIOFILIZADO_CONTIENE = 'EL_FRASCO_AMPULA_CON_LIOFILIZADO_CONTIENE',
	EL_FRASCO_AMPULA_CON_DILUYENTE_CONTIENE = 'EL_FRASCO_AMPULA_CON_DILUYENTE_CONTIENE',
	LA_AMPOLLETA_CONTIENE = 'LA_AMPOLLETA_CONTIENE',
	LA_AMPOLLETA_CON_DILUYENTE_CONTIENE = 'LA_AMPOLLETA_CON_DILUYENTE_CONTIENE'
}

/**
 * @name IMedicamentoFarmaco
 * @description El Fármaco es el principio activo de un medicamento, es decir, la sustancia que tiene efecto farmacológico
 */
export interface IMedicamentoFarmaco {
	farmaco: string //Principio activo
	fabricantes: IInsumoSaludSujeto[] //Fabricantes del principio activo
	subprocesos?: IMedicamentoSubproceso[] //Subprocesos de fabricación del principio activo
}

/**
 * @name IMedicamentoDiluyente
 * @description Diluyente del medicamento para cambiar la concentración
 */

export interface IMedicamentoDiluyente {
	diluyente: string //Nombre del diluyente (p,e, Agua Destilada)
	fabricantes: IInsumoSaludSujeto[] //Fabricantes del diluyente
}

/**
 * @name IMedicamentoAdyuvante
 * @description El Adyuvante es un elemento adicional en la formulación de un medicamentopara potenciar el efecto.
 */
export interface IMedicamentoAdyuvante {
	adyuvante: string //Nombre del adyuvante, p,e, "Aluminio"
	fabricantes: IInsumoSaludSujeto[] //Fabricantes del adyuvante
}

/**
 * @name IMedicamentoSubproceso
 * @description Subproceso de fabricación del fármaco
 */
export interface IMedicamentoSubproceso {
	subproceso: string //Subproceso que se efectua en el fármaco, p.e, micronización, granulación, etc.
	procesador: IInsumoSaludSujeto[] //Quién lleva a cabo el proceso
	nota?: string //Notas adicionales sobre el subproceso del fármaco, o especificación en caso de que se implemente un catálogo y el sub proceso no se encuentre
}

/**
 * @name IMedicamentoFormula
 * @description Fórmula en la que el medicamento puede estar constituído
 */
export interface IMedicamentoFormula {
	tipo: EMedicamentoFormulaTipo //Tipo de fórmula
	etiqueta?: string //Etiqueta de la fórmula del medicamento (p,e, "Sabor maracuyá", "concentración al 5%","50 mcg")
	proporcion?: EMedicamentoFormulaProporcion //proporcion de la fórmula del medicamento (p,e, "Cada 100 ml contiene", "Cada tableta contiene")
	farmacos: IMedicamentoFormulaElemento[] //Fármacos de la fórmula del medicamento
	aditivos?: IMedicamentoFormulaElemento[] //Aditivos de la fórmula del medicamento
}

/**
 * @name IMedicamentoFormulaElemento
 * @description Elemento de la fórmula (p,e, "Ácido láctico", "0.252", "g", "disolvente", "Para formar lactato de sodio")
 */
export interface IMedicamentoFormulaElemento {
	elemento: string //Nombre del elemento, p,e "Ácido láctico"
	equivalencia?: IMedicamentoFormulaElementoSinEquivalencia //Equivalencia del elemento, p,e, "Equivale a 0.25mg de sodio"
	cantidad: string //Cantidad del elemento, p,e, "0.252"
	unidad: string //Unidad de medida del elemento, p,e, "g"
	componente?: string //Función del elemento en la fórmula, p,e, "disolvente", "colorante", "saborizante", etc.
	nota?: string //Notas adicionales sobre el elemento de la fórmula
}
/**
 * @name IMedicamentoFormulaElementoSinEquivalencia
 * @description Elemento de la fórmula (p,e, "Ácido láctico", "0.252", "g", "disolvente", "Para formar lactato de sodio")
 */
export interface IMedicamentoFormulaElementoSinEquivalencia {
	elemento: string //Nombre del elemento, p,e "Ácido láctico"
	cantidad: string //Cantidad del elemento, p,e, "0.252"
	unidad: string //Unidad de medida del elemento, p,e, "g"
	componente?: string //Función del elemento en la fórmula, p,e, "disolvente", "colorante", "saborizante", etc.
	nota?: string //Notas adicionales sobre el elemento de la fórmula
}

/**
 * @name IMedicamentoCadenaProduccionDistribucion
 * @description Sujetos que participan en la cadena de producción - Distribución del medicamento, una vez que esta en su forma de producto final,listo para su comercialización y/o distribución
 */
export interface IMedicamentoCadenaProduccionDistribucion {
	licenciantes?: IInsumoSaludSujeto[] //Licenciantes del producto terminado //Dueño de la molécula (IP) //p,e "Bajo licencia de..." o "según fórmula de..."
	licenciatarios?: IInsumoSaludSujeto[] //Licenciatario del producto terminado ¿Para quién se esta produciendo?
	fabricantes: IInsumoSaludSujeto[] //Fabricante del producto terminado
	importadores?: IInsumoSaludSujeto[] //Importadores del producto terminado (Solo para productos de fabricación extranjera)
	distribuidores?: IInsumoSaludSujeto[] //Distribuidores del producto terminado
	acondicionadoresPrimarios?: IInsumoSaludSujeto[] //Acondicionadores primarios del producto terminado https://www.mheducation.es/bcv/guide/capitulo/8448184513.pdf
	acondicionadoresSecundarios?: IInsumoSaludSujeto[] //Acondicionadores secundarios del producto terminado https://www.mheducation.es/bcv/guide/capitulo/8448184513.pdf
	almacenes?: IInsumoSaludSujeto[] //Almacenes del producto terminado
}

/**
 * @name IMedicamentoPresentacion
 * @description Presentaciones del producto terminado para comercialización y distribución
 * ToDo: Revisar video del 12 de marzo de 2023 minuto 16 para la explicación
 */
export interface IMedicamentoPresentacion {
	resumenPresentacion: string //Resumen de la presentación del medicamento, (p,e, Caja de cartón con un frasco de 10 tabletas de 500 mg)
	envasesPrimarios?: IMedicamentoEnvase[] //Envases primarios de la presentación, es decir, el envase que tiene contacto directamente con el medicamento
	envasesSecundarios?: IMedicamentoEnvase[] //Envases secundarios de la presentación, es decir, el envase que contiene el envase primario, p.e, caja de cartón
	unidad?: string //Unidades en las que se expresa el contenido , por ejemplo, tabletas,ml, etc.
	cantidad?: string //Cantidad de unidades en la presentación
	concentracion?: string //Concentración del medicamento en la presentación
	diluyentes?: IMedicamentoPresentacionDiluyente[] //Diluyentes de la presentación
	dispositivosMedicos?: IMedicamentoPresentacionDispositivoMedico[] //Dispositivo médico que acompaña al medicamento en la presentación, pe "Jeringa 5 ml con registro sanitario 12345 SSA"
	claveCNIS?: string //Clave (si aplica) de coincidencia de la presentación del medicamento con el Compendio Nacional de Insumos para la Salud
	notas?: string[] //Notas adicionales sobre la presentación del medicamento
}

/**
 * @name IMedicamentoEnvase
 * @description Estructura de envase (primario o secundario) (p,e, Frasco de vidrio, caja de cartón, etc.)
 */
export interface IMedicamentoEnvase {
	envase: string //p.e, Frasco
	material: string // p.e. Vidrio
}

/**
 * @name IMedicamentoPresentacionDiluyente
 * @description Diluyente de la presentación del medicamento para cambiar la concentración
 */
export interface IMedicamentoPresentacionDiluyente {
	diluyente: string //Nombre del diluyente (p,e, Agua Destilada)
	unidad?: string //Unidad de medida del diluyente, p,e, ml
	cantidad?: string //Cantidad del diluyente, p,e, 10
	concentracion?: string //Concentración del diluyente, p,e, 10 mg/ml
	registroSanitario?: string //Registro sanitario del diluyente
	envase: IMedicamentoEnvase //Envase del diluyente
}

/**
 * @name IMedicamentoPresentacionDispositivoMedico
 * @description Presentación del medicamento con dispositivo médico
 */
export interface IMedicamentoPresentacionDispositivoMedico {
	dispositivoMedico: string //Nombre del dispositivo médico, p,e, Nebulizador
	cantidad: string //Cantidad de unidades en la presentación del dispositivo médico, p,e, 1
	registroSanitario?: string //Registro sanitario del dispositivo médico
}

/**
 * @name IMedicamentoAnexo
 * @description Estructura de un documeto Anexo de medicamentos
 */
export interface IMedicamentoAnexo {
	tipo: EMedicamentoAnexoTipo //Tipo de documento que se presenta como anexo
	etiqueta?: string //Etiqueta del documento, p,e, "Instrucciones para prescribir dirigidas a especialistas para la salud"
	uuid: string //UUID del documento en el sistema DIGIPRiS: Regulación en línea
	documento?: IFileType[] //Documento anexo
}

/**
 * @name IMedicamentoPatentes
 * @description Estructura de un documento de patentes de medicamentos
 */
export interface IMedicamentoPatentes {
	numeroOficioRespuestaIMPI?: string //Número de oficio de la patente
	documentoConsulta?: IFileType[] //Documento patente
	documentoRespuesta?: IFileType[] //Documento patente
}

/**
 * @name EMedicamentoAnexoTipo
 * Enumeración de tipos de anexos de medicamentos
 */
export enum EMedicamentoAnexoTipo {
	INFORMACION_PRESCRIBIR = 'INFORMACION_PRESCRIBIR',
	INSTRUCTIVO = 'INSTRUCTIVO',
	ETIQUETAS = 'ETIQUETAS'
}

/**
 * @name IMedicamentoBiotecnologico
 * @description Información adicional para medicamentos biotecnológicos
 */
export interface IMedicamentoBiotecnologico {
	origenBiofarmaco: string //Origen del biofármaco, p,e, "Biotecnología molecular"
}

/**
 * @name IMedicamentoBiologico
 * @description Información adicional para medicamentos biológicos
 */
export interface IMedicamentoBiologico {
	organismoVivoOrigen: string //Organismo vivo del que se obtiene el medicamento, p,e, "Levadura"
}
