/**
 * @name dates.ts
 * @author @tirsomartinezreyes
 * @version 1.0.1
 * @description Filtros para manejo de fechas como cadenas
 * @changelog 1.0.1 - 23/oct/24 - @tirsomartinezreyes - Se mueve la función getDateByMillis a un módulo compartido
 * */

import { EFirmaDelimitador } from 'src/bom'
export { getDateByMillis } from 'src/bom'

export interface DaysLeft {
	days: number
	text: string
	class: string
}

export function getDaysLeft(startDateInMillis: number, days: number = 0): DaysLeft {
	const response = {
		days: 0,
		text: '',
		class: 'INFO'
	} as DaysLeft

	response.days = getDaysLeftAsNumber(startDateInMillis, days)
	response.text = getDaysLeftAsString(response.days)
	response.class = getDaysLeftAsClassString(response.days, days)
	return response
}

function getDaysLeftAsNumber(startDateInMillis: number, days: number = 0): number {
	let response = 0
	const millisInDay = 86400000
	const deadLine = startDateInMillis + days * millisInDay
	const difference = deadLine - Date.now()
	response = Math.floor(difference / millisInDay)
	return response
}

function getDaysLeftAsString(daysLeft: number): string {
	let response = ''

	if (daysLeft <= 0) {
		response = 'El tiempo de atención se ha excedido por ' + Math.abs(daysLeft) + ' días'
	}

	if (daysLeft == 1) {
		response = 'Falta un día para la fecha límite de atención'
	}

	if (daysLeft > 1) {
		response = 'Faltan ' + daysLeft + ' días para la fecha límite de atención'
	}

	return response
}

function getDaysLeftAsClassString(daysLeft: number, days: number): string {
	let response = 'INFO'
	const percentage = daysLeft / days

	if (daysLeft <= 0) {
		response = 'DANGER'
	}

	if ((percentage <= 0.25 && percentage > 0) || daysLeft == 1) {
		response = 'WARNING'
	}

	return response
}

export function getTimeAgoAsString(millis?: number): string {
	if (millis == undefined) {
		return ''
	}
	let response = ''
	let prefix = ''
	const difference = Math.abs(Date.now() - millis)
	const seconds = Math.floor(difference / 1000)
	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(minutes / 60)
	const days = Math.floor(hours / 24)
	const months = Math.floor(days / 30)
	const years = Math.floor(months / 12)

	if (years > 0) {
		response = years + ' año' + (years == 1 ? '' : 's')
	} else if (months > 0) {
		response = months + ' mes' + (months == 1 ? '' : 'es')
	} else if (days > 0) {
		response = days + ' día' + (days == 1 ? '' : 's')
	} else if (hours > 0) {
		response = hours + ' hora' + (hours == 1 ? '' : 's')
	} else if (minutes > 0) {
		response = minutes + ' minuto' + (minutes == 1 ? '' : 's')
	} else if (seconds > 30) {
		response = seconds + ' segundo' + (seconds == 1 ? '' : 's')
	} else {
		response = 'un momento'
	}

	if (millis >= Date.now()) {
		prefix = 'En '
	} else {
		prefix = 'Hace '
	}

	return prefix + response
}
export function isoDateToString(value: string): string {
	const isoDateRegex: RegExp = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z)/gm

	if (!value) return value

	const processValue = (val: string): string => {
		const match = val.match(isoDateRegex)
		if (!match) return val

		const formattedDate = match.map(dateStr => {
			const date = new Date(dateStr)
			const options: Intl.DateTimeFormatOptions = {
				year: 'numeric',
				month: 'long',
				day: '2-digit',
				timeZone: 'America/Mexico_City'
			}
			const formatter = new Intl.DateTimeFormat('es-ES', options)
			return formatter.format(date)
		})

		return val.replace(isoDateRegex, formattedDate.join(', '))
	}

	if (Array.isArray(value)) {
		value.map(element => {
			if (element && (element[0]['type'] === 'STRING' || element[0]['type'] === 'DATE')) {
				const valueType = element[0]['type'] === 'STRING' ? 'valueString' : 'valueDate'
				element[0][valueType] = processValue(element[0][valueType])
			}
		})
		return value
	} else {
		return processValue(value)
	}
}
export function formatDateString(date: string | number | Date): string {
	const dateType = typeof date
	let dateValue = undefined
	if (dateType == 'number') {
		dateValue = date
	} else if (dateType == typeof Date) {
		dateValue = (date as Date).getTime()
	} else {
		const patron: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/
		if (patron.test(date as string)) {
			dateValue = date as string
		}
	}
	if (dateValue === undefined) {
		return EFirmaDelimitador.DELIMITADOR_VALOR_VACIO
	}
	const inputDate = new Date(dateValue)
	if (isNaN(inputDate.getTime()) || inputDate.getTime() === 0) {
		return EFirmaDelimitador.DELIMITADOR_VALOR_VACIO
	}

	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		timeZone: 'America/Mexico_City'
	}
	const formatter = new Intl.DateTimeFormat('es-ES', options)
	const formattedDate = formatter.format(inputDate)
	return formattedDate
}
