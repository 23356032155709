<script lang="ts" setup>
/**
 * @name DevSettings
 * @description Componente para modificar en tiempo de ejecución ajustes de desarrollo
 * @version 1.0.2
 * @changelog 1.0.1 - 24/oct/24 - @tirsomartinezreyes - Se agregó la propiedad detalleFechasAdministrativas
 * @changelog 1.0.2 - 25/oct/24 - @tirsomartinezreyes - Se agregó la propiedad infoModalidad,infoActivoInformacion
 */
import { computed, ref, onMounted, IDevSettings, storeToRefs } from 'src/bom'
import { getDefaultDevSettings, useDevSettings } from './DevSettings.store'

const devSettingsStore = useDevSettings()

const defaultSettings: IDevSettings = { ...getDefaultDevSettings() }

const { homoclaveModoDebug, homoclaveModoTrace, autoPopulate, minWords, maxWords, logRocket, detalleFechasAdministrativas, infoModalidad, infoActivoInformacion } = storeToRefs(devSettingsStore)

const props = defineProps({
	debug: {
		type: Boolean,
		default: false
	},
	//R5:Mostrar un icono distintivo cuando se encuentra en un entorno local de desarrollo
	show: {
		type: Boolean,
		default: false
	},
	class: {
		type: String,
		default: ''
	}
})

defineExpose({
	settings: devSettingsStore
})

const modal = ref(false)

/**
 * R2: Informar al desarrollador / Usuario de los ajustes que se estan utilizando en tiempo de ejecución, y los valores por default que se utilizan en los entornos de producción
 */
const mostrarModal = () => {
	props.debug ?? console.log('mostrarModal')
	modal.value = true
}

const ocultarModal = () => {
	props.debug ?? console.log('ocultarModal')
	modal.value = false
}

/**
 * R4: Permitir restablecer los parámetros de desarrollo a los valores originales en tiempo de ejecución
 */
const restablecerSetting = () => {
	props.debug ?? console.log('restablecerSetting')
	devSettingsStore.$reset()
	modal.value = false
	location.reload()
}

/**
 * R5: Mostrar un icono distintivo cuando se encuentra en un entorno local de desarrollo
 */
const hasCustomSettings = computed(() => {
	let response = false
	for (let i in defaultSettings) {
		if (devSettingsStore[i as keyof IDevSettings] != defaultSettings[i as keyof IDevSettings]) {
			response = true
		}
	}
	return response
})

onMounted(() => {
	props.debug ?? console.log('onMounted')
})
</script>

<template>
	<div :class="props.class">
		<!--
			R5: Mostrar un icono distintivo cuando se encuentra en un entorno local de desarrollo
			R6: Mantener oculto el icono en entornos compilados con banderas de producción
			R7: Mostrar el ícono distintivo en un color diferente cuando se utilizan valores de desarrollo por defecto o cuando se están utilizando valores modificados
		-->
		<va-chip v-show="props.show" :color="`${hasCustomSettings ? 'danger' : 'info'}`" class="fs-xl settings" :class="hasCustomSettings ? 'pulseRed' : ''" @click="mostrarModal">⚙️</va-chip>

		<va-modal v-model="modal" allow-body-scroll no-dismiss hide-default-actions style="background: rgba(19, 50, 43, 0.8); backdrop-filter: blur(10px)">
			<div class="row">
				<div class="col-md-12 text-center">
					<div class="text-center">
						<span class="bold">Ajustes de desarrollo </span>
						<hr class="mt5" />
						<br />
						<!--
							R2: Informar al desarrollador / Usuario de los ajustes que se estan utilizando en tiempo de ejecución, y los valores por default que se utilizan en los entornos de producción
							R3: Permitir cambiar los parámetros de desarrollo en tiempo de ejecución
							R4: Permitir restablecer los parámetros de desarrollo a los valores originales en tiempo de ejecución
						-->
						<table>
							<caption></caption>
							<tr>
								<th>Nombre</th>
								<th>Valor actual</th>
								<th>Valor por defecto</th>
							</tr>
							<tr>
								<td>Debug</td>
								<td>
									<va-checkbox v-model="homoclaveModoDebug" />
								</td>
								<td>{{ defaultSettings.homoclaveModoDebug }}</td>
							</tr>
							<tr>
								<td>Trace</td>
								<td>
									<va-checkbox v-model="homoclaveModoTrace" />
								</td>
								<td>{{ defaultSettings.homoclaveModoTrace }}</td>
							</tr>
							<tr>
								<td>Autopopulate</td>
								<td>
									<va-checkbox v-model="autoPopulate" />
								</td>
								<td>{{ defaultSettings.autoPopulate }}</td>
							</tr>
							<tr v-if="autoPopulate">
								<td>Min words</td>
								<td>
									<va-counter v-model="minWords" manual-input :min="1" :max="500" />
								</td>
								<td>{{ defaultSettings.minWords }}</td>
							</tr>
							<tr v-if="autoPopulate">
								<td>Max words</td>
								<td>
									<va-counter v-model="maxWords" manual-input :min="minWords" :max="500" />
								</td>
								<td>{{ defaultSettings.maxWords }}</td>
							</tr>
							<tr>
								<td>LogRocket</td>
								<td>
									<va-checkbox v-model="logRocket" />
								</td>
								<td>{{ defaultSettings.logRocket }}</td>
							</tr>
							<tr>
								<td>Detalle Fechas Administrativas</td>
								<td>
									<va-checkbox v-model="detalleFechasAdministrativas" />
								</td>
								<td>{{ defaultSettings.detalleFechasAdministrativas }}</td>
							</tr>
							<tr>
								<td>Información de la Modalidad</td>
								<td>
									<va-checkbox v-model="infoModalidad" />
								</td>
								<td>{{ defaultSettings.infoModalidad }}</td>
							</tr>
							<tr>
								<td>Información del activo de información</td>
								<td>
									<va-checkbox v-model="infoActivoInformacion" />
								</td>
								<td>{{ defaultSettings.infoActivoInformacion }}</td>
							</tr>
						</table>
						<br />
						<button class="btn btn-danger" type="button" @click="ocultarModal">Cerrar</button>
						<button class="btn btn-primary m10" type="button" @click="restablecerSetting">Restablecer Defaults</button>
					</div>
				</div>
			</div>
		</va-modal>
	</div>
</template>

<style scoped>
.settings {
	position: fixed;
	top: 10px;
	right: 50px;
	width: 50px;
	height: 50px;
	border: 3px dotted #ccc;
	z-index: 9999;
}
table {
	width: 100%;
}
table,
th,
td {
	border: 1px solid black;
}
</style>
