import App from 'src/App.vue'
import { IAppFilters } from 'src/filters/filters'
import { getDateByMillis, getDaysLeft, getTimeAgoAsString } from 'src/filters/dates'
import { relativeLink } from 'src/filters/routes'
import { addCarReturnToCSV, addSpacesToCSV, objectsArrayToString, arrayElementsCount, arrayIndexToOrdinal, upperCaseFirst } from 'src/filters/strings'
import { numeroCertificadoCorto } from 'src/filters/efirma'
import router from 'src/router'
import * as Sentry from '@sentry/vue'
import { ErrorHandler } from 'cofepris-typesafe/Types/Errors'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import vSelect from 'vue-select'
import 'vuestic-ui/css'
import 'src/assets/css/overrides.css'
import VuesticTheme from 'cofepris-typesafe/src/themes/vuesticTheme'
import Vidle from 'v-idle-3'
import LoadScript from 'vue-plugin-load-script'
import 'reflect-metadata'
import { IGetAccessToken } from 'src/services/grapqhlApi.service'
import GetAccessTokenService from './services/GetAccessTokenService'

import {
	useConfigStore, // Obtención de entorno de ejecución y versión de código
	useDevSettings, //Obtención de configuración de desarrollo para determinar si se requiere el registro de sesión por LogRocket
	useLogRocketStore //Control de grabación de Sesión con LogRocket
} from 'src/bom'
//Controles de vuestic
import {
	VaAlert,
	VaAvatar,
	VaBreadcrumbs,
	VaButton,
	VaButtonGroup,
	VaBadge,
	VaCard,
	VaCardActions,
	VaCardContent,
	VaCardTitle,
	VaCarousel,
	VaCheckbox,
	VaChip,
	VaColorInput,
	VaCounter,
	VaDateInput,
	VaDatePicker,
	VaFileUpload,
	VaIcon,
	VaImage,
	VaInnerLoading,
	VaInput,
	VaOptionList,
	VaPopover,
	VaProgressBar,
	VaProgressCircle,
	VaRadio,
	VaScrollContainer,
	VaSelect,
	VaSidebar,
	VaSidebarItem,
	VaSidebarItemContent,
	VaSidebarItemTitle,
	VaSlider,
	VaSpacer,
	VaSwitch,
	VaTab,
	VaTabs,
	VaTimeInput,
	VaTimePicker,
	VaTextarea,
	VaTreeView,
	createVuestic,
	VaSkeletonGroup,
	VaSkeleton,
	VaPagination
} from 'vuestic-ui'
import { IErrorModalService } from 'cofepris-typesafe/Types/ErrorModal'
import IStorage from 'cofepris-typesafe/Types/IStorage'
import TypeContainer from 'cofepris-typesafe/Types/Container'
import { fakerES_MX as faker } from '@faker-js/faker'
//Declaración de tipos para filtros globales
declare module '@vue/runtime-core' {
	export interface ComponentCustomProperties {
		$filters: IAppFilters
	}
}
//Containers
;(window as any).container = TypeContainer.init()
TypeContainer.set<IGetAccessToken>('IGetAccessToken', new GetAccessTokenService())
TypeContainer.set<IErrorModalService>('IErrorModalService', {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	show: (_a: any) => {
		throw new Error('No se ha inicializado el servicio de errores')
	},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	set: _a => {
		throw new Error('No se ha inicializado el servicio de errores')
	}
} as IErrorModalService)
TypeContainer.set<IStorage>('SessionStorage', sessionStorage)

//Stores
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = createApp(App)

//Filtros globales
app.config.globalProperties.$filters = {
	getDateByMillis,
	getDaysLeft,
	getTimeAgoAsString,
	addSpacesToCSV,
	addCarReturnToCSV,
	objectsArrayToString,
	arrayElementsCount,
	relativeLink,
	numeroCertificadoCorto,
	arrayIndexToOrdinal,
	upperCaseFirst
}

//Manejador de eventos para excepciones no controladas
app.config.errorHandler = (error: any) => ErrorHandler(error)
app.use(pinia)
app.use(Vidle, {})
app
	.use(router)
	.use(
		createVuestic({
			config: VuesticTheme.getConfig()
		})
	)
	.use(LoadScript)
	.component('v-select', vSelect)
	.component('va-chip', VaChip)
	.component('va-input', VaInput)
	.component('va-slider', VaSlider)
	.component('va-badge', VaBadge)
	.component('va-switch', VaSwitch)
	.component('va-select', VaSelect)
	.component('va-color-input', VaColorInput)
	.component('va-button', VaButton)
	.component('va-card', VaCard)
	.component('va-card-content', VaCardContent)
	.component('va-alert', VaAlert)
	.component('va-avatar', VaAvatar)
	.component('va-breadcrumbs', VaBreadcrumbs)
	.component('va-button-group', VaButtonGroup)
	.component('va-checkbox', VaCheckbox)
	.component('va-counter', VaCounter)
	.component('va-date-input', VaDateInput)
	.component('va-date-picker', VaDatePicker)
	.component('va-image', VaImage)
	.component('va-icon', VaIcon)
	.component('va-option-list', VaOptionList)
	.component('va-popover', VaPopover)
	.component('va-progress-bar', VaProgressBar)
	.component('va-progress-circle', VaProgressCircle)
	.component('va-radio', VaRadio)
	.component('va-scroll-container', VaScrollContainer)
	.component('va-spacer', VaSpacer)
	.component('va-tabs', VaTabs)
	.component('va-tab', VaTab)
	.component('va-time-input', VaTimeInput)
	.component('va-time-picker', VaTimePicker)
	.component('va-card-title', VaCardTitle)
	.component('va-carousel', VaCarousel)
	.component('va-sidebar-item', VaSidebarItem)
	.component('va-sidebar-item-content', VaSidebarItemContent)
	.component('va-sidebar-item-title', VaSidebarItemTitle)
	.component('va-sidebar', VaSidebar)
	.component('va-card-actions', VaCardActions)
	.component('va-file-upload', VaFileUpload)
	.component('va-inner-loading', VaInnerLoading)
	.component('va-tree-view', VaTreeView)
	.component('va-skeleton-group', VaSkeletonGroup)
	.component('va-skeleton', VaSkeleton)
	.component('va-textarea', VaTextarea)
	.component('va-pagination', VaPagination)

//Montaje del App en el DOM
app.mount('#app')

//Instrumentación de UI
useLogRocketStore().initLogRocket(useDevSettings().logRocket)

//Instrumentación del APP
if (import.meta.env.DEV) {
	app.config.performance = true
	faker.seed(123)
}

if (import.meta.env.PROD) {
	console.log('Inicializando sentry')
	Sentry.init({
		app,
		dsn: 'https://9ee275f8f318433090ad92af765896df@o178474.ingest.sentry.io/4505444526194688',
		trackComponents: true,
		hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
		maxBreadcrumbs: 500,
		environment: useConfigStore().getEnvironment(),
		release: useConfigStore().getEnvironment() + '@' + import.meta.env.VITE_GITVERSION,
		integrations: [
			new Sentry.BrowserTracing({
				// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ['localhost', '*'],
				routingInstrumentation: Sentry.vueRouterInstrumentation(router)
			})
		],
		// Performance Monitoring
		tracesSampleRate: 0.25, // Capture 100% of the transactions, reduce in production!
		// Session Replay
		beforeSend(event) {
			console.log('Sentry event beforeSend :', event)
			return event
		}
	})
}

console.log('✅ Aplicación inicializada')
console.log(
	'\r\n    ____   ____     _   __\r\n   /  _/  / __ \\   / | / /\r\n   / /   / /_/ /  /  |/ / \r\n _/ /   / ____/  / /|  /  \r\n/___/  /_/      /_/ |_/   \r\n                          \r\n\
La Técnica al Servicio de la Patria.'
)
console.log('DIGIPRIS: Regulación en línea / Desarrollado con ♥️ y ☕️ por el Instituto Politécnico Nacional - SEPI ESIME Culhuacan https://posgrados.esimecu.ipn.mx')
console.log('Huélum, Huélum ¡Gloria!')
